import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { Container, Draggable } from 'react-smooth-dnd';

import { applyDrag } from '../../utils';
import glob from '../../glob';
import { Button } from 'primereact/button';
import GameEndScreen from '../../GameEndScreen';

const groupStyle = {
   margin: '50px',
   overflowX: 'auto',
   // border: '1px solid #ccc'
};

export default function RakdSorrendbeAKepeket(props, { ...rest }) {
   const [items, setItems] = useState([]);
   const [taskConfig, setTaskConfig] = useState({});
   const [questionIndex, setQuestionIndex] = useState(0);
   const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
   const [nextTaskItem, setNextTaskItem] = useState(false);
   const [gameStarted, setGameStarted] = useState(false);
   const [answerClass, setAnswerClass] = useState("");
   const [dragBeginDelay, setDragBeginDelay] = useState(10);
   
   useEffect(() => {
      glob.shuffleArray(questions);
      $.each(questions, (index, item) => {
         glob.shuffleArray(questions[index]["options"], true);
      });
   
      var task_config = {};
      
      console.log("variable questions: ", questions);
      task_config["items"] = questions; //
      setTaskConfig(task_config);
   }, []);
   
   useEffect(() => {
      if (taskConfig["items"]) {
         setItems(taskConfig["items"][questionIndex]["options"]);
         setNextTaskItem(taskConfig["items"][questionIndex + 1]);
         setAnswerClass("");
         setDragBeginDelay(10);
      }
   }, [taskConfig, questionIndex]);
   
   useEffect(() => {
      var correct = true;
      $.each(items, (index, item) => {
         if (index != item["index"]) {
            correct = false;
         }
      });
      
      if (correct && gameStarted) {
         setIsAnswerCorrect(correct);
         glob.playGameSound("correct");
         setAnswerClass("correct");
         setDragBeginDelay(10000);
      }
      
   }, [items]);
   
   var task_config = {
      "items": []
   };

   /* 
   var kerdesek = [
      [
         {
            "name": "Tojások vannak a tartóban.",
            "image": require('../../../images/tasks/rakd_sorrendbe_a_kepeket/2_reggeli/1.jpeg').default,
            "index": 0
         }, {
            "name": "A tojást feltörik.",
            "image": require('../../../images/tasks/rakd_sorrendbe_a_kepeket/2_reggeli/2.jpeg').default,
            "index": 1
         }, {
            "name": "Sül a tojás.",
            "image": require('../../../images/tasks/rakd_sorrendbe_a_kepeket/2_reggeli/3.jpeg').default,
            "index": 2
         }, {
            "name": "Kész a reggeli.",
            "image": require('../../../images/tasks/rakd_sorrendbe_a_kepeket/2_reggeli/4.jpeg').default,
            "index": 3
         }
      ],
      [
         {
            "name": "Tojások vannak a tartóban.",
            "image": require('../../../images/tasks/rakd_sorrendbe_a_kepeket/2_reggeli/1.jpeg').default,
            "index": 0
         }, {
            "name": "A tojást feltörik.",
            "image": require('../../../images/tasks/rakd_sorrendbe_a_kepeket/2_reggeli/2.jpeg').default,
            "index": 1
         }, {
            "name": "Sül a tojás.",
            "image": require('../../../images/tasks/rakd_sorrendbe_a_kepeket/2_reggeli/3.jpeg').default,
            "index": 2
         }, {
            "name": "Kész a reggeli.",
            "image": require('../../../images/tasks/rakd_sorrendbe_a_kepeket/2_reggeli/4.jpeg').default,
            "index": 3
         }
      ]
   ]; 
   
   
   $.each(kerdesek, (index, item) => {
      glob.shuffleArray(item);
      var new_item = {
         "options": item
      };
      task_config["items"].push(new_item);
   });
   */

   var questions = [
     {
       options: [
         {
           name: "Az utas sorban áll a repülőtéren.",
           image:
             require("../../../images/stockphotos/folyamatok/1_repules/1.jpeg")
               .default,
           index: 0,
         },
         {
           name: "Az utas áthalad a biztonsági ellenőrzésen.",
           image:
             require("../../../images/stockphotos/folyamatok/1_repules/2.jpeg")
               .default,
           index: 1,
         },
         {
           name: "Az utas várakozik a beszállókapunál.",
           image:
             require("../../../images/stockphotos/folyamatok/1_repules/3.jpeg")
               .default,
           index: 2,
         },
         {
           name: "Az utas felszáll a repülőgéppel.",
           image:
             require("../../../images/stockphotos/folyamatok/1_repules/4.jpeg")
               .default,
           index: 3,
         },
       ],
     },
     {
       options: [
         {
           name: "Tojások vannak a tartóban.",
           image:
             require("../../../images/stockphotos/folyamatok/2_reggeli/1.jpeg")
               .default,
           index: 0,
         },
         {
           name: "A tojást feltörik.",
           image:
             require("../../../images/stockphotos/folyamatok/2_reggeli/2.jpeg")
               .default,
           index: 1,
         },
         {
           name: "Sül a tükörtojás.",
           image:
             require("../../../images/stockphotos/folyamatok/2_reggeli/3.jpeg")
               .default,
           index: 2,
         },
         {
           name: "Kész a reggeli.",
           image:
             require("../../../images/stockphotos/folyamatok/2_reggeli/4.jpeg")
               .default,
           index: 3,
         },
       ],
     },
     {
       options: [
         {
           name: "Üres a tank.",
           image:
             require("../../../images/stockphotos/folyamatok/3_tankolas/1.jpeg")
               .default,
           index: 0,
         },
         {
           name: "Károly megtankolja az autót.",
           image:
             require("../../../images/stockphotos/folyamatok/3_tankolas/2.jpeg")
               .default,
           index: 1,
         },
         {
           name: "Tele a tank.",
           image:
             require("../../../images/stockphotos/folyamatok/3_tankolas/3.jpeg")
               .default,
           index: 2,
         },
         {
           name: "Károly kifizeti a benzint.",
           image:
             require("../../../images/stockphotos/folyamatok/3_tankolas/4.jpeg")
               .default,
           index: 3,
         },
       ],
     },
     {
       options: [
         {
           name: "A séf összevágja a húst.",
           image:
             require("../../../images/stockphotos/folyamatok/4_wok_sutes/1.jpeg")
               .default,
           index: 0,
         },
         {
           name: "A séf olajat önt a serpenyőbe.",
           image:
             require("../../../images/stockphotos/folyamatok/4_wok_sutes/2.jpeg")
               .default,
           index: 1,
         },
         {
           name: "A séf hozzáadja a hagymát.",
           image:
             require("../../../images/stockphotos/folyamatok/4_wok_sutes/3.jpeg")
               .default,
           index: 2,
         },
         {
           name: "A séf beleteszi a húst.",
           image:
             require("../../../images/stockphotos/folyamatok/4_wok_sutes/4.jpeg")
               .default,
           index: 3,
         },
       ],
     },
     {
       options: [
         {
           name: "Az autó defektet kapott.",
           image:
             require("../../../images/stockphotos/folyamatok/5_defektes_gumi/1.jpeg")
               .default,
           index: 0,
         },
         {
           name: "István kirakja az elakadásjelző háromszöget.",
           image:
             require("../../../images/stockphotos/folyamatok/5_defektes_gumi/2.jpeg")
               .default,
           index: 1,
         },
         {
           name: "István megemeli az autót.",
           image:
             require("../../../images/stockphotos/folyamatok/5_defektes_gumi/3.jpeg")
               .default,
           index: 2,
         },
         {
           name: "István leszereli a kereket.",
           image:
             require("../../../images/stockphotos/folyamatok/5_defektes_gumi/4.jpeg")
               .default,
           index: 3,
         },
       ],
     },
     {
       options: [
         {
           name: "Éva felragasztja a maszkolószalagot.",
           image:
             require("../../../images/stockphotos/folyamatok/6_szoba_festes/1.jpeg")
               .default,
           index: 0,
         },
         {
           name: "Éva ecsettel kihúzza a sarkokat.",
           image:
             require("../../../images/stockphotos/folyamatok/6_szoba_festes/2.jpeg")
               .default,
           index: 1,
         },
         {
           name: "Éva hengerrel festi a falakat.",
           image:
             require("../../../images/stockphotos/folyamatok/6_szoba_festes/3.jpeg")
               .default,
           index: 2,
         },
         {
           name: "Éva gyönyörködik a kifestett szobában.",
           image:
             require("../../../images/stockphotos/folyamatok/6_szoba_festes/4.jpeg")
               .default,
           index: 3,
         },
       ],
     },
     {
       options: [
         {
           name: "A szabó méretet vesz.",
           image:
             require("../../../images/stockphotos/folyamatok/7_ruha_szabas/1.jpeg")
               .default,
           index: 0,
         },
         {
           name: "A szabó megtervezi a ruhát.",
           image:
             require("../../../images/stockphotos/folyamatok/7_ruha_szabas/2.jpeg")
               .default,
           index: 1,
         },
         {
           name: "A szabó rárajzolja a szabásmintát az anyagra.",
           image:
             require("../../../images/stockphotos/folyamatok/7_ruha_szabas/3.jpeg")
               .default,
           index: 2,
         },
         {
           name: "A szabó kiszabja az anyagot.",
           image:
             require("../../../images/stockphotos/folyamatok/7_ruha_szabas/4.jpeg")
               .default,
           index: 3,
         },
       ],
     },
     {
       options: [
         {
           name: "Zsófi hoz egy virágpalántát.",
           image:
             require("../../../images/stockphotos/folyamatok/8_palantazas/1.jpeg")
               .default,
           index: 0,
         },
         {
           name: "Anyukájával elültetik.",
           image:
             require("../../../images/stockphotos/folyamatok/8_palantazas/2.jpeg")
               .default,
           index: 1,
         },
         {
           name: "Zsófi öntözi a palántát.",
           image:
             require("../../../images/stockphotos/folyamatok/8_palantazas/3.jpeg")
               .default,
           index: 2,
         },
         {
           name: "Gyönyörű virág lett belőle.",
           image:
             require("../../../images/stockphotos/folyamatok/8_palantazas/4.jpeg")
               .default,
           index: 3,
         },
       ],
     },
     {
       options: [
         {
           name: "A házaspár átnézi az étlapot.",
           image:
             require("../../../images/stockphotos/folyamatok/9_etterem/1.jpeg")
               .default,
           index: 0,
         },
         {
           name: "A pincér felveszi a rendelést.",
           image:
             require("../../../images/stockphotos/folyamatok/9_etterem/2.jpeg")
               .default,
           index: 1,
         },
         {
           name: "A szakácsok elkészítik az ételt.",
           image:
             require("../../../images/stockphotos/folyamatok/9_etterem/3.jpeg")
               .default,
           index: 2,
         },
         {
           name: "A pincér kihozza az ételt.",
           image:
             require("../../../images/stockphotos/folyamatok/9_etterem/4.jpeg")
               .default,
           index: 3,
         },
       ],
     },
     {
       options: [
         {
           name: "Az apa és a fia fát ültet.",
           image:
             require("../../../images/stockphotos/folyamatok/10_facsemete_ultetes/1.jpeg")
               .default,
           index: 0,
         },
         {
           name: "A fa megnő.",
           image:
             require("../../../images/stockphotos/folyamatok/10_facsemete_ultetes/2.jpeg")
               .default,
           index: 1,
         },
         {
           name: "Az apa almát szed.",
           image:
             require("../../../images/stockphotos/folyamatok/10_facsemete_ultetes/3.jpeg")
               .default,
           index: 2,
         },
         {
           name: "A kosár tele van almával.",
           image:
             require("../../../images/stockphotos/folyamatok/10_facsemete_ultetes/4.jpeg")
               .default,
           index: 3,
         },
       ],
     },
     {
       options: [
         {
           name: "A társulat próbálja a darabot.",
           image:
             require("../../../images/stockphotos/folyamatok/11_szinhaz/1.jpeg")
               .default,
           index: 0,
         },
         {
           name: "A közönség várja az előadást.",
           image:
             require("../../../images/stockphotos/folyamatok/11_szinhaz/2.jpeg")
               .default,
           index: 1,
         },
         {
           name: "A társulat előadja a darabot.",
           image:
             require("../../../images/stockphotos/folyamatok/11_szinhaz/3.jpeg")
               .default,
           index: 2,
         },
         {
           name: "A társulat meghajol.",
           image:
             require("../../../images/stockphotos/folyamatok/11_szinhaz/4.jpeg")
               .default,
           index: 3,
         },
       ],
     },
     {
       options: [
         {
           name: "Kati felkel.",
           image:
             require("../../../images/stockphotos/folyamatok/12_reggeli_keszulodes/1.jpg")
               .default,
           index: 0,
         },
         {
           name: "Kati tisztálkodik.",
           image:
             require("../../../images/stockphotos/folyamatok/12_reggeli_keszulodes/2.jpg")
               .default,
           index: 1,
         },
         {
           name: "Kati öltözködik.",
           image:
             require("../../../images/stockphotos/folyamatok/12_reggeli_keszulodes/3.jpg")
               .default,
           index: 2,
         },
         {
           name: "Kati munkába indul.",
           image:
             require("../../../images/stockphotos/folyamatok/12_reggeli_keszulodes/4.jpg")
               .default,
           index: 3,
         },
       ],
     },
     {
       options: [
         {
           name: "Péter megkéri Anna kezét.",
           image:
             require("../../../images/stockphotos/folyamatok/13_eskuvo/1.jpeg")
               .default,
           index: 0,
         },
         {
           name: "Péter és Anna összeházasodnak.",
           image:
             require("../../../images/stockphotos/folyamatok/13_eskuvo/2.jpeg")
               .default,
           index: 1,
         },
         {
           name: "A házaspárnak kislánya születik.",
           image:
             require("../../../images/stockphotos/folyamatok/13_eskuvo/3.jpeg")
               .default,
           index: 2,
         },
         {
           name: "A házaspár sétál a kislányával.",
           image:
             require("../../../images/stockphotos/folyamatok/13_eskuvo/4.jpeg")
               .default,
           index: 3,
         },
       ],
     },
     {
       options: [
         {
           name: "Laci sok sört iszik.",
           image:
             require("../../../images/stockphotos/folyamatok/14_reszeg_sofor/1.jpeg")
               .default,
           index: 0,
         },
         {
           name: "Laci részegen vezet.",
           image:
             require("../../../images/stockphotos/folyamatok/14_reszeg_sofor/2.jpeg")
               .default,
           index: 1,
         },
         {
           name: "Laci fának csapódik az autóval.",
           image:
             require("../../../images/stockphotos/folyamatok/14_reszeg_sofor/3.jpeg")
               .default,
           index: 2,
         },
         {
           name: "Lacinak gipszben van a lába.",
           image:
             require("../../../images/stockphotos/folyamatok/14_reszeg_sofor/4.jpeg")
               .default,
           index: 3,
         },
       ],
     },
     {
       options: [
         {
           name: "A horgász csalit tesz a horogra.",
           image:
             require("../../../images/stockphotos/folyamatok/15_horgaszas/1.jpeg")
               .default,
           index: 0,
         },
         {
           name: "Egy hal bekapja a csalit.",
           image:
             require("../../../images/stockphotos/folyamatok/15_horgaszas/2.jpeg")
               .default,
           index: 1,
         },
         {
           name: "A horgász kifogja a halat.",
           image:
             require("../../../images/stockphotos/folyamatok/15_horgaszas/3.jpeg")
               .default,
           index: 2,
         },
         {
           name: "A horgász megeszi a sült halat.",
           image:
             require("../../../images/stockphotos/folyamatok/15_horgaszas/4.jpeg")
               .default,
           index: 3,
         },
       ],
     },
     {
       options: [
         {
           name: "Klári banánt eszik.",
           image:
             require("../../../images/stockphotos/folyamatok/16_bananhej/1.jpeg")
               .default,
           index: 0,
         },
         {
           name: "Klári eldobja a banánhéjat.",
           image:
             require("../../../images/stockphotos/folyamatok/16_bananhej/2.jpeg")
               .default,
           index: 1,
         },
         {
           name: "Robi rálép a banánhéjra.",
           image:
             require("../../../images/stockphotos/folyamatok/16_bananhej/3.jpeg")
               .default,
           index: 2,
         },
         {
           name: "Robi hanyatt esik.",
           image:
             require("../../../images/stockphotos/folyamatok/16_bananhej/4.jpeg")
               .default,
           index: 3,
         },
       ],
     },
     {
       options: [
         {
           name: "A tyúk tojást tojik.",
           image:
             require("../../../images/stockphotos/folyamatok/17_tojas_koltes/1.jpeg")
               .default,
           index: 0,
         },
         {
           name: "A tyúk kotlik a tojásokon.",
           image:
             require("../../../images/stockphotos/folyamatok/17_tojas_koltes/2.jpeg")
               .default,
           index: 1,
         },
         {
           name: "A kiscsibe kikel a tojásból.",
           image:
             require("../../../images/stockphotos/folyamatok/17_tojas_koltes/3.jpeg")
               .default,
           index: 2,
         },
         {
           name: "A kiscsibe elrohan.",
           image:
             require("../../../images/stockphotos/folyamatok/17_tojas_koltes/4.jpeg")
               .default,
           index: 3,
         },
       ],
     },
     {
       options: [
         {
           name: "Norbert egészségtelenül táplálkozik.",
           image:
             require("../../../images/stockphotos/folyamatok/18_fogyas/1.jpeg")
               .default,
           index: 0,
         },
         {
           name: "Norbert nagyon elhízik.",
           image:
             require("../../../images/stockphotos/folyamatok/18_fogyas/2.jpeg")
               .default,
           index: 1,
         },
         {
           name: "Norbert elkezd rendszeresen sportolni.",
           image:
             require("../../../images/stockphotos/folyamatok/18_fogyas/3.jpeg")
               .default,
           index: 2,
         },
         {
           name: "Norbert lefogyott.",
           image:
             require("../../../images/stockphotos/folyamatok/18_fogyas/4.jpeg")
               .default,
           index: 3,
         },
       ],
     },
     {
       options: [
         {
           name: "Nóra tésztát gyúr.",
           image:
             require("../../../images/stockphotos/folyamatok/19_suti_sutese/1.jpeg")
               .default,
           index: 0,
         },
         {
           name: "Sodrófával kinyújtja a tésztát.",
           image:
             require("../../../images/stockphotos/folyamatok/19_suti_sutese/2.jpeg")
               .default,
           index: 1,
         },
         {
           name: "Nóra kiszaggatja a tésztát.",
           image:
             require("../../../images/stockphotos/folyamatok/19_suti_sutese/3.jpeg")
               .default,
           index: 2,
         },
         {
           name: "Nóra megsüti a tésztát.",
           image:
             require("../../../images/stockphotos/folyamatok/19_suti_sutese/4.jpeg")
               .default,
           index: 3,
         },
       ],
     },
     {
       options: [
         {
           name: "A háziasszony kiönti az ételmaradékot a szemétbe.",
           image:
             require("../../../images/stockphotos/folyamatok/20_szemet_utja/1.jpeg")
               .default,
           index: 0,
         },
         {
           name: "A háziasszony kiviszi a szemetet.",
           image:
             require("../../../images/stockphotos/folyamatok/20_szemet_utja/2.jpeg")
               .default,
           index: 1,
         },
         {
           name: "A háziasszony kidobja a szemetet a kukába.",
           image:
             require("../../../images/stockphotos/folyamatok/20_szemet_utja/3.jpeg")
               .default,
           index: 2,
         },
         {
           name: "A kukásautó kiüríti a kukát.",
           image:
             require("../../../images/stockphotos/folyamatok/20_szemet_utja/4.jpeg")
               .default,
           index: 3,
         },
       ],
     },
     {
       options: [
         {
           name: "Megérett a búza.",
           image:
             require("../../../images/stockphotos/folyamatok/21_kenyer_szuletese/1.jpeg")
               .default,
           index: 0,
         },
         {
           name: "Learatják a búzát.",
           image:
             require("../../../images/stockphotos/folyamatok/21_kenyer_szuletese/2.jpeg")
               .default,
           index: 1,
         },
         {
           name: "Lisztté őrlik a búzát.",
           image:
             require("../../../images/stockphotos/folyamatok/21_kenyer_szuletese/3.jpeg")
               .default,
           index: 2,
         },
         {
           name: "Kenyeret sütnek a lisztből.",
           image:
             require("../../../images/stockphotos/folyamatok/21_kenyer_szuletese/4.jpeg")
               .default,
           index: 3,
         },
       ],
     },
     {
       options: [
         {
           name: "Érik a szőlő.",
           image:
             require("../../../images/stockphotos/folyamatok/22_bor_szuletese/1.jpeg")
               .default,
           index: 0,
         },
         {
           name: "Leszüretelik a szőlőt.",
           image:
             require("../../../images/stockphotos/folyamatok/22_bor_szuletese/2.jpeg")
               .default,
           index: 1,
         },
         {
           name: "Bort készítenek a szőlőből.",
           image:
             require("../../../images/stockphotos/folyamatok/22_bor_szuletese/3.jpeg")
               .default,
           index: 2,
         },
         {
           name: "A vásárló pohárba önti a bort.",
           image:
             require("../../../images/stockphotos/folyamatok/22_bor_szuletese/4.jpeg")
               .default,
           index: 3,
         },
       ],
     },
     {
       options: [
         {
           name: "Elültetik a virághagymákat.",
           image:
             require("../../../images/stockphotos/folyamatok/23_virag_utja/1.jpeg")
               .default,
           index: 0,
         },
         {
           name: "Kikelnek a virágok.",
           image:
             require("../../../images/stockphotos/folyamatok/23_virag_utja/2.jpeg")
               .default,
           index: 1,
         },
         {
           name: "A virágboltos szép csokrot köt a virágokból.",
           image:
             require("../../../images/stockphotos/folyamatok/23_virag_utja/3.jpeg")
               .default,
           index: 2,
         },
         {
           name: "A fiatalember meglepi a barátnőjét egy csokor virággal.",
           image:
             require("../../../images/stockphotos/folyamatok/23_virag_utja/4.jpeg")
               .default,
           index: 3,
         },
       ],
     },
     {
       options: [
         {
           name: "János kontrollra megy a kórházba.",
           image:
             require("../../../images/stockphotos/folyamatok/24_korhaz/1.png")
               .default,
           index: 0,
         },
         {
           name: "Az orvos megvizsgálja Jánost.",
           image:
             require("../../../images/stockphotos/folyamatok/24_korhaz/2.png")
               .default,
           index: 1,
         },
         {
           name: "Az orvos receptet ír.",
           image:
             require("../../../images/stockphotos/folyamatok/24_korhaz/3.png")
               .default,
           index: 2,
         },
         {
           name: "Elköszönnek egymástól.",
           image:
             require("../../../images/stockphotos/folyamatok/24_korhaz/4.png")
               .default,
           index: 3,
         },
       ],
     },
     {
       options: [
         {
           name: "János levetkőzik.",
           image:
             require("../../../images/stockphotos/folyamatok/25_furdes/1.png")
               .default,
           index: 0,
         },
         {
           name: "János beszappanozza magát.",
           image:
             require("../../../images/stockphotos/folyamatok/25_furdes/2.png")
               .default,
           index: 1,
         },
         {
           name: "János lemossa magáról a szappant.",
           image:
             require("../../../images/stockphotos/folyamatok/25_furdes/3.png")
               .default,
           index: 2,
         },
         {
           name: "János megtörölközik.",
           image:
             require("../../../images/stockphotos/folyamatok/25_furdes/4.png")
               .default,
           index: 3,
         },
       ],
     },
   ];
   
   const goNextSlide = () => {
      setQuestionIndex(questionIndex + 1);
      setIsAnswerCorrect(false);
      setGameStarted(false);
      setDragBeginDelay(10);
   }
   
   const onDragStart = () => {
      if (isAnswerCorrect) { return false }
      setGameStarted(true);
   }
  const resetGame = () => {
    var task_config = taskConfig;
    glob.shuffleArray(task_config["items"], true);
    setTaskConfig(task_config);

      setIsAnswerCorrect(false);
      setQuestionIndex(0);
      setDragBeginDelay(10);
   }

   if ((!nextTaskItem && isAnswerCorrect)) {
      return <GameEndScreen className="nomargintop" resetGame={ resetGame } />
   }
   return (
      <div className={`task-wrapper task-wrapper-RakdSorrendbeAKepeket ${answerClass}`} >
         <div className="p-grid">
            <div className="p-col-12">
               <Container groupName="1" dragClass="dnd-ghost-drop" dragBeginDelay={dragBeginDelay} animationDuration={100} orientation="horizontal" onDragStart={e => onDragStart()} style={groupStyle} getChildPayload={i => items[i]} onDrop={e => setItems(applyDrag(items, e))}>
                  {items.map(function (item, index) {
                     return (
                        <Draggable key={index}>
                           <div className="draggable-item">
                              <div className="overlay-bg"></div>
                              <p>{item.name}</p>
                              <img className="question-image" src={item.image} draggable={false} onDragStart={() => { return false }} />
                           </div>
                        </Draggable>
                     )
                  })}
               </Container>
            </div>
            {(isAnswerCorrect && nextTaskItem) && (
               <>
                  <div className="wrapper-btn-next-task">
                     <Button label={<img src={ glob.icons["nextExerciseBig"] } width="50" />} className="btn-next-task p-button-rounded p-button-text" onClick={() => goNextSlide()} />
                     <div className="bg-of-btn-next-task"></div>
                  </div>
               </>
            )}
         </div>
      </div>
   );
};