import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import $ from 'jquery';

import glob from '../glob';
import { useAppContext } from '../appContext';
import GameEndScreen from '../GameEndScreen';


export default function QuestionItem(props, { ...rest }) {
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [nevezdMegText, setNevezdMegText] = useState("");
  const [isInstructionSoundPlayed, setIsInstructionSoundPlayed] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);

  const { appTitle } = useAppContext();
  const { setAppTitle } = useAppContext();
  const { gameInstructionIsActive } = useAppContext();

  var task_config = props.taskConfig;
  var taskItem = task_config["items"][questionIndex];
  var correct_answers = task_config.hasOwnProperty("correct_answers") ? task_config["correct_answers"] : 1;  
  var always_correct = task_config.hasOwnProperty("always_correct") ? task_config["always_correct"] : false;  
  var name = task_config.hasOwnProperty("name") ? task_config["name"] : false;  
  var appTitles = task_config.hasOwnProperty("appTitles") ? task_config["appTitles"] : false;  
  var paymentButton = task_config.hasOwnProperty("paymentButton") ? task_config["paymentButton"] : false;  
  var question_for_app_title = task_config.hasOwnProperty("question_for_app_title") ? task_config["question_for_app_title"] : false;  

  //var taskItem = props.taskItem;
  //var isAnswerCorrect = props.isAnswerCorrect;
  //var nextTaskItem = props.nextTaskItem;
  //var goNextSlide = props.goNextSlide;
  //var answerQuestion = props.answerQuestion;

  var nextTaskItem = false;
  nextTaskItem = task_config["items"][questionIndex + 1];
  
  useEffect(() => {
    if (gameInstructionIsActive && gameStarted) {
      if (question_for_app_title) {
        glob.playInstructionSound(appTitle);
      } else if (name === "HovaIndulAVonat" && gameStarted) {
        glob.playSounds(taskItem["question"]["sounds"], 0);
      } else {
        if ((task_config.hasOwnProperty("playTaskItemSound") && task_config.playTaskItemSound === true) || (props.hasOwnProperty("taskName") && ["MelyikSzotHallod", "KirolBeszelunk", "MelyikPenztHallod", "FizesdKiPontosan"].includes(props.taskName))) {
          setTimeout(function () {
            glob.playSound(taskItem["question"]["sound"]);
          }, 3000);
        }
      }
    }
  }, [gameInstructionIsActive]);

  useEffect(() => { 
    if (appTitles) {
      setAppTitle(appTitles[questionIndex]);
    } else {
      setAppTitle("");
      if (question_for_app_title) {
        var apptitle_ = taskItem["question"]["name"] == "úszni" ? "Melyik tud úszni?" : "Melyik tud szállni?";
        setAppTitle(apptitle_);
        
        if (!isInstructionSoundPlayed) {
          setIsInstructionSoundPlayed(true);
          glob.playInstructionSound(apptitle_);
        }
      } else {
        setAppTitle("");
      }
    }
    
    if ((task_config.hasOwnProperty("playTaskItemSound") && task_config.playTaskItemSound === true) || (props.hasOwnProperty("taskName") && ["MelyikSzotHallod", "KirolBeszelunk", "MelyikPenztHallod", "FizesdKiPontosan"].includes(props.taskName))) {
      setTimeout(function () {
        glob.playSound(taskItem["question"]["sound"]);
      }, questionIndex == 0 ? 3000 : 0);
      
    }
    if (name === "HovaIndulAVonat") {
      glob.playSounds(taskItem["question"]["sounds"], 0);
    }
    
    setTimeout(function () {
      setGameStarted(true);
    }, 1000);

    return () => {
      setAppTitle("");
    };
  }, [questionIndex]);

  const goNextSlide = () => {
    setQuestionIndex(questionIndex + 1);
    $(".btn-question-option").removeClass("active");
    setIsAnswerCorrect(false);
    setNevezdMegText("");
  }
  var correct_answers_ = [];
  var incorrect_answers = [];

  const checkPayment = () => {
    var selectable_items = $('.btn-answer-correct').length;
    var good_answers = $('.btn-answer-correct.btn-selected').length;
    var bad_answers = $('.btn-answer-incorrect.btn-selected').length;
    var good_payment = bad_answers == 0 && (selectable_items == good_answers);
    setIsAnswerCorrect(good_payment);
    glob.playGameSound(good_payment ? "correct" : "incorrect");
    
    if (good_payment) {
      $('.btn-answer-correct.btn-selected').addClass("active");
    } else {
      $(".btn-selected").addClass("btn-incorrect");

      setTimeout(function () {
        $(".btn-incorrect").removeClass("btn-incorrect");
      }, 2000);
    }
    
    $(".btn-selected").removeClass("btn-selected");
  }
  

  const answerQuestion = (elemId, isCorrect, sound = null) => {
    if (isAnswerCorrect) { return false }
    if (paymentButton) {
      $(".btn-incorrect").removeClass("btn-incorrect");
      if ($("#" + elemId).hasClass("btn-selected")) {
        $("#" + elemId).removeClass("btn-selected");  
      } else {
        $("#" + elemId).addClass("btn-selected");  
      }
      return false;
    }
    if (always_correct) {
      setNevezdMegText($("#" + elemId).attr("data-text"));
    }
    if (sound) {
      glob.playSound(sound);
    } else {
      if (!(props.hasOwnProperty("taskName") && props.taskName === "MelyikKettoTartozikOssze")) {
        glob.playGameSound(isCorrect ? "correct":"incorrect");
      }
    }
    
    if (correct_answers == 2) {
      if(incorrect_answers.length == 2) { return false }
      if (!isCorrect && !incorrect_answers.includes(elemId)) {
        incorrect_answers.push(elemId);
      }

      if (isCorrect && !correct_answers_.includes(elemId)) {
        correct_answers_.push(elemId);  
      }
      console.log("incorrect_answers: ", incorrect_answers);
      if (incorrect_answers.length == 1 && correct_answers_.length > 0) {
        if (isCorrect) {
          incorrect_answers.push(elemId);  
        } else {
          incorrect_answers.push(correct_answers_[0]);
        }
        
        glob.playGameSound("incorrect");
        $.each(incorrect_answers, (index, item) => {
          $("#" + item).addClass("incorrect-active");
        });
        setTimeout(function () {
          $.each(incorrect_answers, (index, item) => {
            $("#" + item).removeClass("incorrect-active");
          });
          incorrect_answers = [];
          correct_answers_ = [];
        }, 2000);
        
      }
      if (correct_answers_.length == 2) {
        setIsAnswerCorrect(true);
        glob.playGameSound("correct");
        $.each(correct_answers_, (index, item) => {
          $("#" + item).addClass("active");
        });
      }
    } else {
      if (isCorrect) {
        setIsAnswerCorrect(true);
      }
      $("#" + elemId).addClass("active");
  
      if (!isCorrect) {
        setTimeout(function () {
          $("#" + elemId).removeClass("active");
        }, 2000);
      }
    }
  }
  
  const resetGame = () => {
    glob.shuffleArray(task_config["items"], true);

    setIsAnswerCorrect(false);
    setQuestionIndex(0);
    setAppTitle("");
  }

  if ((!nextTaskItem && (isAnswerCorrect || always_correct))) {
    return <GameEndScreen resetGame={ resetGame } />
  }
  return (
    <>
        <div className={"question-item " + props.type}>
          <div className="p-grid p-align-center p-grid-main">
            {props.type == "kerdes_bal_oldalon_valaszok_jobb_oldalon" && (
              <>
                <div className="p-col-3 sy-position-relative">
                    <Button label={<img src={ glob.icons["speaker"] } width="50" />} className="btn-volume-up p-button-rounded p-button-text p-icon-fs-2rem" onClick={() => glob.playSound(taskItem["question"]["sound"]) } />
                </div>
                <div className="p-col-8">
                    <div className="p-grid p-align-center vertical-container">
                      {taskItem["options"].map(function (option, index2) {
                          return (
                            <div className="p-col-6">
                                <div className="question-option">
                                  <Button id={"btn-question-option-"+index2} label={(<img className="option-image" src={option.image} />)}  className={`button-answer btn-question-option p-button-outlined p-button-plain ${option.isCorrect ? ("btn-answer-correct") : ("btn-answer-incorrect")} `} onClick={() => answerQuestion("btn-question-option-"+index2, option.isCorrect)} />
                                </div>
                            </div>
                          )
                      })}   
                    </div>
                </div>
                <div className="p-col-1"></div>
              </>
            )}
            {props.type == "kerdes_felul_valaszok_alul" && (
              <>
                <div className="p-col-12 p-text-center">
                  {(props.hasOwnProperty("taskName") && props.taskName === "NevezdMeg") && <h5>{nevezdMegText}</h5>}
                  {(taskItem["question"] && taskItem["question"]["name"]) && <h5>{taskItem["question"]["name"]}</h5>}
                  {taskItem["question"].hasOwnProperty("image") && <img className="question-image" src={taskItem["question"]["image"]} />}
                  {taskItem["question"].hasOwnProperty("sound") && <Button label={<img src={ glob.icons["speaker"] } width="50" />}  className="btn-volume-up p-button-rounded p-button-text p-icon-fs-2rem" onClick={() => glob.playSound(taskItem["question"]["sound"]) } />}
                  
                  {paymentButton && <Button label={(<><span className="btn-label">Fizetem</span> <img className="btn-img" src={ glob.icons["arrowRight"] } width="30" /></>)} iconPos="right" className="btn-payment p-button-rounded p-button-plain p-icon-fs-2rem" onClick={() => checkPayment()} />}
                  <div className="p-grid p-grid-options">
                    {taskItem["options"].map(function (option, index2) {
                      return (
                          <div  className={`${paymentButton ? ("p-col-6 p-sm-4") : ("p-col-4")} `} >
                            <div className="question-option">
                                <Button id={"btn-question-option-"+index2} data-text={option.name} label={(<><p>{always_correct?(<>{index2+1}.</>) : option.name}</p>{option.hasOwnProperty("image") && <img className="option-image" src={option.image} />}</>)} className={`button-answer btn-question-option p-button-outlined p-button-plain ${paymentButton  ? ("btn-selectable") : ("")} ${(option.hasOwnProperty("isCorrect")) && (option.isCorrect ? ("btn-answer-correct") : ("btn-answer-incorrect"))} `} onClick={() => answerQuestion("btn-question-option-"+index2, option.isCorrect, option.hasOwnProperty("sound") ? option.sound : null)} />
                            </div>
                          </div>
                      )
                    })}   
                  </div>
                </div>
              </>
            )}
            {props.type == "valaszok_kozepen_ket_jo_valasz" && (
              <>
                <div className="p-col-12 p-text-center">
                  <div className="p-grid p-align-center">
                    {taskItem["options"].map(function (option, index2) {
                      return (
                          <div className="p-col-4">
                            <div className="question-option">
                                <Button id={"btn-question-option-"+index2} label={(<>{option.hasOwnProperty("image") && <img className="option-image" src={option.image} />}</>)}  className={`button-answer btn-question-option p-button-outlined p-button-plain ${option.isCorrect ? ("btn-answer-correct") : ("btn-answer-incorrect")} `} onClick={() => answerQuestion("btn-question-option-"+index2, option.isCorrect)} />
                            </div>
                          </div>
                      )
                    })}   
                  </div>
                </div>
              </>
            )}
            {props.type == "valaszok_kozepen" && (
              <>
                <div className="p-col-12 p-text-center">
                  <h5>{(taskItem.hasOwnProperty("question") && taskItem["question"].hasOwnProperty("name") && !question_for_app_title) && taskItem["question"]["name"]}</h5>
                  <div className="p-grid">
                    {taskItem["options"].map(function (option, index2) {
                      return (
                          <div className="p-col-6">
                            <div className="question-option">
                              {name === "NevezdMegTobbesszam" ? (
                                <>
                                  <div className="frame-NevezdMegTobbesszam">
                                    {option.name && <p>{option.name}</p>}
                                    {option.hasOwnProperty("image") && <img className="option-image" src={option.image } className={`button-answer btn-question-option p-button-outlined p-button-plain ${option.isCorrect ? ("btn-answer-correct") : ("btn-answer-incorrect")} `} onClick={()=>glob.playSound(option.onClickSound)} />}
                                  </div>
                                </>
                              )
                              : (
                                <Button id={"btn-question-option-" + index2} label={(<>{option.name && <p>{option.name}</p>}{option.hasOwnProperty("image") && <img className="option-image" src={option.image} />}</>)} className={`button-answer btn-question-option p-button-outlined p-button-plain ${option.isCorrect ? ("btn-answer-correct") : ("btn-answer-incorrect")} `} onClick={() => answerQuestion("btn-question-option-" + index2, option.isCorrect)} />
                              )}
                            </div>
                          </div>
                      )
                    })}   
                  </div>
                </div>
              </>
            )}
            {props.type == "valaszok_kozepen_teljes_szelessegben" && (
              <>
                <div className="p-col-12 p-text-center">
                  <div className="p-grid">
                    {taskItem["options"].map(function (option, index2) {
                      return (
                          <div className="p-col-12">
                            <div className="question-option">
                              <Button id={"btn-question-option-" + index2} label={(<><p>{option.name}</p>{option.hasOwnProperty("image") && <img className="option-image" src={option.image} />}</>)}  className={`button-answer btn-question-option p-button-outlined p-button-plain ${option.isCorrect ? ("btn-answer-correct") : ("btn-answer-incorrect")} `} onClick={() => answerQuestion("btn-question-option-"+index2, option.isCorrect)} />
                            </div>
                          </div>
                      )
                    })}   
                  </div>
                </div>
              </>
            )}
            {props.type == "kerdes_bal_oldalon_valaszok_jobb_oldalon_egymas_alatt" && (
              <>
                <div className="p-col-12 p-text-center">
                  <div className="p-grid">
                    <div className={`p-col-12 p-${name === "HovaIndulAVonat" ? "md" : "sm"}-6`}>
                      {taskItem["question"]["name"] && taskItem["question"]["name"]}
                      {taskItem["question"].hasOwnProperty("image") && <img className="question-image" src={taskItem["question"]["image"]} />}
                    </div>
                    <div className={`p-col-12 p-${name === "HovaIndulAVonat" ? "md" : "sm"}-6`}>
                      {taskItem["options"].map(function (option, index2) {
                        return (
                          <div className="question-option">
                            <Button id={"btn-question-option-" + index2} label={(<><p>{option.name}</p>{option.hasOwnProperty("image") && <img className="option-image" src={option.image} />}</>)}  className={`button-answer btn-question-option p-button-outlined p-button-plain ${option.isCorrect ? ("btn-answer-correct") : ("btn-answer-incorrect")} `} onClick={() => answerQuestion("btn-question-option-"+index2, option.isCorrect)} />
                          </div>  
                        )
                      })}     
                    </div>
                    
                  </div>
                </div>
              </>
            )}
            
            
            {((isAnswerCorrect && nextTaskItem) || always_correct) && (
              <>
                  <div className={`wrapper-btn-next-task ${always_correct && ("always_correct")} `}>
                    <Button label={<img src={ glob.icons["nextExerciseBig"] } width="50" />} className="btn-next-task p-button-rounded p-button-text" onClick={() => goNextSlide()} />
                    <div className="bg-of-btn-next-task"></div>
                  </div>
              </>
            )}
          
          </div>
        </div>
    </>
  );
};