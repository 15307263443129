import React, { createRef, useEffect, useState } from 'react';
import $ from 'jquery';
import { Button } from 'primereact/button';
import glob from '../../glob';
import KeyboardInput from '../../KeyboardInput';
import { useAppContext } from '../../appContext';
import Slider from 'react-slick';
import GameEndScreen from '../../GameEndScreen';

export default function JegyezdMegAPinKodot(props, { ...rest }) {
   const [userAnswer, setUserAnswer] = useState("");
   const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
   const [questionIndex, setQuestionIndex] = useState(0);
   const [answerClass, setAnswerClass] = useState("");
   const [taskConfig, setTaskConfig] = useState(false);
   const [showQuestion, setShowQuestion] = useState(false);
   const [currentSlide, setCurrentSlide] = useState(0);
   const [isInstructionSoundPlayed, setIsInstructionSoundPlayed] = useState(false);
   
   const myslider = createRef();

   const { setAppTitle } = useAppContext();

   var questions_ = [];
   while (questions_.length!=30) {
      var rand_pin = glob.getRandomIntFromInterval(1000, 9999);
      if (!questions_.includes(rand_pin)) {
         questions_.push(rand_pin);
      }
   }

   useEffect(() => {
      var questions = [];
      $.each(questions_, (index, item) => {
         var new_item = {
            "name": item
         }
         questions.push(new_item);
      });
      
      glob.shuffleArray(questions);
   
      
      var task_config = {};
      task_config["items"] = questions; //.slice(1, 3)

      setTaskConfig(task_config);
   }, []);
   /* 
   useEffect(() => {
      if (userAnswer === "" && answerClass != "") {
         setAnswerClass("");
      }
   }, [userAnswer, answerClass]); */

   useEffect(() => {
      if (showQuestion) {
         var apptitle_ = "Írd be a PIN-kódot!";
         setAppTitle(apptitle_);
         myslider.current.slickGoTo(1);
         if (!isInstructionSoundPlayed) {
            setIsInstructionSoundPlayed(true);
            glob.playInstructionSound(apptitle_);
         }

      } else {
         setAppTitle("");
         myslider.current.slickGoTo(0);
      }

      return () => {
         setAppTitle("");
      };
   }, [showQuestion]);


   var taskItem = taskConfig && taskConfig["items"][questionIndex];
   var nextTaskItem = false;
   nextTaskItem = taskConfig && taskConfig["items"][questionIndex + 1];

   const checkAnswer = () => {
      var is_correct = userAnswer == taskItem["name"];
      setIsAnswerCorrect(is_correct);
      setAnswerClass(is_correct ? "correct" : "incorrect");
      glob.playGameSound(is_correct ? "correct" : "incorrect");
      if (!is_correct) {
         setTimeout(function () {
            setAnswerClass("");
         }, 2000);   
      }
   }
   
   const goNextSlide = () => {
      setQuestionIndex(questionIndex + 1);
      setIsAnswerCorrect(false);
      setUserAnswer("");
      setAnswerClass("");
      setShowQuestion(false);
   }

   var sliderSettings = {
      dots: false,
      arrows: false,
      infinite: false,
      speed: 500,
      centerMode: true,
      slidesToShow: 1,
      draggable: false,
      slidesToScroll: 1,
      afterChange: current => setCurrentSlide(current),
      responsive: [
      {
         breakpoint: 680,
         settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
         }
      }
      ]
   };
   
   const resetGame = () => {
      var task_config = taskConfig;
      glob.shuffleArray(task_config["items"], true);
      setTaskConfig(task_config);
      
      setIsAnswerCorrect(false);
      setQuestionIndex(0);
      setUserAnswer("");
      setAnswerClass("");
      setShowQuestion(false);
   }

   if (!nextTaskItem && isAnswerCorrect) {
      return <GameEndScreen className="nomargintop" resetGame={ resetGame } />
   }
   return (
      <div className="task-wrapper task-wrapper-JegyezdMegAPinKodot">
         <Slider ref={myslider} {...sliderSettings} className="Slider-JegyezdMegAPinKodot" >
            <div className="slide1">
               <div className="wrapper-pin-question">
                  <p class="pin-characters">{taskItem && taskItem["name"]}</p>
                  {taskItem && <img className="pin-bg-img" src={require('../../../images/pinCodeBackground.jpg').default} />}
               </div>
               <Button label={<img src={ glob.icons["nextExerciseBig"] } width="50" />} className="btn-next-frame p-button-rounded p-button-text" onClick={() => setShowQuestion(showQuestion?false:true)} />
            </div>
            <div className="slide2">
               <KeyboardInput
                  className={answerClass}
                  onComponentChange={setUserAnswer}
                  value={userAnswer}
                  isAnswerCorrect={isAnswerCorrect}
                  type="numbers"
                  leftComponent={(taskItem) && <Button label={<img src={ glob.icons["image_again5"] } width="50" />} className="btn-image p-button-rounded p-button-text p-button-plain p-icon-fs-2rem" onClick={() => setShowQuestion(false)} />}
                  rightComponent={!isAnswerCorrect && <Button label={<img src={ glob.icons["check"] } width="50" />} className="btn-answer-check p-button-rounded p-button-text p-icon-fs-2rem" onClick={() => checkAnswer()} />}
               />
            </div>
         </Slider>
         {/* && currentSlide === 1 */}
         {(isAnswerCorrect && nextTaskItem) && (
            <>
               <div className="wrapper-btn-next-task">
                  <Button label={<img src={ glob.icons["nextExerciseBig"] } width="50" />} className="btn-next-task p-button-rounded p-button-text" onClick={() => goNextSlide()} />
                  <div className="bg-of-btn-next-task"></div>
               </div>
            </>
         )}
   </div>
   );
};