import React from 'react';
import $ from 'jquery';
import FrameImageGallery from '../FrameImageGallery';

export default function MitCsinal(props, { ...rest }) {
   
   var task_config = {
      "items": []
   };
   
   $.each(Array.from({length: 53}, (_, i) => (i+1)), (index, item) => {
      var new_item = (
         <img src={require('../../../images/tasks/mit_csinal/'+item+'.jpeg').default} alt="gallery image" />
      );
      task_config["items"].push(new_item);
   });
   var sliderSettings = {
      dots: false
   }
   // sliderSettings={ sliderSettings }
   return (
      <div className="task-wrapper task-wrapper-MitCsinal">
         <FrameImageGallery items={task_config["items"]} sliderSettings={ sliderSettings } customArrows={true} />
      </div>
   );
};