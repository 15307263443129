import React from 'react';
import $ from 'jquery';
import FrameImageGallery from '../FrameImageGallery';
import glob from '../../glob';

export default function MonddErzelemmel(props, { ...rest }) {
   
   var mondatok = [
      "A lány férjhez ment.",
      "Várakozom a postán.",
      "A fű megnőtt.",
      "A tanár feleltetett.",
      "Elveszett a kesztyűm.",
      "Üres a tank.",
      "Az ebéd kihűlt.",
      "A fagyi elfogyott.",
      "A szomszéd elköltözik.",
      "A színházi előadás elmaradt.",
      "A főnök nyaral.",
      "Rövid lett a hajam.",
      "Esik a hó.",
      "A kutyát etették.",
      "Megcsináltam a feladatot.",
      "A gépet kikapcsolták.",
      "Megírta a levelet.",
      "Megfőztük a lekvárt.",
      "Felébredt a baba.",
      "A fiú elköltözött.",
      "Lecserélte az autóját.",
      "Hullanak a levelek.",
      "A külföldiek megérkeztek.",
      "A házat lebontották.",
      "Kiesett a foga.",
      "A postás csengetett.",
      "A hűtő leolvadt.",
      "Várakozom a postán.",
      "Eleredt az eső.",
      "Elment az áram.",
      "Holnap almát szedünk.",
      "Kihíztam a ruhámat.",
      "Minden asztal foglalt.",
      "Kihúzták a fogát.",
      "A vonat elment.",
      "Az öcsém elpakolt.",
      "A cipőt leárazták.",
      "Vettem ajándékot.",
      "A kocsma bezárt.",
      "Megkaptam a vízumot.",
      "Holnap hétfő.",
      "Felhívott a barátnőm.",
      "A tó befagyott.",
      "A fű megnőtt.",
      "A tanár feleltetett.",
      "Elveszett a kesztyűm."
   ];
   
   glob.shuffleArray(mondatok);
   
   var erzelmek = [
      {
         "appTitle": "Mondd vidáman!",
         "image": require('../../../images/tasks/mondd_erzelemmel/vidaman.jpeg').default,
         "sound": require('../../../sounds/voice/_instrukciok/mondd_vidaman.mp3').default,
      }, {
         "appTitle": "Mondd szomorúan!",
         "image": require('../../../images/tasks/mondd_erzelemmel/szomoruan.jpeg').default,
         "sound": require('../../../sounds/voice/_instrukciok/mondd_szomoruan.mp3').default,
      }, {
         "appTitle": "Mondd csodálkozva!",
         "image": require('../../../images/tasks/mondd_erzelemmel/csodalkozva.jpeg').default,
         "sound": require('../../../sounds/voice/_instrukciok/mondd_csodalkozva.mp3').default,
      }, {
         "appTitle": "Mondd dühösen!",
         "image": require('../../../images/tasks/mondd_erzelemmel/duhosen.jpeg').default,
         "sound": require('../../../sounds/voice/_instrukciok/mondd_duhosen.mp3').default,
      }, {
         "appTitle": "Mondd unottan!",
         "image": require('../../../images/tasks/mondd_erzelemmel/unottan.jpeg').default,
         "sound": require('../../../sounds/voice/_instrukciok/mondd_unottan.mp3').default,
      }
   ];
   var task_config = {
      "config": [],
      "items": []
   };
   
   $.each(mondatok, (index, mondat) => {
      $.each(erzelmek, (index2, erzelem) => {
         var new_item = (
            <>
               <p>{mondat}</p>
               <img src={erzelem.image} alt="gallery image" />
            </>
         );
         task_config["items"].push(new_item);
         task_config["config"].push({
            "name":erzelem["appTitle"],
            "sound":erzelem["sound"],
         });
      });
   });

   var sliderSettings = {
      dots: false
   }
   return (
      <div className="task-wrapper task-wrapper-MonddErzelemmel">
         <FrameImageGallery items={task_config["items"]} taskConfig={task_config} gameTpye={"MonddErzelemmel"} sliderSettings={sliderSettings} customArrows={true} />
      </div>
   );
};