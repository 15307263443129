import React, { useState, useEffect, createRef } from 'react';

import Slider from "react-slick";
import { useParams } from 'react-router-dom';
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import glob from '../glob';
import { Button } from 'primereact/button';
import $ from 'jquery';


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", width: "50px" }}
      onClick={onClick}
    >
      <img src={ glob.icons["nextExerciseBig"] } />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", width: "50px" }}
      onClick={onClick}
    >
      <img src={ glob.icons["previousExerciseBig"] } />
    </div>
  );
}

export default function GameSelector(props, { ...rest }) {
  const history = useHistory();
  
  const [currentSlide, setCurrentSlide] = useState(0);
  const [gameTypeIndex, setGameTypeIndex] = useState(1);
  const [menuVideoIsPlaying, setMenuVideoIsPlaying] = useState(false);
  const [video, setVideo] = useState(null);
  const location = useLocation();
  const { paramGameCategory } = useParams();
  var { paramPreviewTaskId } = useParams();
  paramPreviewTaskId = parseInt(paramPreviewTaskId);

  const myslider = createRef();

  var gameCategory = glob.menu_items[paramGameCategory];
  var itemIsGame = gameCategory.type == "game";
  var slideCount = (gameCategory.hasOwnProperty('tasks')) ? gameCategory["tasks"].length : 0;
  var myTimer;

  var sliderSettings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    /* TODO draggable: false,  */
    afterChange: current => setCurrentSlide(current),
    beforeChange: current => muteVideos(),
    responsive: [
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false
        }
      }
    ]
  };
  /* sliderSettings["prevArrow"] = <SamplePrevArrow className="customArrow" />;
  sliderSettings["nextArrow"] = <SampleNextArrow className="customArrow" />;
 */
  var starttime;
  var endtime;

  
  useEffect(() => {
    if (isNaN(paramPreviewTaskId)) {
      var new_url = `/submenu/${paramGameCategory}/0`;
      history.push(new_url);
    } else {
      if (itemIsGame) {
        var new_url = `/submenu/${paramGameCategory}/${paramPreviewTaskId}`;
        setCurrentSlide(paramPreviewTaskId);
      }
    }
  }, []);
  
  var vid;
  useEffect(() => {
    if (itemIsGame) {
      if (paramPreviewTaskId != currentSlide) {
        var new_url = `/submenu/${paramGameCategory}/${currentSlide}`;
        history.push(new_url);
      }
      
      if (myslider.current) {
        if (currentSlide !== 0) {
          myslider.current.slickGoTo(currentSlide);  
        }
      }
      
      muteVideos();
      myTimer = setTimeout(function () {
        muteVideos();
        vid = document.getElementById("menuvideo" + currentSlide);
        if (vid) {
          vid.play();
          setMenuVideoIsPlaying(true);
        }  
      }, 2000);
      
      
    }
    
  }, [currentSlide]);

  
  const muteVideos = () => {
    setMenuVideoIsPlaying(false);
    clearTimeout(myTimer);
    var menuvideos = document.getElementsByClassName("menuvideo");
    for (var i = 0; i < menuvideos.length; i++) {
      var vid_ = document.getElementById("menuvideo" + i);
      vid_.pause();
      vid_.load();
    }
  }

  const openGame = (path) => {
    history.push(path);
  }

  return (
    <>
      {/* <p>Játék sorszáma: <b>{paramGameCategory}</b> | Játék neve: <b>{gameCategory["name"]}</b></p>
      <p>currentSlide: <b>{currentSlide}</b></p> */}
      {itemIsGame ? (
        <>
          {gameCategory.hasOwnProperty('tasks') && (
            <Slider ref={myslider} {...sliderSettings} className={`SliderGameSelector ${gameCategory["tasks"].length<2 ? "float-init-slick-slide" : ""} `}>
              {gameCategory["tasks"].map(function (item, index) {
                // onClick={()=>openGame("/game/" + paramGameCategory + "/" + currentSlide)}
                return (
                  <div className="game-item">
                    {item.hasOwnProperty("preview") && (
                      <>
                        <img  className={`video-preview ${menuVideoIsPlaying && ("hide")} `} src={ item.poster } />
                        <video id={"menuvideo"+index} className="menuvideo" width="320" poster={item.poster} >
                          <source src={item.preview} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </>
                    )}
                  </div>
                )
              })}
            </Slider>  
          )}
          {gameCategory["tasks"][currentSlide].hasOwnProperty("difficultyLevels") && (
            <>
              <div className="wrapper-game-type-selector">
                {gameCategory["tasks"][currentSlide]["difficultyLevels"].map(function (item, index) {
                  return <Button label={index+1} className={`p-button-text p-p-0 ${gameTypeIndex==index+1 ? ("selected") : ("")} `} onClick={() => setGameTypeIndex(index+1)} />
                })}
              </div>  
            </>
          )}
            
            <RouterLink className="button-play-game" to={`/game/${paramGameCategory}/${paramPreviewTaskId}${gameCategory["tasks"][currentSlide].hasOwnProperty("difficultyLevels") ? ("/0/"+gameTypeIndex) : ("")}`}>
              <Button label={ <img src={ glob.icons["play"] } width="40" />} className="p-button-text p-button-rounded" component={RouterLink} />  
            </RouterLink>
            {gameCategory["tasks"][currentSlide].hasOwnProperty("rightIcon") && <img className="img-right-icon" src={gameCategory["tasks"][currentSlide]["rightIcon"]} />}
        </>
      ): (
          <>
            {gameCategory.component}
          </>
      )}
      
    </>
  );
};