import React, { useState } from 'react';
import $ from 'jquery';
import { Button } from 'primereact/button';
import glob from '../../glob';
import FindAllInTable from '../../FindAllInTable';

export default function KeresdMegAzOsszesBetut(props, { ...rest }) {
   
   var targets = [
      {
         "name": "a",
         "value": "a"
      }, {
         "name": "á",
         "value": "aa"
      }, {
         "name": "b",
         "value": "b"
      }, {
         "name": "c",
         "value": "c"
      }, {
         "name": "cs",
         "value": "cs"
      }, {
         "name": "d",
         "value": "d"
      }, {
         "name": "e",
         "value": "e"
      }, {
         "name": "é",
         "value": "ee"
      }, {
         "name": "f",
         "value": "f"
      }, {
         "name": "f",
         "value": "f"
      }, {
         "name": "g",
         "value": "g"
      }, {
         "name": "gy",
         "value": "gy"
      }, {
         "name": "h",
         "value": "h"
      }, {
         "name": "i",
         "value": "i"
      }, {
         "name": "í",
         "value": "ii"
      }, {
         "name": "j",
         "value": "j"
      }, {
         "name": "k",
         "value": "k"
      }, {
         "name": "l",
         "value": "l"
      }, {
         "name": "ly",
         "value": "ly"
      }, {
         "name": "m",
         "value": "m"
      }, {
         "name": "n",
         "value": "n"
      }, {
         "name": "ny",
         "value": "ny"
      }, {
         "name": "o",
         "value": "o"
      }, {
         "name": "ó",
         "value": "oo"
      }, {
         "name": "ö",
         "value": "ooo"
      }, {
         "name": "ő",
         "value": "oooo"
      }, {
         "name": "p",
         "value": "p"
      }, {
         "name": "r",
         "value": "r"
      }, {
         "name": "s",
         "value": "s"
      }, {
         "name": "sz",
         "value": "sz"
      }, {
         "name": "t",
         "value": "ty"
      }, {
         "name": "u",
         "value": "u"
      }, {
         "name": "ú",
         "value": "uu"
      }, {
         "name": "ü",
         "value": "uuu"
      }, {
         "name": "ű",
         "value": "uuuu"
      }, {
         "name": "v",
         "value": "v"
      }, {
         "name": "z",
         "value": "z"
      }, {
         "name": "zs",
         "value": "zs"
      }
   ];
   var hun_alphabet = ["a", "á", "b", "c", "cs", "d", "dz", "dzs", "e", "é", "f", "g", "gy", "h", "i", "í", "j", "k", "l", "ly", "m", "n", "ny", "o", "ó", "ö", "ő", "p", "q", "r", "s", "sz", "t", "ty", "u", "ú", "ü", "ű", "v", "w", "x", "y", "z", "zs"];
   var questions = [];
   $.each(targets, (index, item) => {
      var opts_ = [];
      opts_.push({
         "name": item["name"],
         "isCorrect": true
      });
      var chosed_letters = [];
      while (opts_.length!=8) {
         var rand_item = glob.getRandomElementFromArray(hun_alphabet);
         if (item["name"] != rand_item && !chosed_letters.includes(rand_item)) {
            chosed_letters.push(rand_item);
            opts_.push({
               "name": rand_item,
               "isCorrect": false
            });
         }
      }

      var multiplied_items = [];
      
      var num_of_correct_answer = glob.getRandomIntFromInterval(8, 10);
      while (multiplied_items.length != num_of_correct_answer) {
         multiplied_items.push(opts_[0]);
      }
      while (multiplied_items.length != 50) {
         multiplied_items.push(opts_[glob.getRandomIntFromInterval(1, opts_.length-1)]);
      }
      
      glob.shuffleArray(multiplied_items);
      
      var new_item = {
         "question": {
            "name": item["name"],
            "sound": require('../../../sounds/voice/keresd_meg_az_osszes_betut/' + item["value"] + '.mp3').default
         },
         "options": multiplied_items
      };
      questions.push(new_item);
   });
   
   glob.shuffleArray(questions);
   
   var task_config = {
      "apptitle": "letter",
   };

   task_config["items"] = questions;//;.slice(1, 3)
   
   return (
      <div className="task-wrapper task-wrapper-KeresdMegAzOsszesBetut">
         <FindAllInTable taskConfig={ task_config } />
      </div>
   );
};