import React from 'react';
import $ from 'jquery';
import glob from '../../glob';
import QuestionItem from '../../QuestionItem';

export default function MelyikSzotHallod(props, { ...rest }) {
   var questions_ = [];
   
   $.each(glob.words, (index, item) => {
      if (glob.word_categories.includes(item["question"]["name"])) {
         $.each(item["options"], (index, item2) => {
            item2["category"] = item["question"]["name"];
         });
         questions_ = questions_.concat(item["options"]);
      }
   });
   
   glob.shuffleArray(questions_);
   console.log("questions_: ", questions_);
   var questions = [];
   $.each(questions_, (index, item) => {
      var question_item = item;
      console.log("question_item: ", question_item);

      var opts_ = [{
         "image": question_item["image"],
         "sound": question_item["sound"],
         "isCorrect": true
      }];
      
      var selected_options_ = [];
      while (opts_.length != 4) {
         var rand_item = glob.getRandomElementFromArray(questions_);
         if (question_item["name"] != rand_item["name"] && !selected_options_.includes(rand_item["image"]) && rand_item["category"] == question_item["category"]) {
            selected_options_.push(rand_item["image"]);
            
            opts_.push({
               "image": rand_item["image"],
               "sound": rand_item["sound"],
               "isCorrect": false
            });   
         }
      }

      var new_item = {
         "question": {
            "sound": question_item["sound"],
         },
         "options": opts_
      };
      questions.push(new_item);
   });
   
   glob.shuffleArray(questions);
   $.each(questions, (index, item) => {
      glob.shuffleArray(questions[index]["options"]);
   });

   var task_config = {"playTaskItemSound":true};
   
   task_config["items"] = questions; //.slice(1, 3)

   return (
      <div className="task-wrapper task-wrapper-MelyikSzotHallod">
         <QuestionItem type="kerdes_bal_oldalon_valaszok_jobb_oldalon" taskConfig={task_config} />
      </div>
   );
};