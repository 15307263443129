import React, { useEffect, useState } from 'react';
import $ from 'jquery';

import ShuffleLetters from '../../ShuffleLetters';
import glob from '../../glob';

export default function RakjRendetOsszekeveredettBetuk(props, { ...rest }) {
   const [taskConfig, setTaskConfig] = useState(false);

   useEffect(() => {
      var questions_ = [];
      $.each(glob.words, (index, item) => {
         if (glob.word_categories.includes(item["question"]["name"])) {
            $.each(item["options"], (index, item2) => {
               item2["category"] = item["question"]["name"];
            });
            questions_ = questions_.concat(item["options"]);
         }
      });
      glob.shuffleArray(questions_);
      
      var task_config = {};
         
      task_config["items"] = questions_; //.slice(1, 3)
      setTaskConfig(task_config);
   }, []);

   return (
      <div className="task-wrapper task-wrapper-RakjRendetOsszekeveredettBetuk">
         {taskConfig["items"] && <ShuffleLetters questions={taskConfig["items"]} shuffleAll={true} />}
      </div>
   );
};