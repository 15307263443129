import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import glob from '../../glob';
import KeyboardInput from '../../KeyboardInput';
import { Button } from 'primereact/button';
import GameEndScreen from '../../GameEndScreen';

export default function FejezdBeAMondatot(props, { ...rest }) {
   const [userAnswer, setUserAnswer] = useState("");
   const [taskConfig, setTaskConfig] = useState(false);
   const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
   const [questionIndex, setQuestionIndex] = useState(0);
   const [answerClass, setAnswerClass] = useState("");
   const [showQuestion, setShowQuestion] = useState(false);
   const [taskItem, setTaskItem] = useState(false);
   const [nextTaskItem, setNextTaskItem] = useState(false);
   

   var userAnswers = [];
/* 
   useEffect(() => {
      
      console.log("userAnswers: ",userAnswers);
      console.log("userAnswer: ",userAnswer, "userAnswers[questionIndex]: ", userAnswers[questionIndex]);
      
      if (userAnswer != userAnswers[questionIndex] && userAnswers[questionIndex]) {
         console.log("setuserAnswer: ", userAnswers[questionIndex]);
         setUserAnswer(userAnswers[questionIndex]);
      }
      
   }, [questionIndex]); */

   var questions = [
     {
       question: {
         name: "A férfi édesítőt tesz a tea",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/80.jpeg")
             .default,
       },
       options: ["teába", "teájába"],
     },
     {
       question: {
         name: "A nő cukrot szór a kávé",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/42.jpeg")
             .default,
       },
       options: ["kávéba", "kávéjába"],
     },
     {
       question: {
         name: "A nő lerészegedett a bor",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/81.jpeg")
             .default,
       },
       options: ["bortól"],
     },
     {
       question: {
         name: "A nő simogatja a kisfia mellkas",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/3.jpeg")
             .default,
       },
       options: ["mellkasát"],
     },
     {
       question: {
         name: "Az apa bekapcsolja a gyerekét a biztonsági öv",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/7.jpeg")
             .default,
       },
       options: ["övvel"],
     },
     {
       question: {
         name: "Két barát házaspár tüzet rakott a vízpart",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/24.jpeg")
             .default,
       },
       options: ["vízparton"],
     },
     {
       question: {
         name: "A férfi kiveszi a levelet a boríték",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/27.jpeg")
             .default,
       },
       options: ["borítékból"],
     },
     {
       question: {
         name: "A kislány lelkesen tanul zongorázni a zongoratanárnő",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/5.jpeg")
             .default,
       },
       options: ["zongoratanárnőjétől", "zongoratanárnőtől"],
     },
     {
       question: {
         name: "A férfi ráüt az ujjára egy kalapács",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/82.jpeg")
             .default,
       },
       options: ["kalapáccsal"],
     },
     {
       question: {
         name: "A kutya vizet iszik egy palack",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/52.jpeg")
             .default,
       },
       options: ["palackból"],
     },
     {
       question: {
         name: "A lány mezítláb sétál a fű",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/54.jpeg")
             .default,
       },
       options: ["füvön", "fűben"],
     },
     {
       question: {
         name: "A férfi fát rak a tűz",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/41.jpeg")
             .default,
       },
       options: ["tűzre"],
     },
     {
       question: {
         name: "A kislánynak bajsza lett a tej",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/47.jpeg")
             .default,
       },
       options: ["tejtől"],
     },
     {
       question: {
         name: "A nő a mezőn játszik egy drón",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/19.jpeg")
             .default,
       },
       options: ["drónnal"],
     },
     {
       question: {
         name: "A férfi meggyújtja a cigarettát egy öngyújtó",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/13.jpeg")
             .default,
       },
       options: ["öngyújtóval", "öngyújtójával"],
     },
     {
       question: {
         name: "A stewardess megkínálja a férfit egy kávé",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/74.jpeg")
             .default,
       },
       options: ["kávéval"],
     },
     {
       question: {
         name: "A gyerek elvesz egy paradicsomot az anya tenyér",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/1.jpeg")
             .default,
       },
       options: ["tenyeréből"],
     },
     {
       question: {
         name: "A vendég kifizeti a kávét a kártya",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/23.jpeg")
             .default,
       },
       options: ["kártyájával", "kártyával"],
     },
     {
       question: {
         name: "A háziasszony kiveszi a tiszta ruhákat a mosógép",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/63.jpeg")
             .default,
       },
       options: ["mosógépből"],
     },
     {
       question: {
         name: "Az anya vidáman reggelizik a kislány",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/4.jpeg")
             .default,
       },
       options: ["kislányával", "kislánnyal"],
     },
     {
       question: {
         name: "A nő megkeni a sütemény tetejét a krém",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/22.jpeg")
             .default,
       },
       options: ["krémmel"],
     },
     {
       question: {
         name: "Az anya meggyújtja a szülinapi gyertyákat a torta",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/49.jpeg")
             .default,
       },
       options: ["tortán"],
     },
     {
       question: {
         name: "A kisfiú bele akar nézni a sütő",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/45.jpeg")
             .default,
       },
       options: ["sütőbe"],
     },
     {
       question: {
         name: "A nő beleteszi a tojásokat a liszt",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/64.jpeg")
             .default,
       },
       options: ["lisztbe"],
     },
     {
       question: {
         name: "A háziasszony cukrot szór a tojás",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/60.jpeg")
             .default,
       },
       options: ["tojásra", "tojásokra", "tojáshoz", "tojásokhoz"],
     },
     {
       question: {
         name: "A tanuló levesz egy könyvet a polc",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/30.jpeg")
             .default,
       },
       options: ["polcról"],
     },
     {
       question: {
         name: "Barátnők ülnek a pad",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/40.jpeg")
             .default,
       },
       options: ["padon"],
     },
     {
       question: {
         name: "Kollégák kacagnak a saját fénykép",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/85.jpeg")
             .default,
       },
       options: ["fényképeiken"],
     },
     {
       question: {
         name: "A feleség lekever egy pofont a férfi",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/34.jpeg")
             .default,
       },
       options: ["férfinek", "férfinak"],
     },
     {
       question: {
         name: "A szerelmes pár együtt mossa a fog",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/31.jpeg")
             .default,
       },
       options: ["fogát"],
     },
     {
       question: {
         name: "A csinos sofőr kifényképez az autó",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/59.jpeg")
             .default,
       },
       options: ["autóból"],
     },
     {
       question: {
         name: "Egy vidám pár sétál az erdő",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/32.jpeg")
             .default,
       },
       options: ["erdőben"],
     },
     {
       question: {
         name: "A fiú megmérte a lázát egy lázmérő",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/11.jpeg")
             .default,
       },
       options: ["lázmérővel"],
     },
     {
       question: {
         name: "A hölgy vesz egy könyvet a könyvesbolt",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/26.jpeg")
             .default,
       },
       options: ["könyvesboltban"],
     },
     {
       question: {
         name: "Az eladó átnyújtja a keresett könyvet a férfi",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/51.jpeg")
             .default,
       },
       options: ["férfinek", "férfinak"],
     },
     {
       question: {
         name: "A kisfiú egy csillagszórót tart a kéz",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/16.jpeg")
             .default,
       },
       options: ["kezében"],
     },
     {
       question: {
         name: "A nő feltálalta a pulykát egy nagy tál",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/75.jpeg")
             .default,
       },
       options: ["tálra", "tálon"],
     },
     {
       question: {
         name: "Az orvos megvizsgálja a férfi fül",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/9.jpeg")
             .default,
       },
       options: ["fülét", "füleit"],
     },
     {
       question: {
         name: "A kislány valamit súg a nagymama fül",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/83.jpeg")
             .default,
       },
       options: ["fülébe"],
     },
     {
       question: {
         name: "A lány meglepi az édesapját egy ajándék",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/29.jpeg")
             .default,
       },
       options: ["ajándékkal"],
     },
     {
       question: {
         name: "A kislány tiszta csoki lett a fagyi",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/17.jpeg")
             .default,
       },
       options: ["fagyitól"],
     },
     {
       question: {
         name: "A férfi unottan bámul a tükör",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/28.jpeg")
             .default,
       },
       options: ["tükörbe"],
     },
     {
       question: {
         name: "A nő lecsapja az ébresztőóra",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/20.jpeg")
             .default,
       },
       options: ["ébresztőórát", "ébresztőóráját"],
     },
     {
       question: {
         name: " A baba figyel az apja",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/6.jpeg")
             .default,
       },
       options: ["apjára"],
     },
     {
       question: {
         name: "A férfi kirepül a hinta",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/38.jpeg")
             .default,
       },
       options: ["hintából"],
     },
     {
       question: {
         name: "A kisfiú készít egy közeli képet a kutya",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/46.jpeg")
             .default,
       },
       options: ["kutyáról", "kutyájáról"],
     },
     {
       question: {
         name: "A férfi szőlőt ad a nő",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/66.jpeg")
             .default,
       },
       options: ["nőnek"],
     },
     {
       question: {
         name: "A gyerek kivesz egy almát a láda",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/37.jpeg")
             .default,
       },
       options: ["ládából"],
     },
     {
       question: {
         name: "A nagypapa leszed egy barackot az unoka",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/58.jpeg")
             .default,
       },
       options: ["unokájának"],
     },
     {
       question: {
         name: "A család elment kirándulni az erdő",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/44.jpeg")
             .default,
       },
       options: ["erdőbe"],
     },
     {
       question: {
         name: "Zöldségek vannak a kosár",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/88.jpeg")
             .default,
       },
       options: ["kosárban"],
     },
     {
       question: {
         name: "A paradicsomok kiborultak a kosár",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/70.jpeg")
             .default,
       },
       options: ["kosárból"],
     },
     {
       question: {
         name: "Az egér kinéz a lyuk",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/21.jpeg")
             .default,
       },
       options: ["lyukból"],
     },
     {
       question: {
         name: "A bagolyfióka kinéz az odú",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/10.jpeg")
             .default,
       },
       options: ["odúból", "odújából"],
     },
     {
       question: {
         name: "A család ősszel leveleket gyűjt a talicska",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/67.jpeg")
             .default,
       },
       options: ["talicskába"],
     },
     {
       question: {
         name: "Az ifjú házasok tévét néznek a kanapé",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/33.jpeg")
             .default,
       },
       options: ["kanapéról", "kanapén", "kanapéjukról"],
     },
     {
       question: {
         name: "A férfiak munka után megisznak egy sört a kocsma",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/15.jpeg")
             .default,
       },
       options: ["kocsmában"],
     },
     {
       question: {
         name: "A barátnő megrémült a hír",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/56.jpeg")
             .default,
       },
       options: ["hírtől"],
     },
     {
       question: {
         name: "A nagymama képeket mutat az unokájának az album",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/57.jpeg")
             .default,
       },
       options: ["albumból", "albumban"],
     },
     {
       question: {
         name: "Az apa mesét olvas a kisfiának a mesekönyv",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/8.jpeg")
             .default,
       },
       options: ["mesekönyvből"],
     },
     {
       question: {
         name: "A nő választ egy sütit a hűtő",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/78.jpeg")
             .default,
       },
       options: ["hűtőből"],
     },
     {
       question: {
         name: "A lány virágot ad az anya",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/48.jpeg")
             .default,
       },
       options: ["anyjának", "anyukájának"],
     },
     {
       question: {
         name: "A nő elteszi a visszajárót a pénztárca",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/55.jpeg")
             .default,
       },
       options: ["pénztárcájába", "pénztárcába"],
     },
     {
       question: {
         name: "A nő ráállt a mérleg",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/62.jpeg")
             .default,
       },
       options: ["mérlegre"],
     },
     {
       question: {
         name: "A férfi kiveszi a kifliket a sütő",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/35.jpeg")
             .default,
       },
       options: ["sütőből"],
     },
     {
       question: {
         name: "Süni oson a fű",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/76.jpeg")
             .default,
       },
       options: ["fűben", "füvön"],
     },
     {
       question: {
         name: "Sok finomság került elő a piknik kosár",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/71.jpeg")
             .default,
       },
       options: ["kosárból"],
     },
     {
       question: {
         name: "A pincér bort tölt a palack",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/86.jpeg")
             .default,
       },
       options: ["palackból"],
     },
     {
       question: {
         name: "A takarítónő tisztítja a fugát egy kefe",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/68.jpeg")
             .default,
       },
       options: ["kefével"],
     },
     {
       question: {
         name: "A takarítónő rásöpri a szemetet a lapát",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/72.jpeg")
             .default,
       },
       options: ["lapátra"],
     },
     {
       question: {
         name: "A nő megtisztítja a cipőjét egy kefe",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/14.jpeg")
             .default,
       },
       options: ["kefével"],
     },
     {
       question: {
         name: "A nő kihúzza a szemöldökét egy szempillaspirál",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/79.jpeg")
             .default,
       },
       options: ["szempillaspirállal"],
     },
     {
       question: {
         name: "A fiú újságot olvas a pad",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/69.jpeg")
             .default,
       },
       options: ["padon"],
     },
     {
       question: {
         name: "A lány beleharap az alma",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/2.jpeg")
             .default,
       },
       options: ["almába"],
     },
     {
       question: {
         name: "A barátnők jóízűen eszik a dinnye",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/18.jpeg")
             .default,
       },
       options: ["dinnyét"],
     },
     {
       question: {
         name: "A cukrász cukrot szór a fánk",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/25.jpeg")
             .default,
       },
       options: ["fánkra"],
     },
     {
       question: {
         name: "A nő egy cukorral issza a kávé",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/61.jpeg")
             .default,
       },
       options: ["kávét", "kávéját"],
     },
     {
       question: {
         name: "A nő valamit keres a doboz",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/50.jpeg")
             .default,
       },
       options: ["dobozban"],
     },
     {
       question: {
         name: "A nő gombát szeletel egy éles kés",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/87.jpeg")
             .default,
       },
       options: ["késsel"],
     },
     {
       question: {
         name: "A kislányok összeállítják a tésztát a sütemény",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/43.jpeg")
             .default,
       },
       options: ["süteményhez"],
     },
     {
       question: {
         name: "A föld kiborult a virágcserép",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/84.jpeg")
             .default,
       },
       options: ["virágcserépből"],
     },
     {
       question: {
         name: "A háziasszony olajat önt a saláta",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/73.jpeg")
             .default,
       },
       options: ["salátára"],
     },
     {
       question: {
         name: "A szakállas férfi megköti a nyakkendő",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/65.jpeg")
             .default,
       },
       options: ["nyakkendőjét"],
     },
     {
       question: {
         name: "A lány összehajtogatja a ruhákat és becsomagolja a bőrönd",
         image:
           require("../../../images/stockphotos/grammatikai_morfemak/12.jpeg")
             .default,
       },
       options: ["bőröndbe", "bőröndjébe"],
     },
   ];

   useEffect(() => {
      console.log("variable: ", getModifiedWord("kéz", "kezében"));
      console.log("variable2: ", getModifiedWord(glob.getFileName("kéz"), glob.getFileName("kezében")));

      glob.shuffleArray(questions);
      $.each(questions, (index, item) => {
         var sentence_words = questions[index]["question"]["name"].split(" ");
         var last_word = sentence_words[sentence_words.length - 1];

         sentence_words.pop();
         console.log("sentence_words: ", sentence_words);
         
         
         var mods = [];
         $.each(questions[index]["options"], (index, item) => {
            var to_push = getModifiedWord(last_word, item);
            if (last_word == "kéz") {
               to_push = ["kez", item];
            } else if(last_word == "tenyér") {
               to_push = ["teny", item];
            }
            mods.push(to_push);
         });
         var target_mod = mods[0];
         console.log(last_word, " mods: ", mods);
         sentence_words.push(target_mod[0]);
         userAnswers.push(target_mod[1]);
         var new_sentence = sentence_words.join(" ");

         console.log("new_sentence: ", new_sentence, " Options: ", questions[index]["options"]);
         var passed = false;
         $.each(questions[index]["options"], (index, item) => {
            if (item.includes(target_mod[0])) {
               passed = true;
            }
         });
         if (!passed) {
            console.log("@@ not passed");   
         }
         
         questions[index]["question"]["name"] = new_sentence;
         questions[index]["question"]["userAnswer"] = target_mod[1];
         
      });

      var task_config = {};
         
      task_config["items"] = questions; //.slice(1, 3)
      setTaskConfig(task_config);
   }, []);
   /* 
   useEffect(() => {
      if (userAnswer === "" && answerClass != "") {
         setAnswerClass("");
      }
   }, [userAnswer, answerClass]); */

   useEffect(() => {
      var taskItem_ = taskConfig && taskConfig["items"][questionIndex];
      var nextTaskItem_ = false;
      nextTaskItem_ = taskConfig && taskConfig["items"][questionIndex + 1];
      setTaskItem(taskItem_);
      setNextTaskItem(nextTaskItem_);/* 
      if (taskItem_ && taskItem_["question"]["userAnswer"] != userAnswer) {
         setUserAnswer(taskItem_["question"]["userAnswer"]);
      } */
   }, [taskConfig, questionIndex]);
   
   

   const getModifiedWord = (word1, word2) => {
      var word1_letters = word1.split("");
      var word2_letters = word2.split("");
      var cut_index = -1;
      var modified_word1 = "";
      var modified_word2 = "";
      $.each(word2_letters, (index, item) => {
         if (word1_letters[index]) {
            if (item != word1_letters[index]) {
               cut_index = index;
               modified_word2 += word1_letters[index];
            } else {
               modified_word1 += word1_letters[index];
            }
         }
      });
      return [modified_word1, modified_word2]
   }

   const checkAnswer = () => {
     var is_correct = false;
     
     $.each(taskItem["options"], (index, item) => {
       var last_word_of_sentence = taskItem["question"]["name"].split(" ")[taskItem["question"]["name"].split(" ").length - 1];
       var answer_have_to_mach = item.replace(last_word_of_sentence, "");
       console.log("checkAnswer: ", userAnswer, taskItem["options"], answer_have_to_mach);
         if ((userAnswer != "" && item != "") && (answer_have_to_mach == userAnswer)) {
            is_correct = true;
         }
      });

      setIsAnswerCorrect(is_correct);
      setAnswerClass(is_correct ? "correct" : "incorrect");
      glob.playGameSound(is_correct ? "correct" : "incorrect");
      if (!is_correct) {
         setTimeout(function () {
            setAnswerClass("");
         }, 2000);   
      }
   }

   const goNextSlide = () => {
      setQuestionIndex(questionIndex + 1);
      setIsAnswerCorrect(false);
      setUserAnswer("");
      setAnswerClass("");
      setShowQuestion(false);
   }

  const resetGame = () => {
     
      var task_config = taskConfig;
      glob.shuffleArray(task_config["items"], true);
      setTaskConfig(task_config);
      
      setQuestionIndex(0);
      setIsAnswerCorrect(false);
      setUserAnswer("");
      setAnswerClass("");
      setShowQuestion(false);
   }

   if (!nextTaskItem && isAnswerCorrect) {
      return <GameEndScreen className="nomargintop" resetGame={ resetGame } />
   }
   return (
      <div className="task-wrapper task-wrapper-FejezdBeAMondatot">
         {!showQuestion ? (
            <>
               <div className="p-grid p-text-center">
                  <div className="p-col-12">
                     <h1>{taskItem && taskItem["question"]["name"]}<span className="answer_line">____</span>.</h1>
                     {taskItem && <img className="question-image" src={taskItem["question"]["image"]} />}
                     <Button label={<img src={ glob.icons["nextExerciseBig"] } width="50" />} className="btn-next-frame p-button-rounded p-button-text" onClick={() => setShowQuestion(showQuestion?false:true)} />
                  </div>
               </div>
            </>
         ) : (
            <>
               <KeyboardInput
                  className={answerClass}
                  onComponentChange={setUserAnswer}
                  value={userAnswer}
                  isAnswerCorrect={isAnswerCorrect}
                  /* espectedValue={taskItem["question"]["userAnswer"]} */
                  type="words"
                  leftComponent={(!isAnswerCorrect && taskItem) && <Button label={<img src={ glob.icons["image_again5"] } width="50" />} className="btn-image p-button-rounded p-button-text p-button-plain p-icon-fs-2rem" onClick={() => setShowQuestion(false)} />}
                  rightComponent={<>{!isAnswerCorrect && <Button label={<img src={ glob.icons["check"] } width="50" />} className="btn-answer-check p-button-rounded p-button-text p-icon-fs-2rem" onClick={() => checkAnswer()} />}</>}
                  middleComponent={<><h1>{taskItem && taskItem["question"]["name"]}<span className="answer_line">{userAnswer != "" ? userAnswer : <span className="blank_character"></span>}</span>.</h1></>}
                  hideInput={true}
               />
               {(isAnswerCorrect && nextTaskItem) && (
                  <>
                     <div className="wrapper-btn-next-task">
                        <Button label={<img src={ glob.icons["nextExerciseBig"] } width="50" />} className="btn-next-task p-button-rounded p-button-text" onClick={() => goNextSlide()} />
                        <div className="bg-of-btn-next-task"></div>
                     </div>
                  </>
               )}
            </>
         )}
      </div>
   );
};