import React from 'react';
import $ from 'jquery';
import FrameImageGallery from '../FrameImageGallery';
import glob from '../../glob';

export default function MonddMegASzoSzinet(props, { ...rest }) {
   var task_config = {
      "items": []
   };
   
   const getRandomWord = () => {
      var colors = {
         "barna": "#A52A2A",
         "fehér": "#F8F8FF",
         "fekete": "#000000",
         "kék": "#0000FF",
         "lila": "#800080",
         "narancs": "#FFA500",
         "piros": "#FF0000",
         "rózsaszín": "#FF1493",
         "sárga": "#FFFF00",
         "szürke": "#A9A9A9",
         "zöld": "#00FF00",
         "ciklámen": "#c41c5b"
      }
      var words = Object.keys(colors);
      var word = glob.getRandomElementFromArray(words);
      var color = "";
      var found_match = true;
      while (found_match) {
         color = glob.getRandomElementFromArray(words);
         if (color != word) {
            found_match = false;
         }
      }
      
      var class_ = "";
      if (["fehér", "sárga"].includes(color)) {
         class_ = "border-black"
      }
      
      return (
         <p className={class_+ " question-word"} style={{color:colors[color]}}>{word}</p>
      );
   }
   $.each(Array.from({ length: 30 }, (_, i) => (i + 1)), (index, item) => {
      var new_item = getRandomWord();
      task_config["items"].push(new_item);
   });

   return (
      <div className="task-wrapper task-wrapper-MonddMegASzoSzinet">
         <FrameImageGallery items={task_config["items"]} customArrows={true} />
      </div>
   );
};