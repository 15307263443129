import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { Container, Draggable } from 'react-smooth-dnd';

import { applyDrag } from '../utils';
import { Button } from 'primereact/button';

import glob from '../glob';
import GameEndScreen from '../GameEndScreen';
import { useAppContext } from '../appContext';

const groupStyle = {
  margin: '50px',
  overflowX: 'auto',
  // border: '1px solid #ccc'
};
 
export default function ShuffleLetters(props, { ...rest }) {
   const [items, setItems] = useState([]);
   const [questionIndex, setQuestionIndex] = useState(0);
   const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
   const [nextTaskItem, setNextTaskItem] = useState(false);
   const [isDragStarted, setIsDragStarted] = useState(false);
   const [answerClass, setAnswerClass] = useState("");
   const [dragBeginDelay, setDragBeginDelay] = useState(10);
   
   const [gameStarted, setGameStarted] = useState(false);

   const { gameInstructionIsActive } = useAppContext();

  var questions = props.questions;
  var shuffleAll = props.shuffleAll;

   var letters = [
    {
       "name": "a",
       "value": "a"
    }, {
       "name": "á",
       "value": "aa"
    }, {
       "name": "b",
       "value": "b"
    }, {
       "name": "c",
       "value": "c"
    }, {
       "name": "cs",
       "value": "cs"
    }, {
       "name": "d",
       "value": "d"
    }, {
       "name": "e",
       "value": "e"
    }, {
       "name": "é",
       "value": "ee"
    }, {
       "name": "f",
       "value": "f"
    }, {
       "name": "f",
       "value": "f"
    }, {
       "name": "g",
       "value": "g"
    }, {
       "name": "gy",
       "value": "gy"
    }, {
       "name": "h",
       "value": "h"
    }, {
       "name": "i",
       "value": "i"
    }, {
       "name": "í",
       "value": "ii"
    }, {
       "name": "j",
       "value": "j"
    }, {
       "name": "k",
       "value": "k"
    }, {
       "name": "l",
       "value": "l"
    }, {
       "name": "ly",
       "value": "ly"
    }, {
       "name": "m",
       "value": "m"
    }, {
       "name": "n",
       "value": "n"
    }, {
       "name": "ny",
       "value": "ny"
    }, {
       "name": "o",
       "value": "o"
    }, {
       "name": "ó",
       "value": "oo"
    }, {
       "name": "ö",
       "value": "ooo"
    }, {
       "name": "ő",
       "value": "oooo"
    }, {
       "name": "p",
       "value": "p"
    }, {
       "name": "r",
       "value": "r"
    }, {
       "name": "s",
       "value": "s"
    }, {
       "name": "sz",
       "value": "sz"
    }, {
       "name": "t",
       "value": "ty"
    }, {
       "name": "u",
       "value": "u"
    }, {
       "name": "ú",
       "value": "uu"
    }, {
       "name": "ü",
       "value": "uuu"
    }, {
       "name": "ű",
       "value": "uuuu"
    }, {
       "name": "v",
       "value": "v"
    }, {
       "name": "z",
       "value": "z"
    }, {
       "name": "zs",
       "value": "zs"
    }
   ];
   
   useEffect(() => {
      if (gameInstructionIsActive && gameStarted) {
         var timeout_ms = shuffleAll ? 5000 : 9000;
         setTimeout(function () {
            glob.playSound(task_config["items"][questionIndex]["question"]["sound"]);
         }, timeout_ms);
      }
   }, [gameInstructionIsActive]);
   
   useEffect(() => {
      setItems(task_config["items"][questionIndex]["options"]);
      setNextTaskItem(task_config["items"][questionIndex + 1]);
      setAnswerClass("");
      setDragBeginDelay(10);
      var timeout_ms = shuffleAll ? 5000 : 9000;
      setTimeout(function () {
         glob.playSound(task_config["items"][questionIndex]["question"]["sound"]);
      }, questionIndex == 0 ? timeout_ms : 0);
      setTimeout(function () {
         setGameStarted(true);
       }, 1000);
   }, [questionIndex]);
   


   useEffect(() => {
      var question_letters = task_config["items"][questionIndex]["question"]["name"].split("");
      var correct = true;
      $.each(items, (index, item) => {
         if (question_letters[index] != item["name"]) {
            correct = false;
         }
      });
      setIsAnswerCorrect(correct);
      if (correct && isDragStarted) {
         setAnswerClass("correct");
         glob.playGameSound("correct");
         setDragBeginDelay(10000);
      }
   }, [items]);
   
   var task_config = {
      "items": []
   };
   
  $.each(questions, (index, item) => {
    var word = item["name"];
    var letters = word.split("");
    var options_ = [];
    if (shuffleAll) {
      $.each(letters, (index, letter) => {
        options_.push({
          "name": letter,
          "index": index
        });
      });
      glob.shuffleArray(options_, true);
    } else {
      var temp_letters = JSON.parse(JSON.stringify(letters));
      temp_letters.shift();
      temp_letters.pop();

      var options_ = [];
      $.each(temp_letters, (index, letter) => {
        options_.push({
          "name": letter,
          "index": index+1
        });
      });
      glob.shuffleArray(options_, true);
      
      options_.unshift({
        "name": letters[0],
        "index": 0
      });

      options_.push({
        "name": letters[letters.length-1],
        "index": letters.length-1
      });
    }
    
    
    var new_item = {
      "question": {
        "name": item["name"],
        "image": item["image"],
        "sound": item["sound"]
      },
      "options": options_
    };
      task_config["items"].push(new_item);
   });

   const onDragStart = (e) => {
      console.log("onDragStart e: ", e);
      var found = letters.filter((col) => col.name == e.payload.name);
      if (found.length > 0) {
         glob.playSound(require('../../sounds/voice/_betuk/' + found[0].value + '.wav').default);
      }
      setIsDragStarted(true);
   }
   const goNextSlide = () => {
      setQuestionIndex(questionIndex + 1);
      setIsAnswerCorrect(false);
      setDragBeginDelay(10);
   }
   
   const resetGame = () => {
      glob.shuffleArray(questions, true);
      setIsAnswerCorrect(false);
      setIsDragStarted(false);
      setQuestionIndex(0);
      setDragBeginDelay(10);
   }

   if (!nextTaskItem && isAnswerCorrect) {
      return <GameEndScreen resetGame={ resetGame } />
   }
   return (
      <div className={`p-grid wrapper-ShuffleLetters ${answerClass}`}>
         <div className="p-col-12 p-md-3"></div>  
         <div className="p-col-12 p-md-6 p-text-center">
            {/* <h5>{task_config["items"][questionIndex]["question"]["name"]}</h5> */}
            <img className="question-image" src={task_config["items"][questionIndex]["question"]["image"]} />
            {task_config["items"][questionIndex]["question"].hasOwnProperty("sound") && <Button label={<img src={ glob.icons["speaker"] } width="40" />} className="btn-volume-up p-button-rounded p-button-text p-button-plain p-icon-fs-2rem" onClick={() => glob.playSound(task_config["items"][questionIndex]["question"]["sound"]) } />}
         </div>
         <div className="p-col-12 p-md-3"></div>
            <div className={`p-col-12 p-text-center ${items.length>7 && "minimize-letters"}`}>
               <Container groupName="1" dragClass="dnd-ghost-drop" dragBeginDelay={dragBeginDelay} animationDuration={100} orientation="horizontal" onDragStart={e => onDragStart(e)} style={groupStyle} getChildPayload={i => items[i]} onDrop={e => setItems(applyDrag(items, e))}>
                  {items.map(function (item, index) {
                     if (!shuffleAll && (index==0 || index===(items.length-1))) {
                        return (
                           <div className={`draggable-item letter ${index==0?"letter-first":""} ${index==(items.length-1)?"letter-last":""}`}>
                              <p>{item.name}</p>
                           </div>
                        )  
                     }
                     return (
                        <Draggable key={index}>
                           <div className={`draggable-item letter ${index==0?"letter-first":""} ${index==(items.length-1)?"letter-last":""}`}>
                              <p>{item.name}</p>
                           </div>
                        </Draggable>
                     )
                  })}
               </Container>
            </div>
            <div className="p-col-3">
               {(isAnswerCorrect && nextTaskItem) && (
                  <>
                     <div className="wrapper-btn-next-task">
                        <Button label={<img src={ glob.icons["nextExerciseBig"] } width="50" />} className="btn-next-task p-button-rounded p-button-text" onClick={() => goNextSlide()} />
                        <div className="bg-of-btn-next-task"></div>
                     </div>
                  </>
               )}
            </div>
         </div>
   );
};