import React from 'react';
import $ from 'jquery';
import QuestionItem from '../../QuestionItem';
import glob from '../../glob';
import moment from 'moment';
import "moment/locale/hu";

export default function HovaIndulAVonat(props, { ...rest }) {
   var task_config = {
      "name":"HovaIndulAVonat",
      "appTitles":[],
      "items": []
   };

   
   var t_hours = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
   var t_minutes = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];

   var destinationList = [
      {
         "destination": "Bécs",
         "destinationWithAffix": "Bécsbe",
         "destinationAudio": "becs"
      },
      {
         "destination": "Berlin",
         "destinationWithAffix": "Berlinbe",
         "destinationAudio": "berlin"
      },
      {
         "destination": "Prága",
         "destinationWithAffix": "Prágába",
         "destinationAudio": "praga"
      },
      {
         "destination": "Kecskemét",
         "destinationWithAffix": "Kecskemétre",
         "destinationAudio": "kecskemet"
      },
      {
         "destination": "Sopron",
         "destinationWithAffix": "Sopronba",
         "destinationAudio": "sopron"
      },
      {
         "destination": "Baja",
         "destinationWithAffix": "Bajára",
         "destinationAudio": "baja"
      },
      {
         "destination": "Cegléd",
         "destinationWithAffix": "Ceglédre",
         "destinationAudio": "cegled"
      },
      {
         "destination": "Hatvan",
         "destinationWithAffix": "Hatvanba",
         "destinationAudio": "hatvan"
      },
      {
         "destination": "Eger",
         "destinationWithAffix": "Egerbe",
         "destinationAudio": "eger"
      },
      {
         "destination": "Vác",
         "destinationWithAffix": "Vácra",
         "destinationAudio": "vac"
      },
      {
         "destination": "Gödöllő",
         "destinationWithAffix": "Gödöllőre",
         "destinationAudio": "godollo"
      },
      {
         "destination": "Siófok",
         "destinationWithAffix": "Siófokra",
         "destinationAudio": "siofok"
      },
      {
         "destination": "Gyula",
         "destinationWithAffix": "Gyulára",
         "destinationAudio": "gyula"
      },
      {
         "destination": "Szombathely",
         "destinationWithAffix": "Szombathelyre",
         "destinationAudio": "szombathely"
      },
      {
         "destination": "Tapolca",
         "destinationWithAffix": "Tapolcára",
         "destinationAudio": "tapolca"
      },
      {
         "destination": "Érd",
         "destinationWithAffix": "Érdre",
         "destinationAudio": "erd"
      },
     {
         "destination": "Gárdony",
         "destinationWithAffix": "Gárdonyba",
         "destinationAudio": "gardony"
      },
      {
         "destination": "Szolnok",
         "destinationWithAffix": "Szolnokra",
         "destinationAudio": "szolnok"
      },
      {
         "destination": "Székesfehérvár",
         "destinationWithAffix": "Székesfehérvárra",
         "destinationAudio": "szekesfehervar"
      },
      {
         "destination": "Fonyód",
         "destinationWithAffix": "Fonyódra",
         "destinationAudio": "fonyod"
      },
     {
         "destination": "Nagykanizsa",
         "destinationWithAffix": "Nagykanizsára",
         "destinationAudio": "nagykanizsa"
      },
     {
         "destination": "Debrecen",
         "destinationWithAffix": "Debrecenbe",
         "destinationAudio": "debrecen"
      },
     {
         "destination": "Szeged",
         "destinationWithAffix": "Szegedre",
         "destinationAudio": "szeged"
      },
     {
         "destination": "Keszthely",
         "destinationWithAffix": "Keszthelyre",
         "destinationAudio": "keszthely"
      },
     {
         "destination": "Győr",
         "destinationWithAffix": "Győrbe",
         "destinationAudio": "gyor"
      },
      {
         "destination": "Veszprém",
         "destinationWithAffix": "Veszprémbe",
         "destinationAudio": "veszprem"
      },
      {
         "destination": "Pécs",
         "destinationWithAffix": "Pécsre",
         "destinationAudio": "pecs"
      },
      {
         "destination": "Miskolc",
         "destinationWithAffix": "Miskolcra",
         "destinationAudio": "miskolc"
      }
   ];

   const getQuestionDatas = (destinationItem, time, destinationPlatform) => {
      var possible_questions = [
         {
            "destinationItemKey": "destinationWithAffix",
            "appTitleStr": (
               <>
                  <span>Hova indul a vonat <strong>{time.format("HH") + " óra " + time.format("mm") + " perckor"}</strong>?</span>
               </>
            ),
            "destinationTime": time.format("HH:mm"),
            "destinationPlatform": destinationPlatform
         },
         {
            "destinationItemKey": "destinationPlatform",
            "appTitleStr": (
               <>
                  <span>Melyik vágányról indul a vonat <strong>{destinationItem["destinationWithAffix"]}</strong>?</span>
               </>
            ),
            "destinationTime": time.format("HH:mm"),
            "destinationPlatform": destinationPlatform
         },
         {
            "destinationItemKey": "destinationTime",
            "appTitleStr": (
               <>
                  <span>Mikor indul a vonat <strong>{destinationItem["destinationWithAffix"]}</strong>?</span>
               </>
            ),
            "destinationTime": time.format("HH:mm"),
            "destinationPlatform": destinationPlatform
         },
      ];
      return glob.getRandomElementFromArray(possible_questions);
   }
   const getQuestionSound = (item) => {
      switch(item.destinationItemKey) {
         case "destinationWithAffix":
            // Hova indul a vonat x óra x perckor ?
            
            return [
               require('../../../sounds/voice/vonat/kerdesek/hova_indul_a_vonat.mp3').default,
               require('../../../sounds/voice/vonat/idok/orak/' + moment("2020-01-01 " + item["destinationItem"]["destinationTime"]).format("H") + '.mp3').default,
               require('../../../sounds/voice/vonat/idok/perckor/' + moment("2020-01-01 " + item["destinationItem"]["destinationTime"]).format("m") + '.mp3').default
            ]
            break;
         case "destinationPlatform":
            // Melyik vágányról indul a vonat Bécsbe ?
            return [
               require('../../../sounds/voice/vonat/kerdesek/melyik_vaganyrol_indul_a_vonat.mp3').default,
               require('../../../sounds/voice/vonat/varosok/' + item["destinationItem"]["destinationAudio"] + '.mp3').default
            ]
            break;
         case "destinationTime":
            // Mikor indul a vonat Bécsbe ?
            return [
               require('../../../sounds/voice/vonat/kerdesek/mikor_indul_a_vonat.mp3').default,
               require('../../../sounds/voice/vonat/varosok/' + item["destinationItem"]["destinationAudio"] + '.mp3').default
            ];
            break;
         default:
            // code block
      }
   }
   const getOptions = (destinations) => {
      var solution_index = glob.getRandomIntFromInterval(0, destinations.length - 1);
      var destinationItemKey = destinations[solution_index]["destinationItemKey"];
      var options = [destinations[solution_index]["destinationItem"][destinationItemKey]];

      //glob.removeElementFromArray(options, solution_index);
      while (options.length < 4) {
         var new_option_index = glob.getRandomIntFromInterval(0, destinations.length - 1);
         var new_option = destinations[new_option_index]["destinationItem"][destinationItemKey];
         if (solution_index != new_option_index) {
            if (destinationItemKey === "destinationPlatform" && options[0] === new_option) {
               new_option = parseInt(new_option) + 1;
               console.log("opts: ", options[0], " new_option: ", new_option);
            }
            options.push(new_option);
         }
      }
      
      var return_ = options.map(function (item, index) {
         if (destinationItemKey === "destinationPlatform") {
            item = item + ". vágányról";
         } else if (destinationItemKey === "destinationTime") {
            item = moment("2020-01-01 " + item).format("HH") + " óra " + moment("2020-01-01 " + item).format("mm") + " perckor";
         }
         
         return {
            "name": item,
            "isCorrect": index == 0
         }
      });
      
      return [return_, solution_index]
   }
   var questions = [];
   $.each(Array.from({ length: 30 }, (_, i) => i), (index, item) => {
      var destinations = [];
      var base_hour = 0;
      var lastTime = "";
      var destinationItems = [];

      while (destinations.length < 7) {
         var basetime = glob.getRandomElementFromArray(t_hours)+":"+glob.getRandomElementFromArray(t_minutes);
         if (lastTime != "") {
            basetime = time_.format("HH:mm");
         }
         var time_ = moment("2020-01-01 " + basetime).add(glob.getRandomElementFromArray([10, 15]), 'minutes');
         
         lastTime = time_;
         
         var new_destinationItem = glob.getRandomElementFromArray(destinationList);
         var found_unique_destination_item = false;
         while (!found_unique_destination_item) {
            new_destinationItem = glob.getRandomElementFromArray(destinationList);

            if (!destinationItems.includes(new_destinationItem)) {
               destinationItems.push(new_destinationItem);
               found_unique_destination_item = true;
            }
         }
         
         var question_datas = getQuestionDatas(new_destinationItem, time_, glob.getRandomIntFromInterval(1, 10));
         new_destinationItem["destinationPlatform"] = question_datas["destinationPlatform"];
         new_destinationItem["destinationTime"] = question_datas["destinationTime"];

         destinations.push({
            "destinationItem": new_destinationItem,
            "destinationItemKey": question_datas["destinationItemKey"],
            "appTitleStr": question_datas["appTitleStr"],
         });
      }
      
      
      var temp_ = getOptions(destinations);
      var solution_index = temp_[1];
      
      var opts = temp_[0];
      glob.shuffleArray(opts);
      //console.log("variable test: ", destinations[solution_index]);
      
      
         
      
      var new_item = {
         "question": {
            //"question_type": getQuestionSound(destinations[solution_index]["destinationItemKey"],
            "sounds": getQuestionSound(destinations[solution_index]),
            "name": (
               <>
                  <table className="destinationTable">
                     <tr>
                        <th>Idő</th>
                        <th>Útirány - Hová</th>
                        <th>Vágány</th>
                     </tr>
                     {destinations.map(function (item, index) {
                        return (
                           <tr>
                              <td>{item["destinationItem"]["destinationTime"]}</td>
                              <td>{item["destinationItem"]["destination"]}</td>
                              <td>{item["destinationItem"]["destinationPlatform"]}</td>
                           </tr>
                        )
                     })}
                  </table>
               </>
            ),
         },
         "options": opts
      };
      questions.push(new_item);

      task_config["appTitles"].push(destinations[solution_index]["appTitleStr"]);
   });
   
   task_config["items"] = questions; //.slice(1, 3)
   //task_config["appTitles"] = task_config["appTitles"].slice(1, 3);

   
   return (
      <div className="task-wrapper task-wrapper-HovaIndulAVonat">
         <QuestionItem type="kerdes_bal_oldalon_valaszok_jobb_oldalon_egymas_alatt" taskConfig={task_config} />
      </div>
   );
};