import React from 'react';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useParams } from 'react-router-dom';
import glob from '../glob';

export default function GameDetail(props, { ...rest }) {
  const { paramGameCategory } = useParams();
  const { paramTaskId } = useParams();

  var gameCategory = glob.menu_items[paramGameCategory];
  
  return (
    <>
        {/* GameDetail Játék sorszáma: <strong>{paramGameCategory}</strong> | Játék alkategóriája: <strong>{paramTaskId}</strong> */}
        { gameCategory["tasks"][paramTaskId]["component"] }
    </>
  );
};