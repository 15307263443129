import React, { createRef, useEffect, useRef, useState } from 'react';
import $ from 'jquery';
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { Button } from 'primereact/button';

export default function KeyboardInput(props, { ...rest }) {
   const [keyInput, setKeyInput] = useState("");
   const keyboard = useRef();
   
   var keyboardOptions = {
      layout: {
         default: [
            "á é í ó ö ő ú ü ű",
            "q w e r t z u i o p {backspace}",
            "a s d f g h j k l",
            "y x c v b n m",
            "{space}"
         ],
         display: {
            "{backspace}": "törlés ⌫",/* 
            "{arrowleft}": "arrowleft ⌫",
            "{arrowright}": "arrowright ⌫" */
         }
      }
   };
   if (props.type == "numbers") {
      keyboardOptions = {
         layout: {
            default: [
               "1 2 3",
               "4 5 6",
               "7 8 9",
               "0"
            ],
         }
      };
   }


   useEffect(() => {
      $("#keyboard-input").focus();
   }, []);

   useEffect(() => {
      if (keyInput != props.value) {
         var new_val = props.value;
         setKeyInput(new_val);
         keyboard.current.setInput(new_val);
      }
   }, [props.value]);

   useEffect(() => {
      if (keyInput != props.value) {
         //console.log("props.showQuestion: ", props.showQuestion);
         
      }
   }, [keyInput]);

   const onChange = (input) => {
      if (props.isAnswerCorrect) { return false }
      setKeyInput(input);
      props.onComponentChange(input);
      console.log("Input changed", input);
   };
   const onKeyPress = (button) => {
      if (props.isAnswerCorrect) { return false }
      if (button === "{arrowleft}" || button === "{arrowright}") {
         console.log("arrow pressed");
      }
   };
   
   const simulateBackspace = () => {
      if (props.className.includes("incorrect")) {
         console.log("includes incorrect");
         return false
      }
      var keyInput_ = keyInput;
      var newKeyInput_ = keyInput_.substring(0, keyInput_.length - 1);
      setKeyInput(newKeyInput_);
      keyboard.current.setInput(newKeyInput_);
   }
   const onChangeInput = (e) => {
      var value_ = e.target.value;
      setKeyInput(value_);
      keyboard.current.setInput(value_);
      props.onComponentChange(value_);
   }
   const onBlurInput = () => {
      $("#keyboard-input").focus();
   }
   var class_ = "";
   if (props.hasOwnProperty('hideInput') && props.hideInput == true) {
      class_ = "d-none";
   }
   var inputProps = {};
   if (props.hasOwnProperty("isInputDisabled") && props.isInputDisabled) {
      inputProps["disabled"] = true;
   }
   
   return (
      <div className={"wrapper-keyboard "+props.type+" "+props.className}>
         <div className="p-grid p-align-center grid-input">
            <div className="p-col-12 p-md-2 p-text-right col-components">
               {props.leftComponent && props.leftComponent}
            </div>
            <div className="p-col-12 p-md-8 col-components sy-position-relative">
               <input
                  id="keyboard-input"
                  className={"keyboard-input " + class_}
                  value={keyInput}
                  onChange={(e) => onChangeInput(e)}
                  autoFocus={true}
                  onBlur={onBlurInput}
                  /* {...inputProps} */
                  disabled={true}
               />
               {props.middleComponent && props.middleComponent}
               {props.type=="numbers" && <Button label="X" className="btn-simulateBackspace p-button-rounded p-button-text p-button-plain p-icon-fs-2rem" onClick={() => simulateBackspace() } />}
         </div>
            <div className="p-col-12 p-md-2 col-components">
            {props.rightComponent && props.rightComponent}
         </div>
      </div>
      <div className="p-grid grid-keyboard">
         <div className="p-col-12">
            <Keyboard
               keyboardRef={r => (keyboard.current = r)}
               layoutName={"default"}
               onChange={onChange}
               onKeyPress={onKeyPress}
               {...keyboardOptions}
            />
         </div>
      </div>
   </div>
   );
};