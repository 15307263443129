import React from 'react';
import $ from 'jquery';
import glob from '../../glob';
import QuestionItem from '../../QuestionItem';

export default function MelyikPenztHallod(props, { ...rest }) {
   var task_config = {
      "items": []
   };
   var penz_ = [
      "5",
      "10",
      "20",
      "50",
      "100",
      "200",
      "500",
      "1000",
      "2000",
      "5000",
      "10000",
      "20000",
   ];/* 
   penz_ = [
      "5",
      "10",
      "20",
      "50",
      "100",
      "200"
   ]; */
   
   const getSoundSrc = (name) => {
      return require('../../../sounds/voice/_penz/' + name + '.mp3').default;
   };

   const getImageSrc = (name) => {
      return require('../../../images/tasks/_penz/' + name + '.jpeg').default;
   };

   const getOptions = (question) => {
      var target_category = JSON.parse(JSON.stringify(penz_));

      console.log("target_category: ", target_category);
      
      var options = [question];
      while (options.length < 4) {
         var new_option = glob.getRandomElementFromArray(target_category);
         if (!options.includes(new_option)) {
            options.push(new_option);
         }
      }
      
      var return_ = options.map(function (item, index) {
         return {
            "image": getImageSrc(item),
            "isCorrect": index == 0
         }
      });
      glob.shuffleArray(return_);
      return return_
   }

   var kerdesek = JSON.parse(JSON.stringify(penz_)).concat(JSON.parse(JSON.stringify(penz_))).concat(JSON.parse(JSON.stringify(penz_)));
   console.log("kerdesek length: ", kerdesek);
   
   glob.shuffleArray(kerdesek);
   

   var kerdesek_ = []
   $.each(kerdesek, (index, item) => {
      var new_item = {
         "question": {
            "sound": getSoundSrc(item),
         },
         "options": getOptions(item)
      };
      kerdesek_.push(new_item);
   });

   task_config["items"] = kerdesek_;//; .slice(1, 3)

   return (
      <div className="task-wrapper task-wrapper-MelyikPenztHallod">
         <QuestionItem taskName="MelyikPenztHallod" type="kerdes_bal_oldalon_valaszok_jobb_oldalon" taskConfig={task_config} />
      </div>
   );
};