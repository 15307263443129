import React from 'react';
import $ from 'jquery';
import FrameImageGallery from '../FrameImageGallery';
import glob from '../../glob';

export default function MitLatszAKepen(props, { ...rest }) {
   //"elvont" 55, 46 hiáhyzik
   
   var questions = [];
   $.each(Array.from({ length: 14 }, (_, i) => (i + 1)), (index, item) => {
      var new_item = (
         <img src={require('../../../images/tasks/mit_latsz_a_kepen/'+item+'.jpeg').default} alt="gallery image" />
      );
      questions.push(new_item);
   });

   //glob.shuffleArray(questions);
   
   var task_config = {
      "items": questions
   };

   return (
      <div className="task-wrapper task-wrapper-MitLatszAKepen">
         <FrameImageGallery items={task_config["items"]} customArrows={true} />
      </div>
   );
};