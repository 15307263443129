import React, { useEffect, useState } from 'react';
import $ from 'jquery';

import ShuffleLetters from '../../ShuffleLetters';
import glob from '../../glob';

export default function RakjRendet(props, { ...rest }) {
   const [taskConfig, setTaskConfig] = useState(false);

   useEffect(() => {
      var questions_ = [];
      $.each(glob.words, (index, item) => {
         var item_options = [];
         $.each(item["options"], (index, item2) => {
            if (item2.name.length>3) {
               item_options.push(item2);
            }
         });

         if (glob.word_categories.includes(item["question"]["name"])) {
            $.each(item_options, (index, item2) => {
               item2["category"] = item["question"]["name"];
            });
            questions_ = questions_.concat(item_options);
         }
      });
      glob.shuffleArray(questions_, true);
      
      
      var task_config = {};
         
      task_config["items"] = questions_; //.slice(1, 3)
      setTaskConfig(task_config);
   }, []);

   return (
      <div className="task-wrapper task-wrapper-RakjRendet">
         {taskConfig["items"] && <ShuffleLetters questions={taskConfig["items"]} shuffleAll={false} />}
      </div>
   );
};