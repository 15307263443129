import React, { useState, useEffect } from 'react';
import { Menubar } from 'primereact/menubar';

import { InputText } from 'primereact/inputtext';

import { Button } from 'primereact/button';
import { Link as RouterLink, Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { globalEval } from 'jquery';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import $ from 'jquery';

import glob from '../glob';
import { useAppContext } from '../appContext';


export default function TopBar(props, { ...rest }) {
   const history = useHistory();
   const { paramGameCategory } = useParams();
   const { paramTaskId } = useParams();
   
   var { paramSubtaskId } = useParams();
   paramSubtaskId = parseInt(paramSubtaskId);
   const location = useLocation();

   const { hideSelector } = useAppContext();
   const { appTitle } = useAppContext();
   const { gameInstructionIsActive } = useAppContext();
   const { setGameInstructionIsActive } = useAppContext();

   //paramSubtaskId = isNaN(paramSubtaskId) ? 0 : paramSubtaskId;

   var gameCategory = glob.menu_items[paramGameCategory];
   var game = glob.menu_items[paramGameCategory].hasOwnProperty("tasks") ? glob.menu_items[paramGameCategory]["tasks"][paramTaskId] : null;


   
   const [fullscreenEnabled, setFullscreenEnabled] = useState(false);
   
   useEffect(() => {
      if (paramTaskId && isNaN(paramSubtaskId)) {
         var url = new URL(window.location.href);
         var new_url = location.pathname + "/0";
         console.log("TopBar history push!!!! " + new_url)
         history.push(new_url);
      }
   }, []);
   
   const gotoSubtask = (type_) => {
      console.log("paramSubtaskId: ", paramSubtaskId);
      var val_to_add = type_ == "next" ? 1 : -1;
      var url = new URL(window.location.href);
      
      var new_val = parseInt(paramSubtaskId + val_to_add);
      console.log("new_val: ", new_val);
      var new_url = glob.removeLastDirectoryPartOf(location.pathname) + "/" + new_val;
      
      history.push(new_url);
   }
   useEffect(() => {
      if (paramGameCategory && paramTaskId) {
         playGameInstruction();
      }
      return () => {
         glob.muteSound();
      };
   }, [paramGameCategory, paramTaskId]);

   const goBackward = () => {
      if (location.pathname.includes("submenu")) {
         var new_url = "";
      } else {
         var new_url = `/submenu/${paramGameCategory}/${paramTaskId}`;
      }
      history.push(new_url);
   }
   var elem = document.documentElement;
   const openFullscreen = () => {
      if (elem.requestFullscreen) {
         elem.requestFullscreen();
       } else if (elem.webkitRequestFullscreen) { /* Safari */
         elem.webkitRequestFullscreen();
       } else if (elem.msRequestFullscreen) { /* IE11 */
         elem.msRequestFullscreen();
       }
   }
   const closeFullscreen = () => {
      if (document.exitFullscreen) {
         document.exitFullscreen();
       } else if (document.webkitExitFullscreen) { /* Safari */
         document.webkitExitFullscreen();
       } else if (document.msExitFullscreen) { /* IE11 */
         document.msExitFullscreen();
       }
   }

   const toggleFullscreen = () => {
      if (!fullscreenEnabled) {
         //openFullscreen();
         $("#button").trigger( "click" );
      } else {
         closeFullscreen();
      }
      setFullscreenEnabled(!fullscreenEnabled);
   }
   const playGameInstruction = () => {
      setGameInstructionIsActive(true);
      setTimeout(function () {
         setGameInstructionIsActive(false);
      }, 3000);
      
      var paramGameCategory_ = parseInt(paramGameCategory);
      var paramTaskId_ = parseInt(paramTaskId);
      console.log("paramGameCategory_: ", paramGameCategory_);
      console.log("paramTaskId_: ", paramTaskId_);
      if (appTitle == "Válaszolj a kérdésekre!") {
         glob.playInstructionSound(appTitle);
      } else if (appTitle == "Írd le a kép nevét!") {
         glob.playInstructionSound(appTitle);
      } else if (appTitle == "Írd be a PIN-kódot!") {
         glob.playInstructionSound(appTitle);
      } else if (glob.menu_items[paramGameCategory]["tasks"][paramTaskId] && glob.menu_items[paramGameCategory]["tasks"][paramTaskId]["sounds"] && glob.menu_items[paramGameCategory]["tasks"][paramTaskId]["sounds"].length > 0) {
         if (!(paramGameCategory_ == 0 && (paramTaskId_ == 0 || paramTaskId_ == 1)) && !(paramGameCategory_ === 1 && paramTaskId_ === 7)) {
            setTimeout(function () {
               glob.playSounds(glob.menu_items[paramGameCategory]["tasks"][paramTaskId]["sounds"]);   
            }, 10);
            
         }
      }
   }

   return (
         <Menubar
            className="siteMenubar"
         start={
            <>
               <Button label={<img src={glob.icons["home"]} width="30" />} className="btn-home p-button-text p-button-rounded" onClick={() => goBackward()} />
               <Button label={fullscreenEnabled ? <FullscreenExitIcon /> : <FullscreenIcon />} className="btn-fullscreen p-button-text p-button-rounded" onClick={() => toggleFullscreen()} />
            </>
         }
         end={
            <>
               <Button label={<img src={ glob.icons["home"] } width="30" />} className="btn-home-mobile p-button-text p-button-rounded" onClick={() => goBackward()} />
               
               {(paramGameCategory && paramTaskId) && (
                  <>
                     <p className={`game-instruction ${gameInstructionIsActive ? ("active") : ("")} `} onClick={() => playGameInstruction()}>{(["", null].includes(appTitle)) ? (glob.menu_items[paramGameCategory]["tasks"][paramTaskId]["name"]) : appTitle}</p>
                  </>
               )}
               
               <div className="game-item-selector">
                  {(game && game.hasOwnProperty('haveSelector') && game.haveSelector && !hideSelector) && (
                     <>
                        <Button label="<" className="p-button-outlined" onClick={()=> gotoSubtask("prev")} disabled={paramSubtaskId<1} />
                        <Button label={(paramSubtaskId+1)+"."} className="p-button-outlined" disabled />
                        <Button label=">" className="p-button-outlined" onClick={()=> gotoSubtask("next")} disabled={(paramGameCategory == 0 && paramTaskId == 2 && paramSubtaskId == 26) || (paramGameCategory == 4 && paramTaskId == 7 && paramSubtaskId == 26)} />
                     </>
                  )}
               </div>
               
               
            </>
         }
      />
   );
};