import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import $ from 'jquery';
import Slider from "react-slick";

import glob from '../../glob';
import { useAppContext } from '../../appContext';


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", width: "50px" }}
      onClick={onClick}
    >
      <img src={ glob.icons["nextExerciseBig"] } />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", width: "50px" }}
      onClick={onClick}
    >
      <img src={ glob.icons["previousExerciseBig"] } />
    </div>
  );
}

export default function FrameImageGallery(props, { ...rest }) {
  const [displayDialog, setDisplayDialog] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const { paramGameCategory } = useParams();
  const { paramTaskId } = useParams();

  const { setAppTitle } = useAppContext();
  
  var gameObject = glob.menu_items[paramGameCategory]["tasks"][paramTaskId];
  var props_sliderSettings = props.hasOwnProperty("sliderSettings") ? props["sliderSettings"] : [];
  var customArrows = props.hasOwnProperty("customArrows") ? props["customArrows"] : false;

  var sliderSettings = {
      dots: false,
      infinite: props.hasOwnProperty("sliderInfinite") ? props.sliderInfinite : true,
      speed: 500,
      centerMode: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      rows: 1,
      afterChange: current => setCurrentSlide(current),
      responsive: [
        {
          breakpoint: 680,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
  };
  if (customArrows) {
    sliderSettings["prevArrow"] = <SamplePrevArrow className="customArrow" />;
    sliderSettings["nextArrow"] = <SampleNextArrow className="customArrow" />;
  }

  $.each(Object.keys(props_sliderSettings), (index, key) => {
    if (sliderSettings.hasOwnProperty(key)) {
      sliderSettings[key] = props_sliderSettings[key];
    }
  });
  useEffect(() => {
    if (props.hasOwnProperty('gameTpye') && props.gameTpye === "MonddErzelemmel") {
      setAppTitle(props.taskConfig["config"][currentSlide]["name"]);
      glob.playSound(props.taskConfig["config"][currentSlide]["sound"]);
    }
    
    return () => {
      setAppTitle("");
   };
  }, [currentSlide]);
   return (
      <>
       <Slider {...sliderSettings} className={`SliderImageGallery ${customArrows && "customArrow"} `}>
          {props.hasOwnProperty('items') && props.items.map(function (item, index) {
            return (
              <div className="gallery-item">
                {item}
              </div>  
            )
          })}
        </Slider>  
      </>
   );
};