import React from 'react';
import $ from 'jquery';
import QuestionItem from '../../QuestionItem';
import glob from '../../glob';

export default function FizesdKiPontosan(props, { ...rest }) {
   var penz_ = [
      "5",
      "10",
      "20",
      "50",
      "100",
      "200",
      "500",
      "1000",
      "2000",
      "5000",
      "10000",
      "20000"
   ]
   var questions = [
      '10', '100', '1000', '10000', '1005', '1010', '1015', '10200', '105', '10500', '11000', '1110', '11200', '115', '11500', '120', '1200', '12000', '12200', '125', '12500', '13000', '13200', '1350', '13500', '14000', '14200', '14500', '1490', '15', '150', '1500', '15000', '15200', '1550', '15500', '16000', '16500', '17000', '17500', '18000', '18500', '1900', '19000', '1950', '19500', '20', '200', '2000', '20000', '20020', '2010', '2025', '20500', '2055', '210', '2100', '21000', '2120', '2150', '21500', '2200', '22000', '2205', '2210', '22500', '23000', '23500', '2400', '24000', '2450', '24500', '2490', '25', '250', '2500', '25000', '25500', '26000', '26500', '2700', '27000', '2750', '27500', '2800', '28000', '28500', '29000', '29500', '30', '300', '3000', '30000', '305', '3200', '3250', '3300', '3420', '3450', '35', '350', '3500', '35000', '3600', '3900', '3950', '40', '400', '4000', '40000', '4070', '4100', '415', '4200', '4350', '45', '450', '4500', '45000', '455', '4700', '4800', '4900', '5', '50', '500', '50000', '5020', '5025', '505', '510', '5100', '5115', '5225', '525', '5250', '5300', '5400', '5450', '55', '550', '5500', '55000', '5555', '5650', '5800', '5900', '5920', '60', '600', '6000', '60000', '6005', '6020', '6100', '6200', '625', '65', '650', '6500', '70', '700', '7000', '7060', '7200', '75', '750', '7500', '80', '800', '8000', '805', '8200', '85', '850', '8500', '90', '900', '9000', '9200', '95', '950', '9500', '990'
   ];
   
   glob.shuffleArray(questions);
   
   const getImageSrc = (name) => {
      return require('../../../images/tasks/_penz/' + name + '.jpeg').default;
   };

   const getValues = (loop_value) => {
      var question = [];
      var temp_array = JSON.parse(JSON.stringify(penz_));
      temp_array.reverse();

      $.each(temp_array, (index, item) => {
         item = parseInt(item);
         console.log("loop_value >= item: ", loop_value, item, loop_value >= item);
         if (loop_value >= item) {
            question.push(item);
            loop_value -= item;
            return false;
         }
      });
      return [question, loop_value]
   }

   var questions_ = [];
   $.each(questions, (index, item) => {
      var target_value = item;
      
      var loop_value = parseInt(target_value);
      var question = [];
      var condition_ = true;
      console.log("TARGET: ", loop_value);
      while (loop_value >= 0 && condition_) {
         var temp = getValues(loop_value);
         question = question.concat(temp[0]);
         loop_value = temp[1];
         if (loop_value<=0) {
            condition_ = false;
         }
      }
      var temp_penz = JSON.parse(JSON.stringify(penz_));
      var options_ = question.map(function (item, index) {
         temp_penz = glob.removeElementFromArray(temp_penz, item.toString())
         return {
            "name": (<img className="money-option" src={ getImageSrc(item) } />),
            "isCorrect": true
         }
      });
      console.log("temp_penz: ", temp_penz);
      while (options_.length != 6) {
         var new_element = glob.getRandomElementFromArray(temp_penz);
         if (!question.includes(new_element)) {
            console.log("new_element not in question: ", new_element, question);
            options_.push({
               "name": (<img className="money-option" src={getImageSrc(new_element)} />),
               "isCorrect": false
            });
         }
      }
      glob.shuffleArray(options_);

      var new_item = {
         "question": {
            "name": glob.numberWithSpaces(target_value) + " Ft",
            "sound": require('../../../sounds/voice/fizesd_ki_pontosan/' + target_value + '.mp3').default
         },
         "options": options_
      };
      questions_.push(new_item);
   });

   var task_config = {
      "paymentButton":true
   };
   
   task_config["items"] = questions_; //.slice(1, 3)
   
   return (
      <div className="task-wrapper task-wrapper-FizesdKiPontosan">
         <QuestionItem taskName={"FizesdKiPontosan"} type="kerdes_felul_valaszok_alul" taskConfig={task_config} />
      </div>
   );
};