import React from 'react';
import $ from 'jquery';

import glob from '../../glob';
import QuestionItem from '../../QuestionItem';
import { useParams } from 'react-router';

export default function MelyikAKakukktojas(props, { ...rest }) {
   const { paramDifficultyLevel } = useParams();

   var questions_dict = {
     1: 
       [
         {
           options: [
             {
               image: require("../../../images/aphasiaphotoset/wine_red.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/beer.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00176031.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/drink7.jpeg")
                 .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image: require("../../../images/aphasiaphotoset/drink_warm.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/tea.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/coffee_beans.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/croissant.jpeg")
                 .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image:
                 require("../../../images/aphasiaphotoset/orange_juice.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/apple_juice3.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/white_grape_juice.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/drink_warm.jpeg")
                 .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image: require("../../../images/aphasiaphotoset/bread1.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/croissant.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/biscuit.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/salami.jpeg")
                 .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image: require("../../../images/aphasiaphotoset/salami.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00132641.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/bologna.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00128571.jpeg")
                   .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00154601.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/egg_fried.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/eggs_scrambled_2.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00103601.jpeg")
                   .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image:
                 require("../../../images/aphasiaphotoset/chocolate_bar_bite_size.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/ice_cream_cone_strawberry.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/chicken_whole.jpeg")
                   .default,
               isCorrect: true,
             },
             {
               image: require("../../../images/aphasiaphotoset/cake1.jpeg")
                 .default,
               isCorrect: false,
             },
           ],
         },
         {
           options: [
             {
               image: require("../../../images/aphasiaphotoset/meatballs2.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/chicken_whole.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/salad1.jpeg")
                 .default,
               isCorrect: true,
             },
             {
               image: require("../../../images/aphasiaphotoset/schnitzel.jpeg")
                 .default,
               isCorrect: false,
             },
           ],
         },
         {
           options: [
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00057951.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/cinnamon_bun.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/crackers1.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/lemonade.jpeg")
                 .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image: require("../../../images/aphasiaphotoset/apple.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/pear_green.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/onion_yellow.jpeg")
                   .default,
               isCorrect: true,
             },
             {
               image: require("../../../images/aphasiaphotoset/plum.jpeg")
                 .default,
               isCorrect: false,
             },
           ],
         },
         {
           options: [
             {
               image:
                 require("../../../images/aphasiaphotoset/strawberry2.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/raspberry.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/radish.jpeg")
                 .default,
               isCorrect: true,
             },
             {
               image: require("../../../images/aphasiaphotoset/cherries.jpeg")
                 .default,
               isCorrect: false,
             },
           ],
         },
         {
           options: [
             {
               image: require("../../../images/aphasiaphotoset/cherry.jpeg")
                 .default,
               isCorrect: true,
             },
             {
               image: require("../../../images/aphasiaphotoset/banana.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00168321.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/pineapple.jpeg")
                 .default,
               isCorrect: false,
             },
           ],
         },
         {
           options: [
             {
               image: require("../../../images/aphasiaphotoset/carrot.jpeg")
                 .default,
               isCorrect: true,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/onion_yellow.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/garlic.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/onion_red.jpeg")
                 .default,
               isCorrect: false,
             },
           ],
         },
         {
           options: [
             {
               image: require("../../../images/aphasiaphotoset/watermelon.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/melon_canteloupe.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/grapes_green.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/cabbage_green.jpeg")
                   .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image: require("../../../images/aphasiaphotoset/peas.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/beans.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00119041.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/potato.jpeg")
                 .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image: require("../../../images/aphasiaphotoset/potato.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/chiles.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00118331.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/strawberry2.jpeg")
                   .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image: require("../../../images/aphasiaphotoset/bra.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/skirt.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/tie.jpeg")
                 .default,
               isCorrect: true,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00121581.jpeg")
                   .default,
               isCorrect: false,
             },
           ],
         },
         {
           options: [
             {
               image:
                 require("../../../images/aphasiaphotoset/shoes_leather.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/workboots.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00001992.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00001993.jpeg")
                   .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image: require("../../../images/aphasiaphotoset/hat_snow.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/hat_baseball.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00055350.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/scarf.jpeg")
                 .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00055520.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/sports_bag.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/purse.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/umbrella.jpeg")
                 .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00055550.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/necklace_beads.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/ring_diamond.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/socks.jpeg")
                 .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00123261.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/hat_snow.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00001933.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00055363.jpeg")
                   .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image: require("../../../images/aphasiaphotoset/rain_boots.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00168191.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/shoes_tied.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/hat_baseball.jpeg")
                   .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00168191.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00055360.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00055363.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/gloves.jpeg")
                 .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image: require("../../../images/aphasiaphotoset/rain_boots.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/umbrella.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/jacket.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00001993.jpeg")
                   .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image: require("../../../images/aphasiaphotoset/comb.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/hair_dryer.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/shampoo.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00055142.jpeg")
                   .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image:
                 require("../../../images/aphasiaphotoset/razor_disposable.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/nail_brush.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00055142.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/egg_beater.jpeg")
                 .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image: require("../../../images/aphasiaphotoset/toilet.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/bathtub.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/sink_bathroom_2.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/recliner_1.jpeg")
                 .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image: require("../../../images/aphasiaphotoset/drill.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/chainsaw.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/lawn_mower_1.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/screwdriver.jpeg")
                   .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00053952.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00070511.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/ladder_1.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/pitch_fork.jpeg")
                 .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image:
                 require("../../../images/aphasiaphotoset/knife_pocket.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/knife_1.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/scissors2.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/ladle.jpeg")
                 .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image: require("../../../images/aphasiaphotoset/spade.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/rake_garden.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/hoe.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/shovel_4.jpeg")
                 .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image: require("../../../images/aphasiaphotoset/motorbike.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/car4.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00053110.jpeg")
                   .default,
               isCorrect: true,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00137581.jpeg")
                   .default,
               isCorrect: false,
             },
           ],
         },
         {
           options: [
             {
               image:
                 require("../../../images/aphasiaphotoset/tunnel_subway.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/tram_1.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00137581.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/bicycle.jpeg")
                 .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image: require("../../../images/aphasiaphotoset/row_boat.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/boat_3.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/boat_sail_1.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/helicopter.jpeg")
                 .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00136421.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/fire_truck.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/police_car.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/taxi.jpeg")
                 .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00123311.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/tractor.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00091911.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/airbus_a380.jpeg")
                   .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image:
                 require("../../../images/aphasiaphotoset/flower_shop_2.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/eyeglasses_stores_2.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/clothing_store_2.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/church_3.jpeg")
                 .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image: require("../../../images/aphasiaphotoset/pizzeria_2.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/restaurant.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/ishus.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/hospital.jpeg")
                 .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image: require("../../../images/aphasiaphotoset/doctor.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/dentist.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00116241.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/barber.jpeg")
                 .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image:
                 require("../../../images/aphasiaphotoset/electrician.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00166151.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/mechanic.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/teacher_1.jpeg")
                 .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image:
                 require("../../../images/aphasiaphotoset/put_out_fire.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/police_officer_boy.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/paramedics.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/mail_carrier_3.jpeg")
                   .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00106091.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/flowers_3.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/rose_1.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/carrot.jpeg")
                 .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image: require("../../../images/aphasiaphotoset/crocus.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/flowers_3.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/rose_1.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00070141.jpeg")
                   .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image: require("../../../images/aphasiaphotoset/peach.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/cranberries1.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/walnut.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/pine_cone.jpeg")
                 .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00050629.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00054549.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00000773.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00001316.jpeg")
                   .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00001376.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00001127.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image: require("../../../images/aphasiaphotoset/elephant_3.jpeg")
                 .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00001254.jpeg")
                   .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00000875.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00000870.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00000827.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00009949.jpeg")
                   .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image:
                 require("../../../images/aphasiaphotoset/dolphin_jump.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00052588.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00001302.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00000884.jpeg")
                   .default,
               isCorrect: true,
             },
           ],
         },
         {
           options: [
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00000896.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00000883.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00009930.jpeg")
                   .default,
               isCorrect: false,
             },
             {
               image:
                 require("../../../images/aphasiaphotoset/symbol00000784.jpeg")
                   .default,
               isCorrect: true,
             },
           ],
         },
       ],
     2: [
       {
         options: [
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/1/1.jpeg")
                 .default,
             isCorrect: false,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/1/2.jpeg")
                 .default,
             isCorrect: false,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/1/3.jpeg")
                 .default,
             isCorrect: true,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/1/4.jpeg")
                 .default,
             isCorrect: false,
           },
         ],
       },
       {
         options: [
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/2/1.jpeg")
                 .default,
             isCorrect: false,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/2/2.jpeg")
                 .default,
             isCorrect: false,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/2/3.jpeg")
                 .default,
             isCorrect: true,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/2/4.jpeg")
                 .default,
             isCorrect: false,
           },
         ],
       },
       {
         options: [
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/3/1.jpeg")
                 .default,
             isCorrect: false,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/3/2.jpeg")
                 .default,
             isCorrect: false,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/3/3.jpeg")
                 .default,
             isCorrect: false,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/3/4.jpeg")
                 .default,
             isCorrect: true,
           },
         ],
       },
       {
         options: [
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/4/1.jpeg")
                 .default,
             isCorrect: true,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/4/2.jpeg")
                 .default,
             isCorrect: false,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/4/3.jpeg")
                 .default,
             isCorrect: false,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/4/4.jpeg")
                 .default,
             isCorrect: false,
           },
         ],
       },
       {
         options: [
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/5/1.jpeg")
                 .default,
             isCorrect: false,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/5/2.jpeg")
                 .default,
             isCorrect: true,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/5/3.jpeg")
                 .default,
             isCorrect: false,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/5/4.jpeg")
                 .default,
             isCorrect: false,
           },
         ],
       },
       {
         options: [
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/6/1.jpeg")
                 .default,
             isCorrect: false,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/6/2.jpeg")
                 .default,
             isCorrect: false,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/6/3.jpeg")
                 .default,
             isCorrect: false,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/6/4.jpeg")
                 .default,
             isCorrect: true,
           },
         ],
       },
       {
         options: [
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/7/1.jpeg")
                 .default,
             isCorrect: false,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/7/2.jpeg")
                 .default,
             isCorrect: true,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/7/3.jpeg")
                 .default,
             isCorrect: false,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/7/4.jpeg")
                 .default,
             isCorrect: false,
           },
         ],
       },
       {
         options: [
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/8/1.jpeg")
                 .default,
             isCorrect: true,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/8/2.jpeg")
                 .default,
             isCorrect: false,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/8/3.jpeg")
                 .default,
             isCorrect: false,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/8/4.jpeg")
                 .default,
             isCorrect: false,
           },
         ],
       },
       {
         options: [
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/10/1.jpeg")
                 .default,
             isCorrect: false,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/10/2.jpeg")
                 .default,
             isCorrect: false,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/10/3.jpeg")
                 .default,
             isCorrect: true,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/10/4.jpeg")
                 .default,
             isCorrect: false,
           },
         ],
       },
       {
         options: [
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/11/1.jpeg")
                 .default,
             isCorrect: false,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/11/2.jpeg")
                 .default,
             isCorrect: false,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/11/3.jpeg")
                 .default,
             isCorrect: true,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/11/4.jpeg")
                 .default,
             isCorrect: false,
           },
         ],
       },
       {
         options: [
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/12/1.jpeg")
                 .default,
             isCorrect: true,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/12/2.jpeg")
                 .default,
             isCorrect: false,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/12/3.jpeg")
                 .default,
             isCorrect: false,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/12/4.jpeg")
                 .default,
             isCorrect: false,
           },
         ],
       },
       {
         options: [
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/13/1.jpeg")
                 .default,
             isCorrect: false,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/13/2.jpeg")
                 .default,
             isCorrect: false,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/13/3.jpeg")
                 .default,
             isCorrect: false,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/13/4.jpeg")
                 .default,
             isCorrect: true,
           },
         ],
       },
       {
         options: [
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/14/1.jpeg")
                 .default,
             isCorrect: false,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/14/2.jpeg")
                 .default,
             isCorrect: false,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/14/3.jpeg")
                 .default,
             isCorrect: true,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/14/4.jpeg")
                 .default,
             isCorrect: false,
           },
         ],
       },
       {
         options: [
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/15/1.jpeg")
                 .default,
             isCorrect: true,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/15/2.jpeg")
                 .default,
             isCorrect: false,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/15/3.jpeg")
                 .default,
             isCorrect: false,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/15/4.jpg")
                 .default,
             isCorrect: false,
           },
         ],
       },
       {
         options: [
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/16/1.jpeg")
                 .default,
             isCorrect: false,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/16/2.jpeg")
                 .default,
             isCorrect: false,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/16/3.jpeg")
                 .default,
             isCorrect: true,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/16/4.jpeg")
                 .default,
             isCorrect: false,
           },
         ],
       },
       {
         options: [
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/17/1.jpeg")
                 .default,
             isCorrect: true,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/17/2.jpeg")
                 .default,
             isCorrect: false,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/17/3.jpeg")
                 .default,
             isCorrect: false,
           },
           {
             image:
               require("../../../images/tasks/melyik_a_kakukktojas/17/4.jpeg")
                 .default,
             isCorrect: false,
           },
         ],
       },
     ],
   };

   var questions = questions_dict[paramDifficultyLevel];

   glob.shuffleArray(questions);
   $.each(questions, (index, item) => {
      glob.shuffleArray(questions[index]["options"]);
   });
   
   var task_config = {};
   
   task_config["items"] = questions; //.slice(1, 3)
   
   return (
      <div className="task-wrapper task-wrapper-MelyikAKakukktojas">
         <QuestionItem type="valaszok_kozepen" taskConfig={task_config} />
      </div>
   );
};