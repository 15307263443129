
import "primereact/resources/themes/md-light-indigo/theme.css";
//import 'primereact/resources/themes/md-dark-indigo/theme.css';

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import { BrowserRouter, BrowserRouter as Router, Route, Switch, useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";


import GameSelector from './components/GameSelector';
import TopBar from './components/TopBar';


import './style.css';

import glob from "./components/glob";
import GameDetail from "./components/GameDetail";
import { AppContextProvider } from "./components/appContext";
import Login from "./components/Login/Login";
import PrivateRoute from "./components/Login/PrivateRoute";


const PageHome = () => {
  return (
    <>
      <div className="p-grid-page-home p-grid p-text-center">
        {glob.menu_items.map(function (item, index) {
          return (
            <>
              <div className="col-game-menu p-col-6 p-md-3">
                <RouterLink to={"/submenu/"+(index )}>
                  <div className="box p-p-3">
                    <div className="box-content">
                      <h3>{item.name}</h3>
                      <img src={item.image} alt="menu-item" width="80" />
                    </div>
                  </div>
                </RouterLink>
              </div>
            </>
          )
        })}
      </div>
    </>
  );
};


const PageGameSelector = () => {
  const { paramGameCategory } = useParams();

  return (
    <>
      <div className={"page-game-selector game-index-" + paramGameCategory}>
        <TopBar />
        <GameSelector />
      </div>
    </>
  );
};

const PageGameDetail = () => {
  const { paramGameCategory } = useParams();
  const { paramTaskId } = useParams();
  

  return (
    <>
      <div className={"page-game-detail game-index-"+paramGameCategory+"-id-"+paramTaskId}>
        <TopBar />
        <GameDetail />
      </div>
    </>
  );
};

const App = () => {
  return (
    <BrowserRouter basename="/afazia-gyakorlo-web" >
      <AppContextProvider>
        <Switch>
          <Route path="/login" component={Login} />
          <PrivateRoute path="/game/:paramGameCategory/:paramTaskId/:paramSubtaskId?/:paramDifficultyLevel?" component={PageGameDetail} />
          <PrivateRoute path="/submenu/:paramGameCategory/:paramPreviewTaskId?" component={PageGameSelector} />

          <PrivateRoute path="/" component={PageHome} />
        </Switch>
      </AppContextProvider>
    </BrowserRouter>
  );
}

export default App;
