import React from 'react';
import $ from 'jquery';
import QuestionItem from '../../QuestionItem';
import glob from '../../glob';

export default function MennyiAzIdo(props, { ...rest }) {
   
   const getOptions = (solutionHour, solutionMinute) => {
      var options = [String(solutionHour + ":" + solutionMinute)];
      while (options.length < 3) {
         var randHour = glob.getRandomElementFromArray(Array.from({ length: 24 }, (_, i) => i));
         var randMinute = glob.getRandomElementFromArray(Array.from({ length: 12 }, (_, i) => i * 5));
         if (randMinute === 5) {
            randMinute = "05";
         }
         if (randMinute === 0) {
            randMinute = "00";
         }
         var new_option = String(randHour + ":" + randMinute);
         if (!options.includes(new_option)) {
            options.push(new_option);
         }
      }
      
      var return_ = options.map(function (item, index) {
         return {
            "name": item,
            "isCorrect": index == 0
         }
      });

      glob.shuffleArray(return_);
      return return_
   }

   var questions = [];
   $.each(Array.from({ length: 30 }, (_, i) => i), (index, item) => {
      var solutionHour = glob.getRandomElementFromArray(Array.from({ length: 24 }, (_, i) => i));
      var solutionMinute = glob.getRandomElementFromArray(Array.from({ length: 12 }, (_, i) => i * 5));
      if (solutionMinute === 5) {
         solutionMinute = "05";
      }
      if (solutionMinute === 0) {
         solutionMinute = "00";
      }
      var new_item = {
         "question": {
            "name": (
               <>
                  <div id="clockContainer">
                     <div id="hour" style={{transform:`rotate(${(30 * solutionHour + solutionMinute / 2)}deg)`}}></div>
                     <div id="minute" style={{transform:`rotate(${(6 * solutionMinute)}deg)`}}></div>
                     <div id=""></div>
                  </div>
               </>
            ),
         },
         "options": getOptions(solutionHour, solutionMinute)
      };
      questions.push(new_item);
   });

   var task_config = {};
   
   task_config["items"] = questions; //.slice(1, 3)

   return (
      <div className="task-wrapper task-wrapper-MennyiAzIdo">
         <QuestionItem type="kerdes_bal_oldalon_valaszok_jobb_oldalon_egymas_alatt" taskConfig={task_config} />
      </div>
   );
};