import React, { useState, useEffect } from 'react'
import { Link as RouterLink, Redirect } from "react-router-dom";
import Cookies from 'universal-cookie';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import LicenseText from './LicenseText';

import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { ClientJS } from 'clientjs';

export const login_date_key = () => {
    return "_lgd";
};

export const license_accepted_key = () => {
    return "_lak";
};

export const login_activation_code = () => {
    return "_lac";
};

export default function Login() {
    const [inputVal, setInputVal] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [licenseAccepted, setLicenseAccepted] = useState(false);
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const cookies = new Cookies();
    const [pyWebViewMode, setPyWebViewMode] = useState(false);
    const history = useHistory();
    
    const nextYear = new Date();
    nextYear.setFullYear(new Date().getFullYear() + 3);

    const handleSubmit = (event) => {
        event.preventDefault();
        //setErrorMessage("A megadott aktiválókód nem megfelelő. Kérjük ellenőrizze, hogy helyesen adta-e meg!");
        var loginResSuccesful = login(inputVal)
        if (pyWebViewMode){
            login_aph_pywebview(inputVal);
        } else {
            login_aph(inputVal);
        }
        
        /*
        if (loginResSuccesful){
            setErrorMessage("Sikeres azonosítás")
            cookies.set('login_date', new Date().getTime(), { path: '/' });
            //history.push("/");
        } else {
            setErrorMessage("A megadott aktiválókód nem megfelelő. Kérjük ellenőrizze, hogy helyesen adta-e meg!");
            cookies.set('login_date', null, { path: '/' });
        }
        */
      };

      const login = (code) => {
        const num = Number(code);
        if (code.length>0 && num%2==0)
            return true;
         else
            return false;
      };

      const login_aph = (code) => {
          console.log("+login_aph");
            var client = new ClientJS();
            var fingerPrint = client.getFingerprint();
            var formData = new FormData()
            formData.append('requesttype', 'checklicense');
            formData.append('licensecode', code);
            formData.append('deviceid', 'android_web_' + fingerPrint);
            formData.append('platform', 'android');
            formData.append('productname', 'AfaziaGyakorlo');
            formData.append('productVersion', '1.11');

            const url='https://aphasie.hu/agy/licenseverificator.php'
            axios.post(url, formData)
            .then(response => {
                //console.log("axios response", response.data)
                eval_loginserver_response(response.data.trim())
            });
      };

    const login_aph_pywebview = (code) => {
        console.log("+login_aph_pywebview");
        var client = new ClientJS();
        var fingerPrint = client.getFingerprint();
        window.pywebview.api.checkLicense(code, fingerPrint).then(function(result) {
            console.log("+login_aph_pywebview result.message: " + result.message);
            eval_loginserver_response(result)
        });
    };

      const eval_loginserver_response = (code) => {
        console.log("+eval_loginserver_response code: '" + code + "' length: "+code.length + "type:"+ typeof(code));
        var userAllowed = false;
        if (code.length == 321) {
            const vh = code.substring(160, 160+32);
            console.log(vh)
            switch (vh){
                case '2f868f23720843f37905a320bf551522':
                //case '66696d2be8abd0d5951fb49392ed6e7c':
                userAllowed = true;
                break;
            }
        }

        if (userAllowed){
            setErrorMessage("Sikeres azonosítás")

            cookies.set(login_date_key(), new Date().getTime(), { path: '/', expires: nextYear});
            setUserLoggedIn(true);
            cookies.set(login_activation_code(), inputVal, { path: '/', expires: nextYear});
            //history.push("/");
        } else {
            setErrorMessage("A megadott aktiválókód nem megfelelő. Kérjük ellenőrizze, hogy helyesen adta-e meg!");
            cookies.set(login_date_key(), null, { path: '/', expires: nextYear });
        }
      }

      const acceptLicense = () => {
        cookies.set(license_accepted_key(), true, { path: '/', expires: nextYear });
        setLicenseAccepted(true);
      };

      const devLogin = () => {
            setErrorMessage("Sikeres azonosítás")
            cookies.set(login_date_key(), new Date().getTime(), { path: '/', expires: nextYear });
            setUserLoggedIn(true);
        //alert("Dev_login");
       /*  window.pywebview.api.getRandomNumber().then(function(result) {
            alert("result" + result.message)
        }); */
      };

      useEffect(() => {

        var _la = cookies.get(license_accepted_key())
        if (_la!== undefined && _la != "null" && _la){
            setLicenseAccepted(true);
            console.log("license accepted!")
        }

        var login_date = cookies.get(login_date_key())
        if (login_date!== undefined && login_date != "null" && login_date.length > 0){
            setUserLoggedIn(true)
            console.log("userLoggedIn!!")
        }
        
      }, [])

      useEffect(() => { //pywebview
        window.addEventListener('pywebviewready', function() {
            setPyWebViewMode(true);
            if (!window.pywebview.state) {
              window.pywebview.state = {}
            }
        })
      }, [])

      useEffect(() => {
        var client = new ClientJS();
        var fingerPrint = client.getFingerprint();
        console.log("fingerPrint", fingerPrint, client);
      }, [])


    return (
        <>
        {/* <RouterLink to={"/"}>
          Home
        </RouterLink> */}
        <div className="wrapper-login p-grid p-align-center vertical-container p-text-center p-mt-3 p-px-2">
            <div className="p-col-12 p-md-12">
                <h1 className="app-title p-m-0">Afázia Gyakorló alkalmazás</h1>
                {/* <button onClick={devLogin}>Dev login!</button> */}
                { (userLoggedIn && licenseAccepted) ? (
                    <Redirect to="/" />
                ): (
                    (userLoggedIn && !licenseAccepted) ? (
                        <div>
                            <LicenseText />
                            <Button className="p-mt-4 p-mb-6 btn-label-white" label="Elfogadás" onClick={acceptLicense} />
                        </div>
                        ):(
                            
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <h3>Kérjük adja meg e-mailben kapott aktiválókódját!</h3>
                                    <p>{errorMessage}</p>
                                </div>
                                <div className="p-col-12 p-sm-4"></div>
                                <div className="p-col-12 p-sm-4">
                                    <div className="p-inputgroup">
                                        <InputNumber placeholder="Aktiválókód..." value={inputVal} onChange={(e) => setInputVal(e.value)} useGrouping={false} />
                                        <Button className="btn-label-white" label="Aktivál" type="submit" onClick={handleSubmit} />
                                    </div>
                                </div>
                                <div className="p-col-12 p-sm-4"></div>
                            </div>
                        )
                    )
                }
            </div>
        </div>
      </>
    )
}

