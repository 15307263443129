import React from 'react';
import $ from 'jquery';
import glob from '../../glob';
import QuestionItem from '../../QuestionItem';

export default function MennyiAzEredmeny(props, { ...rest }) {
   
   function add(accumulator, a) {
      return parseInt(accumulator) + parseInt(a);
   }
   function substract(accumulator, a) {
      return  parseInt(accumulator) - parseInt(a);
   }
   function multiply(accumulator, a) {
      return parseInt(accumulator) * parseInt(a);
   }

   const getOptions = (question, type) => {
      var sum = [];
      switch(type) {
         case "+":
            sum = question.reduce(add, 0);
            break;
         case "-":
            sum = question[0] - question[1];
            break;
         case "*":
            sum = question.reduce(multiply, 1);
            break;
      }

      var options = [sum];
      console.log("variable sum is: ", sum, type, question);
      options.push((sum-parseInt(question[0])));
      options.push((sum+parseInt(question[1])));
      
      var options_to_return = options.map(function (item, index) {
         return {
            "name": item,
            "isCorrect": index == 0
         }
      });
      glob.shuffleArray(options_to_return);
      return options_to_return
   }

   var questions = [];
   $.each(Array.from({ length: 20 }, (_, i) => (i + 1)), (index, item) => {
      var operation_type = glob.getRandomElementFromArray(["+", "-", "*"]);

      var max_value = 100;
      if (operation_type == "*") {
         max_value = 10;
      } else if (operation_type == "-") {
         max_value = 30;
      }

      var val1 = glob.getRandomIntFromInterval(1, max_value);
      var val2 = glob.getRandomIntFromInterval(1, max_value);

      if (val2 > val1 && operation_type == "-") {
         var temp_ = val1;
         val1 = val2;
         val2 = temp_;
      }
      var question = [val1, val2];
      
      var name = (<>{question[0]} {operation_type} {question[1]} =</>)
      var new_item = {
         "question": {
            "name": name
         },
         "options": getOptions(question, operation_type)
      };
      questions.push(new_item);
   });
   
   var task_config = {};
   task_config["items"] = questions; //.slice(1, 3)

   return (
      <div className="task-wrapper task-wrapper-MennyiAzEredmeny">
         <QuestionItem type="kerdes_felul_valaszok_alul" taskConfig={task_config} />
      </div>
   );
};