import React from 'react';
import $ from 'jquery';
import FrameImageGallery from '../FrameImageGallery';
import { useParams } from 'react-router';

export default function MitJelent(props, { ...rest }) {
   const { paramDifficultyLevel } = useParams();
   var task_config = {
      "items": []
   };
   //"elvont" 55, 46 hiáhyzik
   
   var folder = paramDifficultyLevel == "1" ? "egyszeru" : "elvont";
   var items = paramDifficultyLevel == "1" ? 22 : 55;

   $.each(Array.from({ length: items }, (_, i) => (i + 1)), (index, item) => {
      if (paramDifficultyLevel == "2" && item == 46) {
         return false;
      }

      var new_item = (
         <img src={require('../../../images/tasks/mit_jelent/'+folder+'/'+item+'.jpeg').default} alt="gallery image" />
      );
      task_config["items"].push(new_item);
   });

   return (
      <div className="task-wrapper task-wrapper-MitJelent">
         <FrameImageGallery items={task_config["items"]} customArrows={true} />
      </div>
   );
};