import React from 'react';
import MennyiPenzedVan from '../../MennyiPenzedVan';

export default function MennyiPenzVanAPenztarcadban(props, { ...rest }) {
   var penz_ = [
      "5",
      "10",
      "20",
      "50",
      "100",
      "200",
      "500",
      "1000",
      "2000",
      "5000",
      "10000",
      "20000"
   ]

   return (
      <div className="task-wrapper task-wrapper-MennyiPenzVanAPenztarcadban">
         <MennyiPenzedVan options={penz_} taskName="MennyiPenzVanAPenztarcadban" />
      </div>
   );
};