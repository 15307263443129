import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { Container, Draggable } from 'react-smooth-dnd';

import { applyDrag, generateItems } from '../../utils';
import glob from '../../glob';
import { Button } from 'primereact/button';
import GameEndScreen from '../../GameEndScreen';
import { useAppContext } from '../../appContext';

const groupStyle = {
   margin: '50px',
   // border: '1px solid #ccc'
};
 
export default function RakdSorrendbeACselekveseket(props, { ...rest }) {
   const [items, setItems] = useState([]);
   const [items2, setItems2] = useState([]);
   const [items3, setItems3] = useState([]);
   const [questionIndex, setQuestionIndex] = useState(0);
   const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
   const [nextTaskItem, setNextTaskItem] = useState(false);
   const [taskConfig, setTaskConfig] = useState({});
   const [answerClass, setAnswerClass] = useState("");   
   const [dragBeginDelay, setDragBeginDelay] = useState(10);   
  const [gameStarted, setGameStarted] = useState(false);
  
  const { gameInstructionIsActive } = useAppContext();
  
   useEffect(() => {
      var questions = [
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/gyari/mielott_visszapillanto_becsatolta_ovet.mp3")
                .default,
            name: "Mielőtt beállította a visszapillantó tükröt, becsatolta a biztonsági övet.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/19.jpeg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/18.jpeg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/gyari/mielott_fogat_nzujtoykodott.mp3")
                .default,
            name: "Mielőtt fogat mosott, nyújtózkodott egyet.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/20.jpeg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/21.jpeg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/gyari/miutan_elultete_viragokat_meglocsolta.mp3")
                .default,
            name: "Miután elültette a virágokat, meglocsolta őket.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/23.jpeg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/22.jpeg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/gyari/miutan_fagyizott_zenethallgatott.mp3")
                .default,
            name: "Miután fagyizott, zenét hallgatott.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/7.jpeg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/17.jpeg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/gyari/mielott_telefonalt_fagyizott.mp3")
                .default,
            name: "Mielőtt telefonált, fagyizott.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/7.jpeg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/5.jpeg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/gyari/mielott_elment_vasarolni_a_baratnojevel_gordeszkazott.mp3")
                .default,
            name: "Mielőtt elment vásárolni a barátnőjével, gördeszkázott.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/10.jpeg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/2.jpeg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/gyari/miutan_megmosta_ablakot_porttorolt.mp3")
                .default,
            name: "Miután megmosta az ablakot, port törölt.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/3.jpeg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/16.jpeg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/gyari/miutan_olvasott_feltette_a_szemuveget.mp3")
                .default,
            name: "Miután olvasott, feltette a szemüvegét.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/12.jpeg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/6.jpeg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/gyari/mielott_elindult_a_terkeppel_a_kezeben_olvasott_a_padon.mp3")
                .default,
            name: "Mielőtt elindult a térképpel a kezében, olvasott a padon.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/12.jpeg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/15.jpeg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/gyari/mielott_megebedelt_ivott_egy_turmixot.mp3")
                .default,
            name: "Mielőtt megebédelt, ivott egy turmixot.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/14.jpeg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/13.jpeg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/gyari/miutan_vasarolt_fenykepezett.mp3")
                .default,
            name: "Miután vásárolt a barátnőjével, fényképezett.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/2.jpeg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/8.jpeg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/gyari/mielott_szulinapi_bulit_tartott_kitakaritott.mp3")
                .default,
            name: "Mielőtt szülinapi bulit tartott, kitakarított.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/4.jpeg")
                  .default,
              index: 1,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/16.jpeg")
                  .default,
              index: 0,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/gyari/miutan_elolvasta_az_emailt_ivott_egy_turmixot.mp3")
                .default,
            name: "Miután elolvasta az emailt, ivott egy turmixot.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/1.jpeg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/14.jpeg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/gyari/mielott_ebedelt_elkuldott_egy_smst.mp3")
                .default,
            name: "Mielőtt ebédelt, elküldött egy smst.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/11.jpeg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/13.jpeg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/gyari/miutan_telefonalt_leult_zenet_hallgatni.mp3")
                .default,
            name: "Miután telefonált, leült zenét hallgatni.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/5.jpeg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/17.jpeg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/gyari/miutan_felkelt_meglocsolta_a_viragokat.mp3")
                .default,
            name: "Miután felkelt, meglocsolta a virágokat.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/20.jpeg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/22.jpeg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/gyari/mielott_elindult_az_autoval_fogat_mosott.mp3")
                .default,
            name: "Mielőtt elindult az autóval, fogat mosott.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/19.jpeg")
                  .default,
              index: 1,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/21.jpeg")
                  .default,
              index: 0,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/gyari/miutan_megpucolta_az_ablakot_elment_fagyizni.mp3")
                .default,
            name: "Miután megpucolta az ablakot, elment fagyizni.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/3.jpeg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/7.jpeg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/gyari/miutan_ablakot_pucolt_megebedelt.mp3")
                .default,
            name: "Miután ablakot pucolt, megebédelt.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/3.jpeg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/stock/13.jpeg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/Agota_fele/miutan_kezet_mosott_kavet_ivott.mp3")
                .default,
            name: "Miután kezet mosott, kávét ivott.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/kezet_mosott.jpg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/kavet_ivott.jpg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/Agota_fele/miutan_leteritette_az_asztalt_levelet_irt.mp3")
                .default,
            name: "Miután leterítette az asztalt, levelet írt.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/leteritette_az_asztalt.jpg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/levelet_irt.jpg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/Agota_fele/miutan_kivette_a_csomagot_a_csomagtartobol_beallt_a_garazsba.mp3")
                .default,
            name: "Miután kivette a csomagot a csomagtartóból, beállt a garázsba.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/kivette_a_csomagot.jpg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/beallt_a_garazsba.jpg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/Agota_fele/mielott_feltette_a_szemuveget_bekapcsolta_a_biztonsagi_ovet.mp3")
                .default,
            name: "Mielőtt feltette a szemüvegét, bekapcsolta a biztonsági övét.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/bekapcsolta_az_ovet.jpg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/feltette_a_szemuveget.jpg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/Agota_fele/miutan_felkelt_ablakot_nyitott.mp3")
                .default,
            name: "Miután felkelt, ablakot nyitott.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/felkelt.jpg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/ablakot_nyitott.jpg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/Agota_fele/miutan_beallt_a_garazsba_kiszallt_az_autobol.mp3")
                .default,
            name: "Miután beállt a garázsba, kiszállt az autóból.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/beallt_a_garazsba.jpg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/kiszallt_a_garazsban.jpg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/Agota_fele/mielott_kutyat_setaltatott_aludt_egy_kicsit.mp3")
                .default,
            name: "Mielőtt kutyát sétáltatott, aludt egy kicsit.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/aludt.jpg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/kutyat_setaltatott.jpg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/Agota_fele/miutan_aludt_zenet_hallgatott.mp3")
                .default,
            name: "Miután aludt, zenét hallgatott.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/aludt.jpg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/zenet_hallgatott.jpg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/Agota_fele/miutan_kutyat_setaltatott_tvt_nezett.mp3")
                .default,
            name: "Miután kutyát sétáltatott, tévét nézett.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/kutyat_setaltatott.jpg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/tvzett.jpg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/Agota_fele/mielott_vasalt_mosogatott.mp3")
                .default,
            name: "Mielőtt vasalt, mosogatott.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/mosogatott.jpg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/vasalt.jpg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/Agota_fele/mielott_vasalt_telefonalt.mp3")
                .default,
            name: "Mielőtt vasalt, telefonált.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/telefonalt.jpg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/vasalt.jpg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/Agota_fele/mielott_mosogatott_ivott_egy_pohar_vizet.mp3")
                .default,
            name: "Mielőtt mosogatott, ivott egy pohár vizet.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/ivott.jpg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/mosogatott.jpg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/Agota_fele/miutan_ablakot_nyitott_etelt_melegitett.mp3")
                .default,
            name: "Miután ablakot nyitott, ételt melegített.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/fozott.jpg")
                  .default,
              index: 1,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/ablakot_nyitott.jpg")
                  .default,
              index: 0,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/Agota_fele/miutan_elmosogatott_setalt_a_kutyaval.mp3")
                .default,
            name: "Miután elmosogatott, sétált a kutyával.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/mosogatott.jpg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/kutyat_setaltatott.jpg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/Agota_fele/mielott_setalt_porszivozott.mp3")
                .default,
            name: "Mielőtt sétált, porszívózott.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/porszivozott.jpg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/setalt.jpg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/Agota_fele/miutan_felvitte_a_csomagokat_olvasott.mp3")
                .default,
            name: "Miután felvitte a csomagokat, olvasott.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/felvitte_a_csomagot.jpg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/ovlasott.jpg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/Agota_fele/miutan_fogat_mosott_bekapcsolta_a_mosogepet.mp3")
                .default,
            name: "Miután fogat mosott, bekapcsolta a mosógépet.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/fogat_mosott.jpg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/bekapcsolta_mosogepet.jpg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/Agota_fele/miutan_kiszallt_az_autobolo_gyumolcsot_vasarolt.mp3")
                .default,
            name: "Miután kiszállt az autóból, gyümölcsöt vásárolt.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/kiszallt_kocsibol.jpg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/gyumolcsot_vett.jpg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/Agota_fele/miutan_elmosogatott_eltorolte_az_edenyt.mp3")
                .default,
            name: "Miután elmosogatott, eltörölte az edényt.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/mosogatott.jpg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/eltorulte_az_edenyt.jpg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/Agota_fele/miutan_levelet_irt_telefonalt.mp3")
                .default,
            name: "Miután levelet írt, telefonált.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/levelet_irt.jpg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/telefonalt.jpg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/Agota_fele/miutan_bevasarolt_beszallt_az_autoba.mp3")
                .default,
            name: "Miután bevásárolt, beszállt az autóba.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/bevasarolt.jpg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/beult_autoba.jpg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/Agota_fele/mielott_lepihent_etelt_melegitett.mp3")
                .default,
            name: "Mielőtt lepihent, ételt melegített.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/fozott.jpg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/aludt.jpg")
                  .default,
              index: 1,
            },
          ],
        },
        {
          question: {
            sound:
              require("../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/Agota_fele/mielott_zenet_hallgatott_leteritette_az_asztalt.mp3")
                .default,
            name: "Mielőtt zenét hallgatott, leterítette az asztalt.",
          },
          options: [
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/leteritette_az_asztalt.jpg")
                  .default,
              index: 0,
            },
            {
              image:
                require("../../../images/stockphotos/rakd_sorrendbe_a_cselekveseket/Agota_fele/zenet_hallgatott.jpg")
                  .default,
              index: 1,
            },
          ],
        },
      ];
         
      glob.shuffleArray(questions);
      $.each(questions, (index, item) => {
         glob.shuffleArray(questions[index]["options"]);
      });
      
      console.log("variable questions: ", questions);
      var task_config = {};
      task_config["items"] = questions; //.slice(1, 3)
      
      setTaskConfig(task_config);
      setQuestionIndex(0);
   }, []);

   useEffect(() => {
    if (gameInstructionIsActive && gameStarted) {
     
      setTimeout(function () {
        glob.playSound(taskConfig["items"][questionIndex]["question"]["sound"]);
     }, 3000);
      
    }
  }, [gameInstructionIsActive]);

   useEffect(() => {
      if (taskConfig.hasOwnProperty('items')) {
         setItems(taskConfig["items"][questionIndex]["options"]);
         setNextTaskItem(taskConfig["items"][questionIndex + 1]);
         setAnswerClass("");
         setDragBeginDelay(10);
         
         setTimeout(function () {
            glob.playSound(taskConfig["items"][questionIndex]["question"]["sound"]);
         }, questionIndex == 0 ? 3000 : 0);
         setTimeout(function () {
          setGameStarted(true);
        }, 1000);
      }
      return () => {
         glob.muteSound();
      };
   }, [questionIndex, taskConfig]);


   useEffect(() => {
      var items2_condition = items2.length == 1 && items2[0]["index"] == 0;
      var items3_condition = items3.length == 1 && items3[0]["index"] == 1;
      
      var is_user_done = items2.length == 1 && items3.length == 1 && items.length == 0;
      var is_correct = is_user_done && items2_condition && items3_condition;

      setIsAnswerCorrect(is_correct);

      if (is_user_done) {
         glob.playGameSound(is_correct ? "correct" : "incorrect");
         setAnswerClass(is_correct ? "correct" : "incorrect");
         if (is_correct) {
            setDragBeginDelay(10000);
         } else {
            setTimeout(function () {
               setAnswerClass("");
               setItems(taskConfig["items"][questionIndex]["options"]);
               setItems2([]);
               setItems3([]);   
            }, 2000);
            
         }
      }
   }, [items, items2, items3]);
   
   /*
   var taskConfig = {
      "items": []
   };
   
   var kerdesek = [
         {
            "question": {
               "name": "Mielőtt telefonált, fagyizott.",
               "sound": require('../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/mielott_telefonalt_fagyizott.mp3').default
            },
            "options": [
               {
                  "image": require('../../../images/tasks/rakd_sorrendbe_a_cselekveseket/7.jpeg').default,
                  "index":0
               },{
                  "image": require('../../../images/tasks/rakd_sorrendbe_a_cselekveseket/5.jpeg').default,
                  "index":1
               }
            ]
         },{
            "question": {
               "name": "Mielőtt ebédelt, elküldött egy smst.",
               "sound": require('../../../sounds/voice/rakd_sorrendbe_a_cselekveseket/mielott_ebedelt_elkuldott_egy_smst.mp3').default
            },
            "options": [
               {
                  "image": require('../../../images/tasks/rakd_sorrendbe_a_cselekveseket/11.jpeg').default,
                  "index":0
               },{
                  "image": require('../../../images/tasks/rakd_sorrendbe_a_cselekveseket/13.jpeg').default,
                  "index":1
               }
            ]
         }
      
   ]

   $.each(kerdesek, (index, item) => {
      //glob.shuffleArray(item);

      taskConfigtask_config["items"].push(item);
   }); */


   const goNextSlide = () => {
      setQuestionIndex(questionIndex + 1);
      setIsAnswerCorrect(false);
      setItems2([]);
      setItems3([]);
      setDragBeginDelay(10);
   }
   
  const resetGame = () => {
    var task_config = taskConfig;
    glob.shuffleArray(task_config["items"], true);
    setTaskConfig(task_config);

      setIsAnswerCorrect(false);
      setQuestionIndex(0);
      setItems2([]);
      setItems3([]);
      setDragBeginDelay(10);
   }

   if ((!nextTaskItem && isAnswerCorrect)) {
      return <GameEndScreen className="nomargintop" resetGame={ resetGame } />
   }
   return (
      <div className={`task-wrapper task-wrapper-RakdSorrendbeACselekveseket ${answerClass}`} >
         <div className="p-grid"> 
            <div className="p-col-12 p-md-4 col-left">
               <Container groupName="1" dragClass="dnd-ghost-drop" dragBeginDelay={dragBeginDelay} animationDuration={100} style={groupStyle} getChildPayload={i => items[i]} onDrop={e => setItems(applyDrag(items, e))}>
                  {items.map(function (item, index) {
                     return (
                        <Draggable key={item.index}>
                           <div className="draggable-item">
                              <img className="question-image" src={item.image} />
                           </div>
                        </Draggable>
                     )
                  })}
               </Container>
            </div>
            <div className="p-col-12 p-md-8 bg-arrow">
               <div className="p-grid p-justify-center">
                  <div className="p-col-12 sy-position-relative">
                     {taskConfig.hasOwnProperty("items") && <h5>{taskConfig["items"][questionIndex]["question"]["name"]}</h5>}
                     {<Button label={<img src={ glob.icons["speaker"] } width="40" />} className="btn-volume-up p-button-rounded p-button-text p-button-plain p-icon-fs-2rem" onClick={() => glob.playSound(taskConfig["items"][questionIndex]["question"]["sound"]) } />}
                  </div>
                  <div className="p-col-5 scene first-scene">
                     <Container groupName="1" dragClass="dnd-ghost-drop" dragBeginDelay={dragBeginDelay} animationDuration={100} getChildPayload={i => items2[i]} onDrop={e => setItems2(applyDrag(items2, e))}>
                        {items2.map(function (item, index) {
                           return (
                              <Draggable key={item.index}>
                                 <div className="draggable-item">
                                    <img className="question-image" src={item.image} />
                                 </div>
                              </Draggable>
                           )
                        })}
                     </Container>
                  </div>
                  <div className="p-col-1"></div>
                  <div className="p-col-5 scene second-scene">
                     <Container groupName="1" dragClass="dnd-ghost-drop" dragBeginDelay={dragBeginDelay} animationDuration={100} getChildPayload={i => items3[i]} onDrop={e => setItems3(applyDrag(items3, e))}>
                        {items3.map(function (item, index) {
                           return (
                              <Draggable key={item.index}>
                                 <div className="draggable-item">
                                    <img className="question-image draggable-img" src={item.image} />
                                 </div>
                              </Draggable>
                           )
                        })}
                     </Container>
                  </div>
               </div>
            </div>
            
            <div className="p-col-12">
               {(isAnswerCorrect && nextTaskItem) && (
                  <>
                     <div className="wrapper-btn-next-task">
                        <Button label={<img src={ glob.icons["nextExerciseBig"] } width="50" />} className="btn-next-task p-button-rounded p-button-text" onClick={() => goNextSlide()} />
                        <div className="bg-of-btn-next-task"></div>
                     </div>
                  </>
               )}
            </div>
         </div>
         
      </div>
   );
};