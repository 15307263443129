import React from 'react'

export default function LicenseText() {
    return (
        <div className="wrapper-LicenseText">
            <h1>Szoftver végfelhasználói licencszerződés</h1>
            <p>amely l&eacute;trej&ouml;tt egyr&eacute;szről</p>
            <p>az <strong>AF&Aacute;ZIA &ndash; Az &Uacute;jrabesz&eacute;lők Egyes&uuml;lete</strong> (sz&eacute;khely: 1075 Budapest, Rumbach. S. u. 12; ad&oacute;sz&aacute;m: 18194502-1-42; honlap: <a href="http://www.aphasie.hu">www.aphasie.hu</a>) mint Licensztulajdonos (<strong>tov&aacute;bbiakban: Licensztulajdonos</strong>)</p>
            <p>&eacute;s</p>
            <p><strong>&Ouml;n, mint felhaszn&aacute;l&oacute;</strong> (<strong>tov&aacute;bbiakban: Felhaszn&aacute;l&oacute;</strong>),</p>
            <p>(Licensztulajdonos &eacute;s Felhaszn&aacute;l&oacute; a tov&aacute;bbiakban egy&uuml;ttesen: Szerződő Felek) k&ouml;z&ouml;tt</p>
            <p>az al&aacute;bbi felt&eacute;telek szerint:</p>
            <ol>
            <li><strong> A szerződ&eacute;s t&aacute;rgya</strong></li>
            </ol>
            <p>Licensztulajdonos &aacute;ltal kifejlesztett, Licensztulajdonos <a href="http://www.aphasie.hu">www.aphasie.hu</a> honlapj&aacute;r&oacute;l let&ouml;lthető, Licensztulajdonos kiz&aacute;r&oacute;lagos tulajdon&aacute;t k&eacute;pező <strong>Af&aacute;zia Gyakorl&oacute;</strong><strong> szoftver</strong> (<strong>tov&aacute;bbiakban:</strong><strong>Szoftverterm&eacute;k</strong>) <strong>felhaszn&aacute;l&oacute;i jog&aacute;nak rendelkez&eacute;sre bocs&aacute;t&aacute;sa</strong>.</p>
            <ol start="2">
            <li><strong> A licensz terjedelme, Felhaszn&aacute;l&oacute; jogai &eacute;s k&ouml;teless&eacute;gei: </strong></li>
            </ol>
            <p>2.1. A szoftverterm&eacute;k a szerzői jog &eacute;s a szellemi tulajdonra vonatkoz&oacute; jogszab&aacute;lyok v&eacute;delme alatt &aacute;ll. Jelen szerződ&eacute;ssel Licensztulajdonos enged&eacute;lyezi a szoftverterm&eacute;k haszn&aacute;lat&aacute;t, de tulajdon&aacute;t nem ruh&aacute;zza &aacute;t.</p>
            <p>2.2. V&eacute;gfelhaszn&aacute;l&oacute; jogosult a Szoftverterm&eacute;k 1 p&eacute;ld&aacute;ny&aacute;t saj&aacute;t eszk&ouml;z&eacute;n 1 p&eacute;ld&aacute;nyban telep&iacute;teni, haszn&aacute;lni, el&eacute;rni, megjelen&iacute;teni, futtatni.</p>
            <p>2.4. Felhaszn&aacute;l&oacute; nem jogosult a Szoftverterm&eacute;ket visszaford&iacute;tani, visszafejteni, belső fel&eacute;p&iacute;t&eacute;s&eacute;t elemezni. A Szoftverterm&eacute;ket sem r&eacute;szben, sem eg&eacute;szben nem adhatja k&ouml;lcs&ouml;nbe, b&eacute;rbe, vagy haszonb&eacute;rbe, vagy l&iacute;zingbe, illetve m&aacute;s m&oacute;don sem adhatja &aacute;t m&aacute;sik Felhaszn&aacute;l&oacute;nak. A Szoftverterm&eacute;ket, sem r&eacute;szben sem eg&eacute;szben, nem &eacute;rt&eacute;kes&iacute;theti, &eacute;p&iacute;theti be m&aacute;sik szoftverbe, &eacute;s tilos a Szoftverterm&eacute;knek, eg&eacute;szben vagy r&eacute;szben, b&aacute;rmilyen m&aacute;s, profitszerz&eacute;s c&eacute;lj&aacute;b&oacute;l megval&oacute;sul&oacute; felhaszn&aacute;l&aacute;sa. A haszn&aacute;lati jog a Szoftverterm&eacute;kre mint egys&eacute;ges term&eacute;kre &eacute;rv&eacute;nyes, alkot&oacute;elemei nem k&uuml;l&ouml;n&iacute;thetők el egym&aacute;st&oacute;l.</p>
            <p>2.5. Amennyiben Felhaszn&aacute;l&oacute; tudom&aacute;st szerez a Szoftverterm&eacute;k b&aacute;rmif&eacute;le jogosulatlan felhaszn&aacute;l&aacute;s&aacute;r&oacute;l, &uacute;gy k&ouml;teles mindent megtenni annak megsz&uuml;ntet&eacute;se &eacute;rdek&eacute;ben. A tudom&aacute;s&aacute;ra jutott jogosulatlan felhaszn&aacute;l&aacute;st k&ouml;teles megakad&aacute;lyozni, &eacute;s k&ouml;teles a tudom&aacute;s&aacute;ra jutott adatokr&oacute;l a Licensztulajdonost halad&eacute;ktalanul &iacute;r&aacute;sban t&aacute;j&eacute;koztatni.</p>
            <p>2.6. A Szoftverterm&eacute;kbe a Felhaszn&aacute;l&oacute; &aacute;ltal bet&ouml;lt&ouml;tt k&eacute;p-, hang &eacute;s sz&ouml;veganyag tartalmak&eacute;rt, a Szoftverterm&eacute;kkel l&eacute;trehozott &uuml;zenetek&eacute;rt kiz&aacute;r&oacute;lag a Felhaszn&aacute;l&oacute; felelős, azzal kapcsolatban a Licensztulajdonost semmilyen felelőss&eacute;g vagy k&ouml;telezetts&eacute;g nem terheli.</p>
            <ol start="3">
            <li><strong> Licensztulajdonos k&ouml;telezetts&eacute;ge, felelőss&eacute;ge</strong></li>
            </ol>
            <p>3.1. Licensztulajdonos a Szoftverterm&eacute;k telep&iacute;t&eacute;s&eacute;hez sz&uuml;ks&eacute;ges telep&iacute;tőf&aacute;jlt &eacute;s aktiv&aacute;l&oacute; k&oacute;dot a Felhaszn&aacute;l&oacute; rendelkez&eacute;s&eacute;re bocs&aacute;tja.</p>
            <p>3.2. A Szoftverterm&eacute;k futtat&aacute;s&aacute;hoz min. android 4.2 vagy &uacute;jabb rendszerű eszk&ouml;z sz&uuml;ks&eacute;ges, a Szoftverterm&eacute;k t&aacute;rhelyig&eacute;nye min. 400 mb. Nem k&eacute;pezi a Licensztulajdonos feladat&aacute;t a Felhaszn&aacute;l&oacute; &aacute;ltal haszn&aacute;lni k&iacute;v&aacute;nt eszk&ouml;z alkalmass&aacute; t&eacute;tele a jelen Szoftverterm&eacute;k fogad&aacute;s&aacute;ra &eacute;s futtat&aacute;s&aacute;ra.</p>
            <p>3.3. Licensztulajdonos t&ouml;rekszik a term&eacute;k hossz&uacute; t&aacute;v&uacute; fenntarthat&oacute;s&aacute;g&aacute;ra, telefonon, vagy egy&eacute;b online m&oacute;don seg&iacute;ts&eacute;get ny&uacute;jt, tan&aacute;csot ad a Szoftverterm&eacute;k haszn&aacute;lat&aacute;val kapcsolatban, azonban a Szoftverterm&eacute;k friss&iacute;t&eacute;se, vagy meghat&aacute;rozott ideig terjedő term&eacute;kt&aacute;mogat&aacute;sa semmilyen m&eacute;rt&eacute;kben sem k&eacute;pezi Licensztulajdonos feladat&aacute;t.</p>
            <p>3.4. Licensztulajdonos a Felhaszn&aacute;l&oacute; k&eacute;sz&uuml;l&eacute;k&eacute;n fell&eacute;pő esetleges hib&aacute;k&eacute;rt &eacute;s műszaki korl&aacute;tok&eacute;rt nem tartozik felelőss&eacute;ggel. Licensztulajdonos semmilyen k&aacute;rt&eacute;r&iacute;t&eacute;si k&ouml;telezetts&eacute;g nem terheli tov&aacute;bb&aacute; a Szoftverterm&eacute;k esetleges hib&aacute;ib&oacute;l, vagy helytelen haszn&aacute;lat&aacute;b&oacute;l eredő k&aacute;rokat illetően.</p>
            <ol start="4">
            <li><strong> A szerződ&eacute;s l&eacute;trej&ouml;tte</strong></li>
            </ol>
            <p>A szerződ&eacute;s a Szoftverterm&eacute;k Felhaszn&aacute;l&oacute; &aacute;ltal, a Felhaszn&aacute;l&oacute; eszk&ouml;z&eacute;n t&ouml;rt&eacute;nő telep&iacute;t&eacute;s&eacute;vel, k&uuml;l&ouml;n al&aacute;&iacute;r&aacute;s n&eacute;lk&uuml;l, j&ouml;n l&eacute;tre.</p>
            <ol start="5">
            <li><strong> A szerződ&eacute;s hat&aacute;lya, m&oacute;dos&iacute;t&aacute;sa, megszűn&eacute;se, ir&aacute;nyad&oacute; jog</strong></li>
            </ol>
            <p>A jelen szerződ&eacute;s hat&aacute;rozatlan időre j&ouml;n l&eacute;tre. Licensztulajdonos egyoldal&uacute;an &eacute;s azonnali hat&aacute;llyal felmondhatja a jelen szerződ&eacute;st, ha Felhaszn&aacute;l&oacute; az abban foglaltakat nem tartja be, b&aacute;rmelyik rendelkez&eacute;s&eacute;t megs&eacute;rti.</p>
            <p>A szerződ&eacute;s egyoldal&uacute; m&oacute;dos&iacute;t&aacute;s&aacute;ra kiz&aacute;r&oacute;lag a Felhaszn&aacute;l&oacute; előzetes &eacute;rtes&iacute;t&eacute;se mellett, Licensztulajdonos jogosult.</p>
            <p>A szerződ&eacute;s megszűn&eacute;se eset&eacute;n Felhaszn&aacute;l&oacute; k&ouml;teles a let&ouml;lt&ouml;tt Szoftverterm&eacute;ket eszk&ouml;z&eacute;ről teljes m&eacute;rt&eacute;kben elt&aacute;vol&iacute;tani, a telep&iacute;tőf&aacute;jlt megsemmis&iacute;teni.</p>
            <p>A szerződ&eacute;sre a magyar jog rendelkez&eacute;sei az ir&aacute;nyad&oacute;k. A szerződ&eacute;sben r&eacute;szletesen nem szab&aacute;lyozott k&eacute;rd&eacute;sekben a mindenkori vonatkoz&oacute; jogszab&aacute;lyok rendelkez&eacute;sei az ir&aacute;nyad&oacute;k.</p>
            <p>K.m.f.</p>
            <p>---</p>
        </div>
    )
}
