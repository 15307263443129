import React, { createRef, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { useHistory, useLocation, useParams } from 'react-router';
import { Redirect } from "react-router-dom";

import Slider from "react-slick";
import $ from 'jquery';

import glob from '../../glob';
import { useAppContext } from '../../appContext';
import GameEndScreen from '../../GameEndScreen';

export default function OlvasdFelHangosanASzoveget(props, { ...rest }) {
   var { paramSubtaskId } = useParams();
   paramSubtaskId = parseInt(paramSubtaskId);
   const [currentSlide, setCurrentSlide] = useState(0);
   const [questionIndex, setQuestionIndex] = useState(0);
   const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
   const [gameEnded, setGameEnded] = useState(false);
   const [taskItem, setTaskItem] = useState(null);
   const [isInstructionSoundPlayed, setIsInstructionSoundPlayed] = useState(false);
   const history = useHistory();
   const location = useLocation();
   
   const { setAppTitle } = useAppContext();
   const { setHideSelector } = useAppContext();

   const myslider = createRef();

   var task_config = {
      "items": [
         {
            "name": "Sok alma termett a kertben, ezért elhatároztam, csinálok kompótot. Először meghámoztam az almákat, feldaraboltam, és feltettem főzni. Fahéj, szegfűszeg és cukor helyett véletlenül egy nagy kanál só került bele. A családomnak nem ízlett.",
            "questions": [
               {
                  "question": {
                     "name": "Mit csináltam először?",
                  },
                  options: [
                     { name: "meghámoztam az almákat", isCorrect: true },
                     { name: "feldaraboltam az almákat ", isCorrect: false },
                     { name: "feltettem főzni a kompótot", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Mivel ízesítettem?",
                  },
                  options: [
                     { name: "sóval", isCorrect: true },
                     { name: "fahéjjal", isCorrect: false },
                     { name: "cukorral", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Vajon miért nem ízlett a családnak a kompót?",
                  },
                  options: [
                     { name: "nagyon sós volt", isCorrect: true },
                     { name: "nagyon édes volt", isCorrect: false },
                     { name: "nagyon fahéjas volt", isCorrect: false },
                   ],
               }
            ]
         },{
            "name": "Egy fiatal lány bement a fodrászhoz, mert szeretett volna egy új frizurát. Nagyon sokan voltak az üzletben, három órát kellett volna várnia. Várni nem volt türelme, ezért elment vásárolni. Vett egy pár cipőt, egy táskát és egy szép sálat. Mire visszaért, észrevette, hogy elfogyott a pénze, így új frizura nélkül ment haza.",
            "questions": [
               {
                  "question": {
                     "name": "Mennyit kellett volna várnia?",
                  },
                  options: [
                     { name: "3 órát", isCorrect: true },
                     { name: "1 órát", isCorrect: false },
                     { name: "2 órát", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Miket vásárolt?",
                  },
                  options: [
                     { name: "cipőt, sapkát, sálat", isCorrect: false },
                     { name: "cipőt, sálat, táskát", isCorrect: true },
                     { name: "kesztyűt, sálat, esernyőt", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Milyen lett az új frizurája?",
                  },
                  options: [
                     { name: "semmilyen", isCorrect: true },
                     { name: "rövid", isCorrect: false },
                     { name: "göndör", isCorrect: false },
                   ],
               }
            ]
         },{
            "name": "A gyerekek nagyon szeretnek este a tűznél szalonnát sütni. Már délután előkészítik a fát, apró gallyakat tesznek alulra, arra jönnek a vastagabb ágak és a tetejére egy nagy rönk. Mire a tűz leég és jó a parázs, megcsinálják a nyársakat. Szalonna, hagyma, kolbász kerül rájuk. A zsírt kenyérre csepegtetik, paprikát, paradicsomot esznek hozzá.",
            "questions": [
               {
                  "question": {
                     "name": "Mi keletkezik, ha leég a tűz?",
                  },
                  options: [
                     { name: "parázs", isCorrect: true },
                     { name: "szén", isCorrect: false },
                     { name: "víz", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Hova tűzik fel a sütni való ételeket?",
                  },
                  options: [
                     { name: "késre", isCorrect: false },
                     { name: "nyársra", isCorrect: true },
                     { name: "villára", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Mit nem esznek hozzá?",
                  },
                  options: [
                     { name: "paprikát", isCorrect: false },
                     { name: "paradicsomot", isCorrect: false },
                     { name: "krumplit", isCorrect: true },
                   ],
               }
            ]
         },{
            "name": "Vettem egy új könyvespolcot a gyerekeknek. Azt gondoltam, az alsó polcra teszem a képeskönyveket, hogy a három éves unokám is elérje. Középen a mesekönyvek vannak a hét évesnek,  és a felső polcon a Harry Potter regények vannak a nagyobbaknak.",
            "questions": [
               {
                  "question": {
                     "name": "Hány éves az a gyerek, aki a mesekönyveket olvassa?",
                  },
                  options: [
                     { name: "7 éves", isCorrect: true },
                     { name: "8 éves", isCorrect: false },
                     { name: "12 éves", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Kik olvassák Harry Potter-t?",
                  },
                  options: [
                     { name: "a nagyobbak", isCorrect: true },
                     { name: "a 7 évesek", isCorrect: false },
                     { name: "a szülők", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Hány polc van?",
                  },
                  options: [
                     { name: "3", isCorrect: true },
                     { name: "4", isCorrect: false },
                     { name: "2", isCorrect: false },
                   ],
               }
            ]
         },{
            "name": "Klárinak két macskája van, Mogyi és Cili. Naponta kétszer esznek, reggel száraz tápot kapnak, este pedig húst. Külön tányérjuk van, nehogy összevesszenek. A vizet egy fehér edényből isszák, az közös.\nAz almot egy kis lyukas lapáttal kell minden nap megtisztítani, hogy ne legyen a lakásban macskaszag. Akinek macskája van, sose unatkozik.",
            "questions": [
               {
                  "question": {
                     "name": "Hány adag ételt fogyasztanak a macskák egy nap összesen?",
                  },
                  options: [
                     { name: "kettőt", isCorrect: false },
                     { name: "négyet", isCorrect: true },
                     { name: "hatot", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Mit esznek este?",
                  },
                  options: [
                     { name: "macskatápot", isCorrect: false },
                     { name: "déli maradékot", isCorrect: false },
                     { name: "húst", isCorrect: true },
                   ],
               },{
                  "question": {
                     "name": "Mitől nem lesz a lakásban macskaszag?",
                  },
                  options: [
                     { name: "Klári minden nap megtisztítja az almot", isCorrect: true },
                     { name: "a macskák szobatiszták és kikéredzkednek", isCorrect: false },
                     { name: "szagelszívó működik a lakásban", isCorrect: false },
                   ],
               }
            ]
         },{
            "name": "A szobafestő felvonult egy lakásba, mert megrendelték, hogy meszelje ki a konyhát.\nVitte magával a vödröt, az ecseteket, a zöld festéket. Sajnos a létrát elfelejtette, ezért nem tudta teljesen elvégezni a munkát. Amikor a megrendelő meglátta, mit csinált, elájult.",
            "questions": [
               {
                  "question": {
                     "name": "Milyen hibákat követett el a szobafestő?",
                  },
                  options: [
                     { name: "nem keverte fel a festéket", isCorrect: false },
                     { name: "nem takarta le a bútorokat", isCorrect: false },
                     { name: "nem vitt létrát", isCorrect: true },
                   ],
               },{
                  "question": {
                     "name": "Mennyit fizetett a megrendelő?",
                  },
                  options: [
                     { name: "nem derül ki", isCorrect: true },
                     { name: "8.000 Ft-ot", isCorrect: false },
                     { name: "12.000 Ft-ot", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Mit mondanak az ilyen mesterre?",
                  },
                  options: [
                     { name: "aranykezű", isCorrect: false },
                     { name: "kontár", isCorrect: true },
                     { name: "profi", isCorrect: false },
                   ],
               }
            ]
         },{
            "name": "Béla nagyon szereti az erős paprikát, minden tavasszal vesz néhány palántát. Ősszel boldogan szedi le a piros csöveket a tőről. Gumikesztyűt húz, amikor darálja, mert az erős paprika kimarná a kezét. A megdarált paprikát kis üvegekbe teszi, olíva olajat önt rá, és karácsonykor evvel kedveskedik a barátainak, akik szívesen csepegtetnek az csípős olajból az ételeikre.",
            "questions": [
               {
                  "question": {
                     "name": "Béla hogyan védi meg a kezét?",
                  },
                  options: [
                     { name: "kézkrémmel", isCorrect: false },
                     { name: "kézmosással", isCorrect: false },
                     { name: "gumikesztyűvel", isCorrect: true },
                   ],
               },{
                  "question": {
                     "name": "Mit önt a paprikára?",
                  },
                  options: [
                     { name: "olajat", isCorrect: true },
                     { name: "ecetet", isCorrect: false },
                     { name: "vizet", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Mikor és kinek ad a kis üvegekből?",
                  },
                  options: [
                     { name: "Mikuláskor a gyerekeknek", isCorrect: false },
                     { name: "Karácsonykor a barátainak", isCorrect: true },
                     { name: "Húsvétkor a kollégáinak", isCorrect: false },
                   ],
               }
            ]
         },{
            "name": "Valaki talált egy kosarat a piacon. Megnézte, mi van a kosárban és megpróbálta kitalálni, vajon mit akart főzni, aki elvesztette. Volt benne sárgarépa, fehérrépa, karalábé, kelkáposzta, zeller, petrezselyem, gomba és egy tyúk. \nEzen kívül volt még hagyma, paprika, paradicsom és kolbász, valamint liszt, tojás, tej.",
            "questions": [
               {
                  "question": {
                     "name": "Milyen levest akart főzni a kosár gazdája?",
                  },
                  options: [
                     { name: "borsólevest", isCorrect: false },
                     { name: "krumplilevest", isCorrect: false },
                     { name: "tyúkhúslevest", isCorrect: true },
                   ],
               },{
                  "question": {
                     "name": "Mi készülhetett volna még a kosár tartalmából?",
                  },
                  options: [
                     { name: "tökfőzelék", isCorrect: false },
                     { name: "lecsó", isCorrect: true },
                     { name: "uborkasaláta", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Hol veszett el a kosár?",
                  },
                  options: [
                     { name: "piacon", isCorrect: true },
                     { name: "csarnokban", isCorrect: false },
                     { name: "zöldségesnél", isCorrect: false },
                   ],
               }
            ]
         },{
            "name": "A lányomnak három házas gyereke van. Van két menye és egy veje. \nMindkét menyének van 2-2 kislánya, a vejének 3 fia.",
            "questions": [
               {
                  "question": {
                     "name": "Hány lánya van a lányomnak?",
                  },
                  options: [
                     { name: "1", isCorrect: true },
                     { name: "2", isCorrect: false },
                     { name: "3", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Hány fia van a lányomnak?",
                  },
                  options: [
                     { name: "2", isCorrect: true },
                     { name: "1", isCorrect: false },
                     { name: "3", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Hány unokája van a lányomnak?",
                  },
                  options: [
                     { name: "7", isCorrect: true },
                     { name: "5", isCorrect: false },
                     { name: "3", isCorrect: false },
                   ],
               }
            ]
         },{
            "name": "A nagy három emeletes óceánjáró hajón 1000 utas utazhat egyszerre. Ezen kívül van 1000 fő személyzet. Az utasok kétszemélyes kabinokban laknak, a személyzet négyszemélyes kabinokban. \nMinden emeleten ugyanannyi kabin van.",
            "questions": [
               {
                  "question": {
                     "name": "Hány kabin van összesen?",
                  },
                  options: [
                     { name: "750", isCorrect: true },
                     { name: "500", isCorrect: false },
                     { name: "1000", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Hány kabin van egy emeleten?",
                  },
                  options: [
                     { name: "250", isCorrect: true },
                     { name: "500", isCorrect: false },
                     { name: "750", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Hány éves a kapitány?",
                  },
                  options: [
                     { name: "nem tudjuk", isCorrect: true },
                     { name: "38", isCorrect: false },
                     { name: "52", isCorrect: false },
                   ],
               }
            ]
         },{
            "name": "Vaddisznócsalád tartja rettegésben Bélaliget lakosságát.  A település szélső utcáiban alkonyatkor rendre megjelenik egy anyakoca három csíkos malacával és az ingatlanok elé a már napokkal korábban kihelyezett zöldhulladékos zsákokat fosztogatják. A zsákokat kirágják, azok tartalmát szétszórják. A falevelek között diót, tobozt, hullott almát, káposztacsutkát egyéb ehető termést keresnek. A helyi vadásztársaság elnöke elmondja, hogy a kicsinyeit féltő vadállat rendkívül veszélyes, ezért óva int mindenkit a koca megközelítésétől. A megoldást abban látná, ha az ingatlantulajdonosok csak a gyűjtés napján helyeznék ki a zsákokat az utcára.",
            "questions": [
               {
                  "question": {
                     "name": "Hány vaddisznó garázdálkodik Bélaligeten?",
                  },
                  options: [
                     { name: "négy", isCorrect: true },
                     { name: "kettő", isCorrect: false },
                     { name: "három", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Mit szeretnének megenni a vaddisznók?",
                  },
                  options: [
                     { name: "hullott almát", isCorrect: true },
                     { name: "falevelet", isCorrect: false },
                     { name: "fenyőtüskét", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Miért veszélyes az anyakoca?",
                  },
                  options: [
                     { name: "félti a területét", isCorrect: false },
                     { name: "félti a kicsinyeit", isCorrect: true },
                     { name: "félti az ennivalóját", isCorrect: false },
                   ],
               }
            ]
         },{
            "name": "38 éves 186 cm magas, szőke, zöld szemű, sovány testalkatú, biztos egzisztenciával rendelkező vegyésztechnikus vagyok. Hobbim a tandemugrás, a kígyótenyésztés és a vadvízi evezés. Házasság céljából keresem azt a hasonló érdeklődésű teltkarcsú, kékszemű, életvidám konyhatündért, aki gondoskodna otthonunk melegéről és jó szívvel gondozná 25 kilós óriásteknősömet is.",
            "questions": [
               {
                  "question": {
                     "name": "Mit keres a hirdető?",
                  },
                  options: [
                     { name: "szakácsnőt", isCorrect: false },
                     { name: "ejtőernyőst", isCorrect: false },
                     { name: "feleséget", isCorrect: true },
                   ],
               },{
                  "question": {
                     "name": "Milyen állata van a hirdetőnek?",
                  },
                  options: [
                     { name: "hal", isCorrect: false },
                     { name: "teknős", isCorrect: true },
                     { name: "béka", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Hány éves hölgy ismeretségét keresi?",
                  },
                  options: [
                     { name: "25", isCorrect: false },
                     { name: "38", isCorrect: false },
                     { name: "nem derül ki", isCorrect: true },
                   ],
               }
            ]
         },{
            "name": "Könnyű, finom vacsora a túrógombóc. Negyed kg félzsíros túrót villával összetörünk. Hozzáadunk 6 csapott evőkanál búzadarát, kevés sót és két tojás sárgáját, majd összekeverjük. A tojások fehérjét kemény habbá verjük és óvatosan a masszába forgatjuk.\nVizes kézzel gombócokat formálunk. A gombócokat lobogó sós vízben kifőzzük, majd pirított zsemlemorzsába forgatjuk. Porcukorral tejföllel vagy baracklekvárral tálaljuk. \nA túrógombócot mindig tálalás előtt főzzük ki. Jó étvágyat!",
            "questions": [
               {
                  "question": {
                     "name": "Hogyan adagoljuk a búzadarát?",
                  },
                  options: [
                     { name: "evőkanállal", isCorrect: false },
                     { name: "merőkanállal", isCorrect: false },
                     { name: "csapott evőkanállal", isCorrect: true },
                   ],
               },{
                  "question": {
                     "name": "Mennyi tojás kell a túrógombócba?",
                  },
                  options: [
                     { name: "1", isCorrect: false },
                     { name: "2", isCorrect: true },
                     { name: "3", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Miben főzzük ki a gombócokat?",
                  },
                  options: [
                     { name: "sós vízben", isCorrect: true },
                     { name: "cukros vízben", isCorrect: false },
                     { name: "tejben", isCorrect: false },
                   ],
               }
            ]
         },{
            "name": "Az unokám osztályába 13 kisfiú és 12 kislány jár. Mivel a lányok sokat fecsegnek, a fiúk pedig gyakran verekednek a tanítónő úgy ülteti a gyerekeket, hogy minden fiúnak egy lány legyen a padtársa. A szemüvegesek kerülnek előre, a magasabb gyerekek hátra. Az osztályterem minden padjába jut gyerek.",
            "questions": [
               {
                  "question": {
                     "name": "Hány tanuló jár az osztályba?",
                  },
                  options: [
                     { name: "24", isCorrect: false },
                     { name: "25", isCorrect: true },
                     { name: "27", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Kinek nem jut padtárs?",
                  },
                  options: [
                     { name: "1 lánynak", isCorrect: false },
                     { name: "1 fiúnak", isCorrect: true },
                     { name: "2 fiúnak", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Hány pad van a teremben?",
                  },
                  options: [
                     { name: "12", isCorrect: false },
                     { name: "13", isCorrect: true },
                     { name: "22", isCorrect: false },
                   ],
               }
            ]
         },{
            "name": "Klárika leszaladt a háztartási boltba mosogatószivacsért. Régen járt itt, elámult, hogy mekkora a választék. Mosogatószivacsból is volt vagy hatféle. Legjobban az a nagy zöld tetszett neki, amiből három volt összecsomagolva, de szóba jöhetett a 12 darabos sárga kézkímélős is. Gondolta, mielőtt eldönti, hogy melyiket vegye meg, egy kicsit szétnéz a boltban. Végül vett egy kék felmosóvödröt, egy hosszúnyelű szemeteslapátot, két csomag műanyag ruhacsipeszt és egy üveg körömlakklemosót. A pénztáros nem tudott visszaadni húszezresből, de szerencsére volt nála bankkártya.",
            "questions": [
               {
                  "question": {
                     "name": "Hol vásárolt Klárika?",
                  },
                  options: [
                     { name: "illatszer boltban", isCorrect: false },
                     { name: "háztartási boltban", isCorrect: true },
                     { name: "100 forintos boltban", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Milyen mosogatószivacsot vett Klárika?",
                  },
                  options: [
                     { name: "nem vett mosogatószivacsot", isCorrect: true },
                     { name: "nagy zöldet", isCorrect: false },
                     { name: "sárga kézkímélőset", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Miért fizetett bankkártyával?",
                  },
                  options: [
                     { name: "mindig azzal fizet", isCorrect: false },
                     { name: "nem volt nála pénz", isCorrect: false },
                     { name: "nem tudtak visszaadni húszezresből", isCorrect: true },
                   ],
               }
            ]
         },{
            "name": "13. alkalommal rendezik meg Nagyputtonyon a híres Dödölle Fesztivált. Éhes Tóbiás, a rendezvény fővédnöke elmondja, hogy a dödöllét sokféle képen lehet elkészíteni, de abban mindenki egyetért, hogy a krumplit összedolgozni a liszttel bizony jó erőnlétet és kitartást igényel. A fesztiválon a látogatók megismerkedhetnek a dödölle történetével, elsajátíthatják elkészítését. Megkóstolhatják a különböző tájegységek dödölléit. Lesz dödöllefőző és dödölleevő verseny is. A jó hangulatról néptáncbemutató, asszonykórus és citerazenekar gondoskodik. A kicsiket kézműves foglalkozások várják, a bátrabbak a kertben tyúkfogó versenyen tehetik próbára ügyességüket. Este táncházzal zárul az esemény.",
            "questions": [
               {
                  "question": {
                     "name": "Hol lesz a Dödölle Fesztivál?",
                  },
                  options: [
                     { name: "Nagyputtonyon", isCorrect: true },
                     { name: "Kisputtonyon", isCorrect: false },
                     { name: "Puttonyoson", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Milyen zöldségből készül a dödölle?",
                  },
                  options: [
                     { name: "répából", isCorrect: false },
                     { name: "krumpliból", isCorrect: true },
                     { name: "káposztából", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Milyen versenyt rendeznek a kertben?",
                  },
                  options: [
                     { name: "dödölleevő versenyt", isCorrect: false },
                     { name: "néptáncversenyt", isCorrect: false },
                     { name: "tyúkfogó versenyt", isCorrect: true },
                   ],
               }
            ]
         },{
            "name": "Magyar sikerekkel zárult a Lépesréti Nemzetközi Horgászverseny 2. fordulója, ahol csapatok mérték össze tudásukat. A Bajusz Béla vezette Sármányosi Kuttyogatók kifogták a tó 72 kilós rekordharcsáját. A Pitykeszőlősi Pontyfogók a nap folyamán összesen 126 kg pontyot fogtak. Az Alsóvárosi Csukacsata versenyzői összehangolt csapatmunkával, másfél óra kemény fárasztás után a nádasból kifogták a halőr tavaly nyáron elveszett fél pár gumicsizmáját. A szerencsés halőr különdíjként egy hordó sört ajánlott fel a becsületes megtalálóknak, amit a sporttársak a halőrrel együtt még aznap este maradéktalanul elfogyasztottak.",
            "questions": [
               {
                  "question": {
                     "name": "Hol lakik Bajusz Béla?",
                  },
                  options: [
                     { name: "Alsóvárosban", isCorrect: false },
                     { name: "Sármányoson", isCorrect: true },
                     { name: "Pitykeszőlősön", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Mit fogtak a nádasban?",
                  },
                  options: [
                     { name: "harcsát", isCorrect: false },
                     { name: "pontyot", isCorrect: false },
                     { name: "gumicsizmát", isCorrect: true },
                   ],
               },{
                  "question": {
                     "name": "Mit adott a halőr jutalmul?",
                  },
                  options: [
                     { name: "oklevelet", isCorrect: false },
                     { name: "gumicsizmát", isCorrect: false },
                     { name: "hordó sört", isCorrect: true },
                   ],
               }
            ]
         },{
            "name": "Lelepleződött a csaló polgármester. Szeptember 23-ára virradóan 8 kicsi és 5 nagy gabonakört találtak Kékkő határában. A települést azonnal ellepték a kíváncsiskodók, az ufológusok, és a nemzetközi média képviselői. A tudományos vizsgálódás nem vezetett eredményre, de a megkárosított gazdák feljelentést tettek ismeretlen tettes ellen. A megyei rendőrkapitányság nyomozói hamar kiderítették, hogy a köröket a település polgármestere készítette az éj leple alatt, abban a reményben, hogy ezzel fellendítheti a 488 lelket számláló zsákfalu idegenforgalmát egyben saját pálinkafőzdéjének forgalmát is. A bűnjeleket – a lefűrészelt síléceket megtalálták a polgármester garázsában.",
            "questions": [
               {
                  "question": {
                     "name": "Hány gabonakör volt Kékkőn?",
                  },
                  options: [
                     { name: "13", isCorrect: true },
                     { name: "5", isCorrect: false },
                     { name: "8", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Ki készítette a gabonaköröket?",
                  },
                  options: [
                     { name: "egy helyi lakos", isCorrect: false },
                     { name: "a polgármester", isCorrect: true },
                     { name: "egy UFO szakértő", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Mit főzött a polgármester?",
                  },
                  options: [
                     { name: "befőttet", isCorrect: false },
                     { name: "lekvárt", isCorrect: false },
                     { name: "pálinkát", isCorrect: true },
                   ],
               }
            ]
         },{
            "name": "Toronydarut fogadott örökbe N. Géza gombkötő kisiparos. A vállalkozó elmondta, hogy gyerekkori álma valósult meg ezzel, – már kisfiú korában szeretett volna egy saját toronydarut. Bár jövedelmi viszonyai lehetővé tennék a daru megvásárlását, de saját vállalkozásában kevésbé tudná kihasználni azt. Ezért döntött feleségével egyetértésben az örökbefogadás mellett. N. Géza fizeti ezentúl a daru éves karbantartását, az esetleges javításokat és a biztonsági ellenőrzés költségeit. A Magasépítő Vállalat „Muki 2” névre hallgató toronydaruját már rozsdátlanítottak és N. Gézáné ízlésének megfelelően fűzöldre festették. A boldog örökbefogadók rendszeresen látogatják a gépet.",
            "questions": [
               {
                  "question": {
                     "name": "Miért nem vette meg N. Géza a toronydarut?",
                  },
                  options: [
                     { name: "nem volt rá elég pénze", isCorrect: false },
                     { name: "nem fér be az udvarára", isCorrect: false },
                     { name: "nincs rá szüksége a munkájához", isCorrect: true },
                   ],
               },{
                  "question": {
                     "name": "Hogy hívják a gépet?",
                  },
                  options: [
                     { name: "Daru 2", isCorrect: false },
                     { name: "Torony 2", isCorrect: false },
                     { name: "Muki 2", isCorrect: true },
                   ],
               },{
                  "question": {
                     "name": "Miért festették fűzöldre a toronydarut?",
                  },
                  options: [
                     { name: "ez a szín N. Gézáné kedvenc színe", isCorrect: true },
                     { name: "hogy jól látszódjon messziről", isCorrect: false },
                     { name: "olcsó volt a zöld festék", isCorrect: false },
                   ],
               }
            ]
         },{
            "name": "Szoboravató ünnepséget tartottak a Kockás utcai Virág Béla Általános Iskola aulájában. A kerület híres sakkbajnokának egyben az iskola névadójának szobrát készültek leleplezni. Az ünnepségen részt vett az iskola tanári kara, 654 tanulója, a helyi újság riportere és a kerületi tévé is képviseltette magát. Beszédet mondott a polgármester, szerepelt az énekkar, verset mondtak a színjátszókörösök és az iskola fúvószenekara eljátszotta Virág Béla kedvenc indulóját.\nA zene elhalkulta után az iskola igazgatója és a sakkszakkör vezetője ünnepélyesen lehúzta a leplet a szoborról. Ekkor a csendet mindenki megdöbbenésére riadt nyávogás törte meg. Kiderült, hogy Klementina, a gondnok macskája a szobor és az éj leple alatt itt adott életet 5 gyönyörű, egészséges kiscicájának.",
            "questions": [
               {
                  "question": {
                     "name": "Ki volt Virág Béla?",
                  },
                  options: [
                     { name: "bokszbajnok", isCorrect: false },
                     { name: "sakknagymester", isCorrect: true },
                     { name: "polgármester", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Mit játszott a fúvószenekar?",
                  },
                  options: [
                     { name: "keringőt", isCorrect: false },
                     { name: "polkát", isCorrect: false },
                     { name: "indulót", isCorrect: true },
                   ],
               },{
                  "question": {
                     "name": "Hogy hívták a gondnok macskáját?",
                  },
                  options: [
                     { name: "Klementina", isCorrect: true },
                     { name: "Valentina", isCorrect: false },
                     { name: "Karolina", isCorrect: false },
                   ],
               }
            ]
         },{
            "name": "Kovácsné a hatodikról vásárolni indult. A bejárati ajtón kilépve megdöbbenve látta, hogy eltűnt a lábtörlője. Akkor döbbent meg még jobban, amikor a lift felé tartva felfedezte kedvenc juharmintás lábtörlőjét dr. Csöbör Csaba ajtaja előtt. „Ellopta volna?” – morfondírozott. „Á, egy nyugalmazott rendőrkapitány nem tesz ilyet! Talán a „Takács ikrek voltak?” Azok a rosszcsont kisfiúk a múltkor is békát dugtak Kázmér szomszéd levélszekrényébe. Ekkor kinyílt a lift ajtaja és kilépett rajta a szemben lakó kedves fiatalasszony, Terike. „Ő Marci, az új szomszéd” – mondta mosolyogva és letette a kezében tartott kiskutyát. „Vasárnap kaptam a nővéremtől”. Az aprócska kölyökkutya nekiiramodott a folyosón, felkapta a juharmintás lábtörlőt és már vitte is a lépcsőház végére. „Ne haragudjon drága Kovács néni, mindjárt visszahozom. Ezt a kis csibészt pedig beadom a kutyaiskolába ha egy kicsit nagyobb lesz, de még annyira kicsi, hogy a kutyaoviba se veszik be.” Kovácsné jót nevetett és belépett a liftbe.",
            "questions": [
               {
                  "question": {
                     "name": "Hova ment Kovácsné?",
                  },
                  options: [
                     { name: "közértbe", isCorrect: true },
                     { name: "kutyaiskolába", isCorrect: false },
                     { name: "a hatodikra", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Ki vitte el a lábtörlőt?",
                  },
                  options: [
                     { name: "dr. Csöbör Csaba", isCorrect: false },
                     { name: "Kázmér szomszéd", isCorrect: false },
                     { name: "Marci, a kiskutya", isCorrect: true },
                   ],
               },{
                  "question": {
                     "name": "Mit csináltak a Takács gyerekek?",
                  },
                  options: [
                     { name: "becsöngettek éjszaka a lakásokba", isCorrect: false },
                     { name: "elvitték a lábtörlőt", isCorrect: false },
                     { name: "békát dugtak a levélszekrénybe", isCorrect: true },
                   ],
               }
            ]
         },{
            "name": "A Hegyen-völgyön Turistaegyesület Nyugdíjas Szakosztálya október 16-ára „sárga túrát” szervez. Az út végig sárga turistajelzésen vezet. Könnyű túra. Táv: eltévedés nélkül 30,4 km, szintkülönbség +1260 m, -840 m. Menetidő megállás nélkül 7 óra 28 perc. Utunk a sárga csíkon a Nagy csalánmezőn  és a Pocskondiás árkon át vezet, majd a sárga háromszöget követve felkapaszkodunk a Szivatos nyergen egészen az Akasztófa tetőig. Rövid kitérővel a sárga kereszten meglátogatjuk a Remetetemplom romját, majd a sárga körön a Rogyásszikla alatt elhaladva érintjük a Békaforrást, majd leereszkedünk a Guggolós barlangig. Látcsövét, nordic walking botját és vérnyomáscsökkentőjét mindenki hozza magával.",
            "questions": [
               {
                  "question": {
                     "name": " Hova vezet a sárga kereszt jelzés?",
                  },
                  options: [
                     { name: "forráshoz", isCorrect: false },
                     { name: "templomromhoz", isCorrect: true },
                     { name: "hegytetőre", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Hol tölthetik meg kulacsaikat a túrázók?",
                  },
                  options: [
                     { name: "a Békaforrásnál", isCorrect: true },
                     { name: "a Pocskondiás árokban", isCorrect: false },
                     { name: "a Guggolós barlangban", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Hol ér véget a túra?",
                  },
                  options: [
                     { name: "a Guggolós barlangnál", isCorrect: true },
                     { name: "a Békaforrásnál", isCorrect: false },
                     { name: "a Nagy csalánmezőn", isCorrect: false },
                   ],
               }
            ]
         },{
            "name": "Az Andrássy út végén van a Hősök tere. Jobb oldalán a Műcsarnok, bal oldalt a Szépművészeti múzeum van. Középen a Millenniumi emlékmű. A Műcsarnok mellett a Műjégpálya található.  A Vajdahunyad vár a Városligetben van, és a Fővárosi Nagycirkusz is. Aki szeretne fürödni, a Széchenyi fürdőbe mehet.",
            "questions": [
               {
                  "question": {
                     "name": "Mi van a Hősök tere közepén?",
                  },
                  options: [
                     { name: "Millenniumi emlékmű", isCorrect: true },
                     { name: "Műcsarnok", isCorrect: false },
                     { name: "Szépművészeti múzeum", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Milyen vár van a Városligetben?",
                  },
                  options: [
                     { name: "Vajdahunyad vár", isCorrect: true },
                     { name: "Ligeti vár", isCorrect: false },
                     { name: "Széchenyi vár", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Hol lehet korcsolyázni?",
                  },
                  options: [
                     { name: "Műjégpályán", isCorrect: true },
                     { name: "Műcsarnokban", isCorrect: false },
                     { name: "Széchenyi fürdőben", isCorrect: false },
                   ],
               }
            ]
         },{
            "name": "Blanka 83 éves. Minden hónap utolsó szombatján a volt osztálytársai nála találkoznak egy kis beszélgetésre. Az almáspite mellé mindig kávét is kínál. Egyik alkalommal kétszer olyan erősre főzte, mint szokta, ezért hajnali kettőig nem tudott elaludni. A barátnők is panaszkodtak másnap telefonon, hogy a fél éjszakát álmatlanul töltötték.",
            "questions": [
               {
                  "question": {
                     "name": "Hány évesek a barátnők?",
                  },
                  options: [
                     { name: "38 év körüliek", isCorrect: false },
                     { name: "73 év körüliek", isCorrect: false },
                     { name: "83 év körüliek", isCorrect: true },
                   ],
               },{
                  "question": {
                     "name": "Mit esznek a kávéhoz?",
                  },
                  options: [
                     { name: "almásrétest", isCorrect: false },
                     { name: "almáspitét", isCorrect: true },
                     { name: "meggyespitét", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Mikor aludt el Blanka?",
                  },
                  options: [
                     { name: "éjfélkor", isCorrect: false },
                     { name: "hajnali 2-kor", isCorrect: true },
                     { name: "hajnali 3-kor", isCorrect: false },
                   ],
               }
            ]
         },{
            "name": "A parkolóban álló tejautomata, amiből a finom, hideg, friss tehéntejet szoktam venni, sok bosszúságot okozott a nyáron. Háromszor is előfordult, hogy hiába dobtam be a 220 forintot, nem adott tejet, hanem kiírta, hogy “sajnos elfogyott”, de a pénzt nem adta vissza. A bolti tejből pedig nem lehetett olyan jó aludttejet csinálni.",
            "questions": [
               {
                  "question": {
                     "name": "Mennyi pénzem veszett el?",
                  },
                  options: [
                     { name: "660 Ft", isCorrect: true },
                     { name: "220 Ft", isCorrect: false },
                     { name: "440 Ft", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Milyen tej van az automatában, amikor működik?",
                  },
                  options: [
                     { name: "birkatej", isCorrect: false },
                     { name: "tehéntej", isCorrect: true },
                     { name: "kecsketej", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Mit csináltam belőle?",
                  },
                  options: [
                     { name: "aludttejet", isCorrect: true },
                     { name: "kefirt", isCorrect: false },
                     { name: "túrót", isCorrect: false },
                   ],
               }
            ]
         },{
            "name": "A gyerekek nagyon szeretnek  hintázni és homokozni. Minden hétköznap délelőtt elmennek az anyukájukkal egy-két órára a közeli játszótérre. Visznek magukkal homokozójátékot, innivalót és gyümölcsöt. Sajnos csak két hinta van, ezért nem tudnak egyszerre hintázni, mert pont kétszer annyian vannak. Nem szoktak veszekedni, beosztják, ki mikor hintázhat.",
            "questions": [
               {
                  "question": {
                     "name": "Kivel mennek a játszótérre?",
                  },
                  options: [
                     { name: "anyukájukkal", isCorrect: true },
                     { name: "nagymamájukkal", isCorrect: false },
                     { name: "apukájukkal", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Mit szoktak magukkal vinni?",
                  },
                  options: [
                     { name: "homokozójátékot", isCorrect: true },
                     { name: "labdát", isCorrect: false },
                     { name: "biciklit", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Hány gyerek megy a játszótérre?",
                  },
                  options: [
                     { name: "4", isCorrect: true },
                     { name: "2", isCorrect: false },
                     { name: "6", isCorrect: false },
                   ],
               }
            ]
         },{
            "name": "Két barátnő elhatározta, hogy vasárnap délután moziba mennek. Egyik már pénteken elment, hogy megvegye a jegyeket. Szerettek a hatodik sor szélén ülni, ezért volt fontos időben ott lenni a pénztárnál. Szerencsére minden kívánságukat sikerült kielégíteni, a jegyek oda szóltak, ahova akarták. Nagyon jól szórakoztak.",
            "questions": [
               {
                  "question": {
                     "name": "Hova szóltak a jegyek?",
                  },
                  options: [
                     { name: "a hatodik sor szélére", isCorrect: true },
                     { name: "a hatodik sor közepére", isCorrect: false },
                     { name: "az első sorba", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Mikor vette meg a jegyeket?",
                  },
                  options: [
                     { name: "pénteken", isCorrect: true },
                     { name: "szombaton", isCorrect: false },
                     { name: "vasárnap", isCorrect: false },
                   ],
               },{
                  "question": {
                     "name": "Mikor szórakoztak jól?",
                  },
                  options: [
                     { name: "vasárnap délután", isCorrect: true },
                     { name: "vasárnap délelőtt", isCorrect: false },
                     { name: "vasárnap este", isCorrect: false },
                   ],
               }
            ]
         }
      ]
   };
   

   useEffect(() => {
      if (currentSlide == 0) {
         setAppTitle("");
         //setIsAnswerCorrect(false);
         setHideSelector(false);
      } else {
         var apptitle_ = "Válaszolj a kérdésekre!";
         setAppTitle(apptitle_);
         setHideSelector(true);
         if (!isInstructionSoundPlayed) {
            setIsInstructionSoundPlayed(true);
            glob.playInstructionSound(apptitle_);
         }
      }

      return () => {
         setAppTitle("");
      };
   }, [currentSlide]);

   useEffect(() => {
      setIsAnswerCorrect(false);
      setTaskItem(task_config["items"][paramSubtaskId ? paramSubtaskId : 0]);
   }, [questionIndex]);

   useEffect(() => {
      setIsAnswerCorrect(false);
      setQuestionIndex(0);
      myslider.current.slickGoTo(0);
      setAppTitle("");
      setTaskItem(task_config["items"][paramSubtaskId ? paramSubtaskId : 0]);
   }, [paramSubtaskId]);


   var sliderSettings = {
      dots: false,
      arrows: false,
      infinite: false,
      speed: 500,
      centerMode: true,
      slidesToShow: 1,
      draggable: false,
      slidesToScroll: 1,
      afterChange: current => setCurrentSlide(current),
      responsive: [
      {
         breakpoint: 680,
         settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
         }
      }
      ]
   };

   
   useEffect(() => {
      $.each(task_config["items"], (index, item) => {
        $.each(task_config["items"][index]["questions"], (index2, item) => {
          glob.shuffleArray(task_config["items"][index]["questions"][index2]["options"]);
        });
      });
      
      setTaskItem(task_config["items"][paramSubtaskId ? paramSubtaskId : 0]);
   }, []);
   
   const goNextSlide = () => {
      if (taskItem["questions"][questionIndex + 1]) {
         setQuestionIndex(questionIndex+1)
      } else {
         var nextTaskItem = false;
         nextTaskItem = task_config["items"][paramSubtaskId + 1];
         if ((!nextTaskItem && isAnswerCorrect)) {
            setGameEnded(true);
            return false;
         } else {
            // reset question
            setQuestionIndex(0);

            // go next subtask
            var url = new URL(window.location.href);
            var new_val = parseInt(paramSubtaskId + 1);
            console.log("new_val: ", new_val);
            var new_url = glob.removeLastDirectoryPartOf(location.pathname) + "/" + new_val;
            history.push(new_url);
         }
      }
      $(".btn-question-option").removeClass("active");
   }
   const openQuestions = () => {
      setAppTitle("Válaszolj a kérdésekre!");
      myslider.current.slickGoTo(1);
   }

   const answerQuestion = (elemId, isCorrect) => {
      if (isAnswerCorrect) { return false }
      glob.playGameSound(isCorrect ? "correct":"incorrect");
      if (isCorrect) {
         setIsAnswerCorrect(true);
      }
      $("#" + elemId).addClass("active");
      if (!isCorrect) {
         setTimeout(function () {
            $("#" + elemId).removeClass("active");
         }, 2000);   
      }
   }
   
   const resetGame = () => {
      setIsAnswerCorrect(false);
      setGameEnded(false);
      setQuestionIndex(0);
      setAppTitle("");
      var new_url = glob.removeLastDirectoryPartOf(location.pathname) + "/" + 0;
      history.push(new_url);
    }
    var nextTaskItem = false;
    nextTaskItem = task_config["items"][paramSubtaskId + 1];
  
    if ((!nextTaskItem && isAnswerCorrect && gameEnded)) {
      return <GameEndScreen className="nomargintop" resetGame={ resetGame } />
    }
   return (
      <div className="task-wrapper task-wrapper-OlvasdFelHangosanASzoveget">
         <Slider ref={myslider} {...sliderSettings} className={`SliderFigyeldMegJolAKepet`}>
            <div className="wrapper-main_image p-text-center">
               <div className="p-grid p-align-center grid-main">
                  <div className="p-col-2 p-lg-3"></div>
                  <div className="p-col-8 p-lg-6">
                     <p>{taskItem && taskItem["name"]}</p>
                  </div>
                  <div className="p-col-2 p-lg-3 sy-position-relative">
                     <div className="wrapper-btn-question-mark">
                        <Button label={<img src={glob.icons["question"]} width="50" />} className="btn-question-mark p-button-rounded p-button-text" onClick={() => openQuestions()} />
                        <div className="bg-rectangle cyan"></div>
                     </div>
                  </div>
               </div>
            </div>
            
            <div className="question-item">
               <div className="p-grid p-align-center sy-position-relative">
                  <div className="p-col-3">
                     <div className="wrapper-btn-image">
                        <Button label={<img src={glob.icons["read"]} width="50" />} className="btn-image p-button-rounded p-button-text p-button-plain" onClick={() => myslider.current.slickGoTo(0)} />
                        <div className="bg-rectangle yellow"></div>
                     </div>
                  </div>
                  <div className="p-col-6">
                     <h2 className="p-m-0">{taskItem && taskItem["questions"][questionIndex]["question"]["name"]}</h2>
                     {taskItem && taskItem["questions"][questionIndex]["options"].map(function (option, index2) {
                        return (
                           <div className="question-option">
                              <Button id={"btn-question-option-"+index2} label={option.name} className={`button-answer btn-question-option p-button-outlined p-button-plain ${option.isCorrect ? ("btn-answer-correct") : ("btn-answer-incorrect")} `} onClick={() => answerQuestion("btn-question-option-"+index2, option.isCorrect)} />
                           </div>
                        )
                     })}
                  </div>
                  <div className="p-col-3">
                  </div>
               </div>
            </div>
         </Slider>
         {(isAnswerCorrect && currentSlide === 1) && (
            <>
               <div className="wrapper-btn-next-task">
                  <Button label={<img src={ glob.icons["nextExerciseBig"] } width="50" />} className="btn-next-task p-button-rounded p-button-text" onClick={() => goNextSlide()} />
                  <div className="bg-of-btn-next-task"></div>
               </div>
            </>
         )}
      </div>
   );
};