import React from 'react';
import $ from 'jquery';
import QuestionItem from '../../QuestionItem';
import glob from '../../glob';

export default function NevezdMegTobbesszam(props, { ...rest }) {
   

   var questions = [];

   $.each(Array.from({length: 51}, (_, i) => ((i+1) * 2)-1), (index, item) => {
      var new_item = {
         "options": [
            {
               "name": "Ez egy ...",
               "image": require('../../../images/tasks/nevezd_meg_tobbesszam/'+item+'.jpeg').default,
               "onClickSound": require("../../../sounds/voice/nevezd_meg_tobbesszam/ez_egy.mp3").default
            },
            {
               "name": "Ezek ...",
               "image": require('../../../images/tasks/nevezd_meg_tobbesszam/' + (item + 1) + '.jpeg').default,
               "onClickSound": require("../../../sounds/voice/nevezd_meg_tobbesszam/ezek.mp3").default
            }
         ]
      };
      questions.push(new_item);
   });
   
   glob.shuffleArray(questions);
   var task_config = {
      "name": "NevezdMegTobbesszam",
      "always_correct": true
   };
   
   task_config["items"] = questions; //.slice(1, 3)

   return (
      <div className="task-wrapper task-wrapper-NevezdMegTobbesszam">
         <QuestionItem type="valaszok_kozepen" taskConfig={task_config} />
      </div>
   );
};
