import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import $ from 'jquery';

import glob from '../glob';
import { useAppContext } from '../appContext';
import GameEndScreen from '../GameEndScreen';


export default function FindAllInTable(props, { ...rest }) {
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [nevezdMegText, setNevezdMegText] = useState("");
  const { setAppTitle } = useAppContext();

  var task_config = props.taskConfig;
  var taskItem = task_config["items"][questionIndex];

  var nextTaskItem = false;
  nextTaskItem = task_config["items"][questionIndex + 1];
  
  useEffect(() => {
    if (task_config.apptitle == "letter") {
      setAppTitle(<span>Keresd meg az összes <strong>{taskItem["question"]["name"]}</strong> betűt!</span>);
      glob.playSound(taskItem["question"]["sound"]);
    } else {
      setAppTitle(<span>Keresd meg az összes <strong>{taskItem["question"]["name"]}</strong> szót!</span>);
      
      glob.playSounds([
        require('../../sounds/voice/keresd_meg_az_osszes_szot/keresd_meg_az_osszes_szot_1.mp3').default,
        taskItem["question"]["sound"],
        require('../../sounds/voice/keresd_meg_az_osszes_szot/keresd_meg_az_osszes_szot_2.mp3').default,
      ], 0);
    }

    return () => {
      setAppTitle("");
      glob.muteSound();
    };
  }, [questionIndex]);

  const goNextSlide = () => {
    setQuestionIndex(questionIndex + 1);
    $(".btn-question-option").removeClass("active");
    setIsAnswerCorrect(false)
  }

  const answerQuestion = (elemId, isCorrect, sound = null) => {
    if (isAnswerCorrect) { return false }
    
      glob.playGameSound(isCorrect?"correct":"incorrect");  
      $("#" + elemId).addClass("active");  
      
      if (!isCorrect) {
        setTimeout(function () {
          $("#" + elemId).removeClass("active");
        }, 2000);
      }
      
    
      if (!isCorrect == false) {
        setTimeout(function () {
          //$("#" + elemId).removeClass("active");
        }, 2000);
    }
    var selectable_items = $('.btn-answer-correct').length;
    var good_answers = $('.btn-answer-correct.active').length;
    var bad_answers = $('.btn-answer-incorrect.active').length;
    var questionCompleted = selectable_items == good_answers;
    
    if (questionCompleted) {
      setIsAnswerCorrect(true);
    }
  }

  var col_count = task_config.apptitle == "letter" ? 10 : 5;
  
  const resetGame = () => {
    glob.shuffleArray(task_config["items"], true);

    setIsAnswerCorrect(false);
    setQuestionIndex(0);
    setAppTitle("");
  }
  if ((!nextTaskItem && isAnswerCorrect)) {
    return <GameEndScreen resetGame={ resetGame } />
  }
  return (
    <>
      <div className="wrapper-FindAllInTable">
        <div className="p-grid">
          <div className="p-col-12">
              <table className="FindAllInTable">
                {Array.from({ length: 5 }, (_, i) => i).map(function (i, index) {
                  return (
                    <>
                      <tr>
                        {Array.from({ length: col_count}, (_, j) => j).map(function (j, index) {
                          return (
                            <>
                              <td id={"btn-question-option-"+((i*col_count)+j)} className={`button-answer btn-question-option p-button-outlined p-button-plain ${taskItem["options"][(i*col_count)+j].isCorrect ? ("btn-answer-correct") : ("btn-answer-incorrect")} `} onClick={() => answerQuestion("btn-question-option-"+((i*col_count)+j), taskItem["options"][(i*col_count)+j].isCorrect)}>{taskItem["options"][(i*col_count)+j]["name"]}</td>
                            </>
                          )
                        })}
                      </tr>
                    </>
                  )
                })}
            </table>
              
            </div>
        </div>
        <div className="p-col-3">
            {(isAnswerCorrect && nextTaskItem) && (
              <>
                  <div className="wrapper-btn-next-task">
                    <Button label={<img src={ glob.icons["nextExerciseBig"] } width="50" />} className="btn-next-task p-button-rounded p-button-text" onClick={() => goNextSlide()} />
                    <div className="bg-of-btn-next-task"></div>
                  </div>
              </>
            )}
        </div>
      </div>
    </>
  );
};