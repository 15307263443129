import React from 'react';
import $ from 'jquery';
import FrameImageGallery from '../FrameImageGallery';

export default function MiAFoglalkozasa(props, { ...rest }) {
   
   var task_config = {
      "items": []
   };
   
   $.each(Array.from({ length: 25 }, (_, i) => (i + 1)), (index, item) => {
      if (item != 24) {
         var new_item = (
            <img src={require('../../../images/tasks/mi_a_foglalkozasa/'+item+'.jpeg').default} alt="gallery image" />
         );
         task_config["items"].push(new_item);   
      }
   });

   return (
      <div className="task-wrapper task-wrapper-MiAFoglalkozasa">
         <FrameImageGallery items={task_config["items"]} customArrows={true} />
      </div>
   );
};