import React, { useEffect, useState } from 'react';
import $ from 'jquery';

import { Button } from 'primereact/button';

import glob from '../glob';
import GameEndScreen from '../GameEndScreen';
import { useAppContext } from '../appContext';

export default function AnimationAnswer(props, { ...rest }) {
   const [items, setItems] = useState([]);
   const [questionIndex, setQuestionIndex] = useState(0);
   const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
   const [nextTaskItem, setNextTaskItem] = useState(false);
   const [userAnswerIndexes, setUserAnswerIndexes] = useState([]);
   const [answerClass, setAnswerClass] = useState("");
   
   const [gameStarted, setGameStarted] = useState(false);
  
   const { gameInstructionIsActive } = useAppContext();

   var last_elem = $(".words-destination");

   
   var kerdesek = props.kerdesek;
   var typeQuiz = props.typeQuiz;

   useEffect(() => {
      if (gameInstructionIsActive && gameStarted && !typeQuiz) {
         setTimeout(function () {
            glob.playSound(task_config["items"][questionIndex]["question"]["sound"]);
         }, 3000);
      }
   }, [gameInstructionIsActive]);
   
   useEffect(() => {
      setItems(task_config["items"][questionIndex]["options"]);
      setNextTaskItem(task_config["items"][questionIndex + 1]);
      setAnswerClass("");
      setTimeout(function () {
         glob.playSound(task_config["items"][questionIndex]["question"]["sound"]);
      }, questionIndex == 0 ? 3000 : 0);
      setTimeout(function () {
         setGameStarted(true);
       }, 1000);
      last_elem = $(".words-destination");
   }, [questionIndex]);
   
   useEffect(() => {
      if (!typeQuiz) {
         var is_correct = true;
         $.each(items, (index, item) => {
            if (index != item["index"]) {
               is_correct = false;
            }
         });
         setIsAnswerCorrect(is_correct);
      }
   }, [items]);

   var task_config = {
      "items": []
   };


   if (typeQuiz) {
         $.each(kerdesek, (index, item) => {
         var opts = []
         $.each(item["options"], (index, option) => {
            opts.push({
               "name": option["name"],
               "index": index,
               "isCorrect": option["isCorrect"]
            })
         });
         glob.shuffleArray(opts, true);
         var new_item = {
            "question": {
               "name": (<>{item["first_part"]} <span className="insentence words-destination"></span> {item["second_part"]}</>),
            },
            "options": opts
         };
         task_config["items"].push(new_item);
      });
   } else {
      $.each(kerdesek, (index, item) => {
         var words = item[0].split(" ");
         var options_ = [];

         $.each(words, (index, word) => {
            options_.push({
               "name": word,
               "index": index
            });
         });
         glob.shuffleArray(options_);
         var new_item = {
            "question": {
               "name": item[0],
               "image": item[1],
               "sound": item[2]
            },
            "options": options_
         };
         task_config["items"].push(new_item);
      });
   }
   useEffect(() => {
      if (!typeQuiz) {
         if ($(".word-item").length == $(".word-item.animated").length) {
            var is_correct = true;
            
            $.each(userAnswerIndexes, (index, item) => {
               if (index != item) {
                  is_correct = false;
               }
               console.log("check index: ", index, " - ", item, " : ", index != item);
            });

            setIsAnswerCorrect(is_correct);
            
            
            if (!is_correct) {
               setTimeout(function () {
                  $(".word-item.animated").removeClass("animated").addClass("initial-state");
                  last_elem = $(".words-destination");
               }, 3000);   
            }
            
         }
      }
   }, [userAnswerIndexes]);

   
   const animateThis = (elemId) => {
      if (isAnswerCorrect) { return false }
      console.log("m1");
      var el = $("#word" + elemId);
      if (el.hasClass("animated")) {
         el.removeClass("animated").addClass("initial-state");
         if (userAnswerIndexes.length > 1) {
            var userAnswerIndexes_ = userAnswerIndexes;
            var index_ = parseInt(el.attr("id").replace("word", ""));
            userAnswerIndexes_ = glob.removeElementFromArray(userAnswerIndexes_, index_);
            setUserAnswerIndexes(userAnswerIndexes_);
            last_elem = $("#word" + userAnswerIndexes_[userAnswerIndexes_.length - 1]);
         } else {
            last_elem = $(".words-destination");
            setUserAnswerIndexes([]);
         }
         return false
      }

      el.removeClass("initial-state");
      console.log("variable last_elem: ", last_elem);
      //last_elem.css({ position: 'static' });
      var position = last_elem.offset();
      
      // Make it static
      el.css({ position: 'static' });
      
      // Get the static position
      var end = position;
      var left_ = end.left;
      if (!last_elem.hasClass("words-destination")) {
         var left_value = 50;
         if ($(window).width() < 576) {
            left_value = 10;
         }
         left_ += last_elem.width()+left_value
      }
      
      // Turn it back to absolute
      var value_to_add = -50;
      if (typeQuiz) {
         value_to_add = -10;
      }

      el.css({
         position: 'absolute',
      }).animate({ // Animate to the static position
         top: $(".words-destination").offset()["top"] + value_to_add,
         left: left_
      }, 300, 'linear');
      el.addClass("animated");
      last_elem = el;
      
      if (typeQuiz) {
         var is_correct = el.hasClass("word-answer-correct");
         setTimeout(function () {
            glob.playGameSound(is_correct ? "correct" : "incorrect");
            setAnswerClass(is_correct ? "correct" : "incorrect");
            
            if (is_correct) {
               setIsAnswerCorrect(true);
            } else {
               setTimeout(function () {
                  setAnswerClass("");
                  el.removeClass("animated").addClass("initial-state");
                  last_elem = $(".words-destination");
               }, 2000);
            }
         }, 300);
            
         
      }

      var indexes_ = userAnswerIndexes;
      indexes_.push(elemId);

      if ($(".word-item").length == $(".word-item.animated").length) {
         var is_correct = true;
         
         var is_correct_ = true;
         var new_indexes = indexes_;
         $.each(indexes_, (index, item) => {
            if (index != item) {
               is_correct = false;
            }
            console.log("check index: ", index, " - ", item, " : ", index == item);
            if (index != item || !is_correct_) {
               is_correct_ = false;
               $("#word" + index).addClass("incorrect-word");
               new_indexes = glob.removeElementFromArray(new_indexes, index);
               
            }
         });
         
         glob.playGameSound(is_correct ? "correct":"incorrect");
         
         setIsAnswerCorrect(is_correct);
         
         if (is_correct) {
            setAnswerClass("correct");
         } else {
            setTimeout(function () {
               
               $(".incorrect-word").removeClass("animated").removeClass("incorrect-word").addClass("initial-state");
               if (last_elem.length > 0) {
                  last_elem =  $("#word"+new_indexes[new_indexes.length-1]);
               } else {
                  last_elem = $(".words-destination");
               }
               //setUserAnswerIndexes([]);
               setAnswerClass("");
               console.log("variable new_indexes: ", new_indexes);
               setUserAnswerIndexes(new_indexes);
            }, 3000);   
         }
         
      } else {
         setUserAnswerIndexes(indexes_);
      }
      
   }
   
   const goNextSlide = () => {
      $(".word-item").removeClass("animated").addClass("initial-state");
      setQuestionIndex(questionIndex + 1);
      setIsAnswerCorrect(false);
      setUserAnswerIndexes([]);
   }
   
   const resetGame = () => {
      glob.shuffleArray(kerdesek, true);
      $(".word-item").removeClass("animated").addClass("initial-state");
      setIsAnswerCorrect(false);
      setQuestionIndex(0);
      setUserAnswerIndexes([]);
   }

   if (!nextTaskItem && isAnswerCorrect) {
      return <GameEndScreen resetGame={ resetGame } />
   }
   return (
      <div className={`wrapper-AnimationAnswer ${answerClass}`}>
         <div className="p-grid">
            {!typeQuiz && <div className="p-col-12 p-xl-3"></div>}
            <div className={`p-col-12  ${typeQuiz ? ("") : ("p-xl-6")} p-text-center sy-position-relative`}>
               {typeQuiz && <h5>{task_config["items"][questionIndex]["question"]["name"]}</h5>}
               {task_config["items"][questionIndex]["question"]["image"] && <img className="question-image" src={task_config["items"][questionIndex]["question"]["image"]} />}
               {task_config["items"][questionIndex]["question"].hasOwnProperty("sound") && <Button label={<img src={ glob.icons["speaker"] } width="40" />}  className="btn-volume-up p-button-rounded p-button-text p-button-plain p-icon-fs-2rem" onClick={() => glob.playSound(task_config["items"][questionIndex]["question"]["sound"]) } />}
            </div>
            {!typeQuiz && <div className="p-col-12 p-xl-3"></div>}
            {!typeQuiz && (
               <>
                  <div className="p-col-2 p-xl-3"></div>
                  <div className="p-col-8 p-xl-6 words-destination"></div>
                  <div className="p-col-2 p-xl-3"></div>
               </>
            )}
            
            <div className="p-col-12 p-text-center">
               {items.map(function (item, index) {
                  return (
                     <div id={"word"+item.index} className={`word-item initial-state ${(item.hasOwnProperty("isCorrect") && item.isCorrect) ? ("word-answer-correct") : ("word-answer-incorrect")} `}  onClick={(e)=>animateThis(item.index)}>
                        <p>{item.name}</p>
                     </div>
                  )
               })}
            </div>
            <div className="p-col-3">
               {(isAnswerCorrect && nextTaskItem) && (
                  <>
                     <div className="wrapper-btn-next-task">
                        <Button label={<img src={ glob.icons["nextExerciseBig"] } width="50" />} className="btn-next-task p-button-rounded p-button-text" onClick={() => goNextSlide()} />
                        <div className="bg-of-btn-next-task"></div>
                     </div>
                  </>
               )}
            </div>
         </div>
      </div>
   );
};