import React from 'react';
import MennyiPenzedVan from '../../MennyiPenzedVan';

export default function MennyiAprodVan(props, { ...rest }) {

   var penz_ = [
      "5",
      "10",
      "20",
      "50",
      "100",
      "200"
   ]
   
   return (
      <div className="task-wrapper task-wrapper-MennyiAprodVan">
         <MennyiPenzedVan options={penz_} />
      </div>
   );
};