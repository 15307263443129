import React from 'react';
import $ from 'jquery';
import glob from '../glob';
import QuestionItem from '../QuestionItem';

export default function MennyiPenzedVan(props, { ...rest }) {
   
   var penz_ = props.options;

   const getImageSrc = (name) => {
      return require('../../images/tasks/_penz/' + name + '.jpeg').default;
   };

   const getOptions = (question) => {
      var sum = question.reduce(add, 0);
      var options = [sum];
      options.push((sum-parseInt(question[0])));
      options.push((sum+parseInt(question[1])));
      
      var return_ = options.map(function (item, index) {
         return {
            "name": glob.numberWithSpaces(item) +" Ft",
            "isCorrect": index == 0
         }
      });

      return return_
   }
   
   function add(accumulator, a) {
      return parseInt(accumulator) + parseInt(a);
   }

   //var kerdesek = JSON.parse(JSON.stringify(penz_));

   var questions = [];
   $.each(Array.from({ length: 30 }, (_, i) => (i + 1)), (index, item) => {
      var question = [];
      var question_length = 4;
      if (props.hasOwnProperty('taskName') && props.taskName === "MennyiPenzVanAPenztarcadban") {
         question_length = 6;
      }
      $.each(Array.from({ length: question_length }, (_, i) => (i + 1)), (index2, item2) => {
         question.push(glob.getRandomElementFromArray(penz_));
      });
      
      var name = question.map(function (item, index) {
         return (
            <>
               <img className="money-option" src={getImageSrc(item)} />
               {(props.hasOwnProperty('taskName') && props.taskName === "MennyiPenzVanAPenztarcadban" && index == 2) && <br />}
            </>
         )
      });
      var opts_ = getOptions(question);
      glob.shuffleArray(opts_, true);

      var new_item = {
         "question": {
            "name": name
         },
         "options": opts_
      };
      questions.push(new_item);
   });
   
   var task_config = {};
   
   task_config["items"] = questions; //.slice(1, 3)
   
   return (
      <QuestionItem type="kerdes_felul_valaszok_alul" taskConfig={task_config} />
   );
};