import React, { useContext, useEffect, useState } from "react";

const AppContext = React.createContext();

export function useAppContext() {
  return useContext(AppContext);
}

export function AppContextProvider({ children }) {
  const [appTitle, setAppTitle] = useState(null);
  const [hideSelector, setHideSelector] = useState(false);
  const [gameInstructionIsActive, setGameInstructionIsActive] = useState(false);

  useEffect(() => {
    console.log("loaded");
    console.log("appTitle: ", appTitle);
  }, [appTitle]);
  
  const value = {
    appTitle,
    setAppTitle,
    hideSelector,
    setHideSelector,
    gameInstructionIsActive,
    setGameInstructionIsActive
  };
  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
}
