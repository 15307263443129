import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import QuestionItem from '../../QuestionItem';
import glob from '../../glob';

export default function NevezdMeg(props, { ...rest }) {
   const [taskConfig, setTaskConfig] = useState(false);
   
   useEffect(() => {
      var available_words = ['ablak', 'ad', 'agy', 'ajto', 'all', 'allatkert', 'allatorvos', 'alma', 'almale', 'alsonadrag', 'alszik', 'ananasz', 'anyacsavar', 'apaca', 'aranyhal', 'as', 'aso', 'asvanyviz', 'asztal', 'asztalos', 'asztalterito', 'auto', 'autoszerelo', 'bab', 'babakocsi', 'bagoly', 'bakancs', 'balna', 'balta', 'banan', 'bar', 'barack', 'barna', 'beka', 'beszel', 'bezbolsapka', 'bicikli', 'biciklizik', 'bicska', 'billentyuzet', 'birka', 'biro', 'biztonsagior', 'biztositotu', 'bluz', 'bogre', 'bokor', 'bor', 'borond', 'borotva', 'borso', 'bot', 'bugyi', 'busz', 'buszmegallo', 'buszvezeto', 'butorbolt', 'capa', 'cellux', 'cerna', 'ceruza', 'cipo', 'cipobolt', 'citrom', 'citromfacsaro', 'colstok', 'csavar', 'csavarhuzo', 'cseresznye', 'csesze', 'csibe', 'csiga', 'csillagasz', 'csillagok', 'csillar', 'csipesz', 'csirke', 'csizma', 'csoki', 'csonak', 'cukraszda', 'darazs', 'delfin', 'denever', 'dio', 'diszno', 'dobol', 'doboz', 'domb', 'dugohuzo', 'dzseki', 'ebresztoora', 'ecset', 'edzocipo', 'eg', 'eger', 'elefant', 'elelmiszerbolt', 'elem', 'elemlampa', 'eltorik', 'enekel', 'eper', 'epiteszmernok', 'erdesz', 'erdo', 'esernyo', 'eso', 'eszik', 'etterem', 'evez', 'fa', 'fagyi', 'fakanal', 'farmer', 'fasirt', 'fasli', 'fedo', 'feher', 'fejhallgato', 'fekete', 'fekszik', 'felho', 'felmos', 'felmoso', 'felmosovodor', 'fenykepesz', 'fenykepezik', 'fenykepezogep', 'fenyofa', 'fest', 'festek', 'festohenger', 'fesu', 'filctoll', 
      'fiok', 'focizik', 'fodrasz', 'fogat_mos', 'fogkefe', 'fogkrem', 'fogo', 'fogorvos', 'fogorvosi_rendelo', 'fokhagyma', 'fold', 'folyekonyszappan', 'folyo', 'forgoszek', 'fotel', 'fu', 'fuggony', 'fulhallgato', 'funyirogep', 'furdik', 'furdokopeny', 'furdoszoba', 'furesz', 'furogep', 'fut', 'fuvet_vag', 'galamb', 'garazs', 'gardrob', 'gemkapocs', 'gereblye', 'gereblyezik', 'giliszta', 'gitarozik', 'golya', 'gomba', 'gordeszkazik', 'gorogdinnye', 'gumicsizma', 'gyik', 'gyogyszer', 'gyogyszeresz', 'gyogyszertar', 'gyogytornasz', 'gyufa', 'gyumolcsfa', 'gyumolcsot_szed', 'gyurodeszka', 'gyuru', 'habvero', 'hagyma', 'hajat_mos', 'hajat_szarit', 'hajkefe', 'hajo', 'hajszarito', 'hal', 'hallokeszulek', 'haloszoba', 'hamburger', 'hangya', 'harisnya', 'hattyu', 'haz', 'haztartasibolt', 'hegy', 'hegyezo', 'helikopter', 'hentes', 'hernyo', 'ho', 'holapat', 'hold', 'horcsog', 'horgasz', 'horgaszik', 'hovirag', 'hurka', 'hus', 'husbolt', 'huto', 'huz', 'ibolya', 'informatikus', 'ing', 'injekcio', 'iroasztal', 'iskola', 'iszik', 'jarokeret', 'jatszik', 'jatszoter', 'jeg', 'jegesmedve', 'joghurt', 'kabat', 'kacsa', 'kacsint', 'kad', 'kagylo', 'kakao', 'kakaoscsiga', 'kakas', 'kaktusz', 'kalacs', 'kalap', 'kalapacs', 'kamion', 'kanal', 'kanape', 'kanari', 'kapa', 'kapal', 'kaposzta', 'karkoto', 'karmester', 'karora', 'kartya', 'katica', 'katona', 'kave', 'kavefozo', 'kecske', 'kek', 'keksz', 'kemenysepro', 'kenguru', 'kenocs', 'kenyer', 'kerekesszek', 'kerites', 'kert', 'kertesz', 'kes', 'kesztyu', 'kezet_mos', 'kifli', 'kigyo', 'kiomlik', 'kistanyer', 'kiwi', 'kockasajt', 'kod', 'kohog', 
      'kolbasz', 'komod', 'konnektor', 'konyha', 'konyhakes', 'konyharuha', 'konyv', 'konyvesbolt', 'konyvespolc', 'konyvtaros', 'korcsolyazik', 'korhaz', 'koromkefe', 'korte', 'krokodil', 'krumpli', 'krumplinyomo', 'krumplipure', 'kuka', 'kukas', 'kukasauto', 'kulcs', 'kullancs', 'kutya', 'kutyaol', 'labdazik', 'labos', 'lakat', 'lancfuresz', 'lapat', 'lapostanyer', 'laptop', 'lazmero', 'lefekszik', 'legy', 'lekvar', 'lelkesz', 'lencse', 'lepcso', 'letra', 'level', 'leves', 'liba', 'lila', 'lilahagyma', 'limonade', 'lo', 'locsolocso', 'lombsepru', 'lovagol', 'macska', 'madareteto', 'magassarku_cipo', 'magno', 'majkrem', 'majom', 'malna', 'mandarin', 'manko', 'margarin', 'maszik', 'medence', 'medve', 'megbotlik', 'meggy', 'megy', 'meh', 'mehesz', 'melltarto', 'melytanyer', 'mentoauto', 'mentos', 'merleg', 'merokanal', 'meroszalag', 'metro', 'mez', 'mezo', 'mikro', 'mikrofon', 'mogyoro', 'moha', 'mokus', 'mos', 'mosdo', 'mosogat', 'mosogatogep', 'mosogatoszivacs', 'mosogep', 'mosopor', 'motor', 'mozi', 'mutat', 'mutos', 'nadrag', 'nagyito', 'nap', 'nappali', 'napszemuveg', 'narancs', 'narancsle', 'narancssarga', 'nejlonzacsko', 'nevet', 'nover', 'nyakkendo', 'nyaklanc', 'nyar', 'nyomtato', 'nyugagy', 'nyul', 'okostelefon', 'ollo', 'olvas', 'olvasolampa', 'ongyujto', 
      'ontozokanna', 'optikus', 'oroszlan', 'orrcsepp', 'orvos', 'orvosi_rendelo', 'osz', 'ov', 'pad', 'papagaj', 'papir', 'papirbolt', 'papirtorlo', 'paprika', 'papucs', 'paradicsom', 'parizsi', 'park', 'parna', 'partvis', 'patak', 'pekseg', 'penztarca', 'penztaros', 'perec', 'pezsgo', 'pezsgotabletta', 'pillango', 'pilota', 'pincer', 'pingpongozik', 'pingvin', 'piros', 'pizsama', 'pizza', 'pizzeria', 'pohar', 'pok', 'polc', 'polip', 'polo', 'porkolt', 'porszivo', 'porszivozik', 'postas', 'pulover', 'pumpa', 'radio', 'radir', 'ragaszto', 'ragtapasz', 'rajzol', 'rak', 'rantotta', 'rendor', 'rendorauto', 'repa', 'repulo', 'reszelo', 'retek', 'ribizli', 'ridikul', 'rigo', 'rizs', 'roka', 'rovidnadrag', 'rozsa', 'rozsaszin', 'rug', 'ruhaakaszto', 'ruhabolt', 'ruhacsipesz', 'ruhasszekreny', 'sajt', 
      'sakk', 'sal', 'salata', 'samli', 'sampon', 'sapka', 'sarga', 'sargadinnye', 'satu', 'sebesz', 'sepru', 'serpenyo', 'siel', 'sir', 'sodrofa', 'sonka', 'sopor', 
      'sor', 'sornyito', 'sporttaska', 'sug', 'sultkrumpli', 'sundiszno', 'szagol', 'szakacs', 'szakacskonyv', 'szalami', 'szalveta', 'szamar', 'szamitogep', 'szamitogepezik', 'szamologep', 'szandal', 'szappan', 'szek', 'szel', 'szemetes', 'szemeteslapat', 'szemuveg', 'szennyestarto', 'szerszamoslada', 'szigeteloszalag', 'szilva', 'szines_ceruza', 'szinhaz', 'szog', 'szoknya', 'szolo', 'szolole', 'szonyeg', 'szunyog', 'szurke', 'szuro', 'tablet', 'takarito', 'takaro', 'tal', 'talca', 'talicska', 'tanar', 'tarsasjatek', 'taska', 'tavasz', 'taviranyito', 'taxi', 'tea', 'tehen', 'tehenesz', 'teherauto', 'tej', 'teknos', 'tel', 'telefon', 'telefonal', 'templom', 'tenger', 'tengerimalac', 'teniszezik', 'tepsi', 'terdel', 'terit', 'teszta', 'teve', 'tigris', 'to', 'toboz', 'tojas', 'tol', 'tolcser', 'toll', 'tolltarto', 'tolto', 'torna_cipo', 'torolkozo', 'torta', 'torulkozik', 'traktor', 'triko', 'trombital', 'tu', 'tucsok', 'tukor', 'tukortojas', 'tulipan', 'tusszent', 'tuz', 'tuzhely', 'tuzogep', 'tuzolto', 'tuzoltoauto', 'tuzrakohely', 'tv', 'tvt_nez', 'tyuk', 'uborka', 'ugrik', 'ujhagyma', 'ujsag', 'ul', 'uszik', 'uszoda', 'vadasz', 'vagodeszka', 'vaj', 'vakond', 'varrono', 'vasal', 'vasalo', 'vasutallomas', 'vasutas', 'vasvilla', 'vegyeszmernok', 'vercukorszintmero', 'vereb', 'vernyomasmero', 'vezet', 'villa', 'villam', 'villanyborotva', 'villanykorte', 'villanyszerelo', 'villaskulcs', 'virag', 'viragbolt', 'viragcserep', 
         'virsli', 'visz', 'vitorlashajo', 'viz', 'vizilo', 'vizvezetekszerelo', 'vonalzo', 'vonat', 'wc', 'wc_papir', 'zebra', 'zenet_hallgat', 'zokni', 'zold', 'zoldsegbolt', 'zoldseges', 'zsemle', 'zsiraf', 'zsirkreta', 'zuhanyozik'];
      
      var temp_questions = [];
      $.each(glob.words, (index, item) => {
         temp_questions = temp_questions.concat(item["options"])
      });
      
      var questions_ = [];
      $.each(temp_questions, (index, item) => {
         if (available_words.includes(item["name"])) {
            questions_.push(item);
         }
      });
      console.log("questions_: ", questions_);
      
      glob.shuffleArray(questions_);
      
      var questions = [];
      $.each(questions_, (index, item) => {
         var new_item = {
            "question": {
               "image": item["image"]
            },
            "options": [
               {
                  "sound": require('../../../sounds/voice/_betuk/'+splitWord(item["name"])[0]+'.wav').default,
                  "name": splitWord(item["name"])[0]
               },
               {
                  "sound": require('../../../sounds/voice/_szavak/'+item["name"]+'_.mp3').default,
                  "name": splitWord(item["name"])[1]
               },
               {
                  "sound": item["sound"],
                  "name": item["name"]
               }
            ]
         };
         questions.push(new_item);
      });

      glob.shuffleArray(questions);/* 
      $.each(questions, (index, item) => {
         glob.shuffleArray(questions[index]["options"]);
      }); */

      
      var task_config = {
         "always_correct": true
      };
      
      task_config["items"] = questions; //.slice(1, 4)
      setTaskConfig(task_config);
   }, []);


/* 
   var task_config = {
      "always_correct": true,
      "items": []
   };
   var task_items = [
      ["ma", "margarin", "margarine2.jpeg"],
   ];


   $.each(task_items, (index, item) => {
      var new_item = {
         "question": {
            "image": require('../../../images/tasks/_photoset/'+item[2]).default
         },
         "options": [
            {
               "sound": require('../../../sounds/voice/_betuk/'+item[0][0]+'.wav').default,
               "name": item[0][0]
            },
            {
               "sound": require('../../../sounds/voice/_szavak/'+item[1]+'_.mp3').default,
               "name": item[0]
            },
            {
               "sound": require('../../../sounds/voice/_szavak/'+item[1]+'.mp3').default,
               "name": item[1]
            }
         ]
      };
      task_config["items"].push(new_item);
   });
    */

   function isDoubleLetter(letter){
      return letter=="cs" || letter == "dz" || letter == "gy"  || letter == "ly" || letter == "ny" || letter == "sz" || letter == "ty" || letter == "zs";
  }
  
  function isTripleLetter(letter){
      return letter=="ccs" || letter == "dzs" || letter == "ggy" || letter == "lly"  || letter == "nny" || letter == "ssz" || letter == "tty" || letter == "zzs";
  }
  
  function isVowel(letter){
      return letter=="a" || letter=="á" || letter=="e" || letter == "é" || letter=="i" || letter == "í" || letter == "o" || letter == "ó" || letter == "ö" || letter == "ő" || letter == "u"  || letter == "ú" || letter == "ü" || letter == "ű";
  }
  
  function splitWord(word){
      var phonemicCue;
  
      var firstLetter;
      var secondLetter;
  
      var lastIndex = 0;
      if(isTripleLetter(word.substring(0, 3))){
          firstLetter = word.substring(0, 3);
          lastIndex = 3;
      } else if(isDoubleLetter(word.substring(0, 2))){
          firstLetter = word.substring(0, 2);
          lastIndex = 2;
      } else {
          firstLetter = word[0];
          lastIndex = 1;
      }
  
      if(isDoubleLetter(word.substring(lastIndex, lastIndex+2))) {
          secondLetter = word.substring(lastIndex, lastIndex+2);
          lastIndex = lastIndex + 2;
      } else {
          secondLetter = word[lastIndex];
          lastIndex ++;
      }
  
      // Fonemikus cue (elso ket hang) osszeillesztese, illetve
      phonemicCue = firstLetter + secondLetter;
  
      // harmadik hang megtalalasa es hozzaillesztese massalhangzotorlodas eseten
      if(!isVowel(firstLetter) && !isVowel(secondLetter)) {
          phonemicCue += word[lastIndex];
      }
  
      return [firstLetter, phonemicCue]
   }
   
   return (
      <div className="task-wrapper task-wrapper-NevezdMeg">
         {taskConfig["items"] && <QuestionItem taskName="NevezdMeg" type="kerdes_felul_valaszok_alul" taskConfig={taskConfig} />}
      </div>
   );
};