import React from 'react';
import $ from 'jquery';
import glob from '../../glob';
import QuestionItem from '../../QuestionItem';

export default function MutasdMeg(props, { ...rest }) {
   
   var penz_display = {
      "5": "Öt Ft",
      "10": "Tíz Ft",
      "20": "Húsz Ft",
      "50": "Ötven Ft",
      "100": "Száz Ft",
      "200": "Kétszáz Ft",
      "500": "Ötszáz Ft",
      "1000": "Ezer Ft",
      "2000": "Kétezer Ft",
      "5000": "Ötezer Ft",
      "10000": "Tízezer Ft",
      "20000": "Húszezer Ft"
   };

   var penz_ = [
      "5",
      "10",
      "20",
      "50",
      "100",
      "200",
      "500",
      "1000",
      "2000",
      "5000",
      "10000",
      "20000",
   ];
   
   const getImageSrc = (name) => {
      return require('../../../images/tasks/_penz/' + name + '.jpeg').default;
   };

   const getOptions = (question) => {
      var target_category = JSON.parse(JSON.stringify(penz_));

      console.log("target_category: ", target_category);
      
      var options = [question];
      while (options.length < 4) {
         var new_option = glob.getRandomElementFromArray(target_category);
         if (!options.includes(new_option)) {
            options.push(new_option);
         }
      }
      
      var options_to_return = options.map(function (item, index) {
         return {
            "image": getImageSrc(item),
            "isCorrect": index == 0
         }
      });
      
      glob.shuffleArray(options_to_return, true);
      return options_to_return
   }

   var kerdesek = JSON.parse(JSON.stringify(penz_)).concat(JSON.parse(JSON.stringify(penz_))).concat(JSON.parse(JSON.stringify(penz_)));
   console.log("kerdesek length: ", kerdesek);

   glob.shuffleArray(kerdesek);
   
   var questions = [];
   $.each(kerdesek, (index, item) => {
      var new_item = {
         "question": {
            "name": penz_display[item],
         },
         "options": getOptions(item)
      };
      questions.push(new_item);
   });

   var task_config = {};
   
   task_config["items"] = questions; //.slice(1, 3)
   
   return (
      <div className="task-wrapper task-wrapper-MutasdMeg">
         <QuestionItem type="valaszok_kozepen" taskConfig={task_config} />
      </div>
   );
};