import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import glob from '../../glob';
import KeyboardInput from '../../KeyboardInput';
import { Button } from 'primereact/button';
import GameEndScreen from '../../GameEndScreen';
import { useAppContext } from '../../appContext';

export default function IrdLeAHallottSzot(props, { ...rest }) {
   const [userAnswer, setUserAnswer] = useState("");
   const [taskConfig, setTaskConfig] = useState(false);
   const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
   const [questionIndex, setQuestionIndex] = useState(0);
   const [answerClass, setAnswerClass] = useState("");
   
   const [gameStarted, setGameStarted] = useState(false);
   const { gameInstructionIsActive } = useAppContext();

   useEffect(() => {
      var questions_ = [];
      $.each(glob.words, (index, item) => {
         if (glob.word_categories.includes(item["question"]["name"])) {
            $.each(item["options"], (index, item2) => {
               item2["category"] = item["question"]["name"];
            });
            questions_ = questions_.concat(item["options"]);
         }
      });
      glob.shuffleArray(questions_);
      console.log("questions_: ", questions_);
      var questions = [];
      $.each(questions_, (index, item) => {
         var question_item = item;
         console.log("question_item: ", question_item);

         var new_item = {
            "question": {
               "name": question_item["name"],
               "image": question_item["image"],
               "sound": question_item["sound"],
            }
         };
         questions.push(new_item);
      });
      glob.shuffleArray(questions);

      var task_config = {};
         
      task_config["items"] = questions; //.slice(1, 3)
      setTaskConfig(task_config);
   }, []);
/* 
   useEffect(() => {
      if (userAnswer === "" && answerClass != "") {
         setAnswerClass("");
      }
   }, [userAnswer, answerClass]); */
   
   var taskItem = taskConfig && taskConfig["items"][questionIndex];
   var nextTaskItem = false;
   nextTaskItem = taskConfig && taskConfig["items"][questionIndex + 1];

   const checkAnswer = () => {
      var is_correct = taskItem["question"]["name"] == userAnswer;
      setIsAnswerCorrect(is_correct);
      setAnswerClass(is_correct ? "correct" : "incorrect");
      glob.playGameSound(is_correct ? "correct" : "incorrect");
      if (!is_correct) {
         setTimeout(function () {
            setAnswerClass("");
         }, 2000);   
      }
   }

   const goNextSlide = () => {
      setQuestionIndex(questionIndex + 1);
      setIsAnswerCorrect(false);
      setUserAnswer("");
      setAnswerClass("");
   }
   
   
   useEffect(() => {
      if (gameInstructionIsActive && gameStarted) {
        setTimeout(function () {
         glob.playSound(taskItem["question"]["sound"]);
       }, 3000);
      }
   }, [gameInstructionIsActive]);
   
   useEffect(() => {
      if (taskItem) {
         setTimeout(function () {
            glob.playSound(taskItem["question"]["sound"]);
         }, questionIndex == 0 ? 3000 : 0);
         setTimeout(function () {
            setGameStarted(true);
          }, 1000);
      }
   }, [questionIndex, taskItem]);

   const resetGame = () => {
      var task_config = taskConfig;
      glob.shuffleArray(task_config["items"], true);
      setTaskConfig(task_config);
      
      setIsAnswerCorrect(false);
      setQuestionIndex(0);
      setUserAnswer("");
      setAnswerClass("");
   }

   if (!nextTaskItem && isAnswerCorrect) {
      return <GameEndScreen className="nomargintop" resetGame={ resetGame } />
   }
   return (
      <div className="task-wrapper task-wrapper-IrdLeAHallottSzot">
         <KeyboardInput
            className={answerClass}
            onComponentChange={setUserAnswer}
            value={userAnswer}
            isAnswerCorrect={isAnswerCorrect}
            type="words"
            leftComponent={taskItem && <Button label={<img src={ glob.icons["speaker"] } width="50" />} className="btn-speaker p-button-rounded p-button-text p-button-plain p-icon-fs-2rem" onClick={() => glob.playSound(taskItem["question"]["sound"])} />}
            rightComponent={<Button label={<img src={ glob.icons["check"] } width="50" />} className="btn-answer-check p-button-rounded p-button-text p-button-plain p-icon-fs-2rem" onClick={() => checkAnswer()} />}
         />
         <div className="p-col-3">
            {(isAnswerCorrect && nextTaskItem) && (
               <>
                  <div className="wrapper-btn-next-task">
                     <Button label={<img src={ glob.icons["nextExerciseBig"] } width="50" />} className="btn-next-task p-button-rounded p-button-text" onClick={() => goNextSlide()} />
                     <div className="bg-of-btn-next-task"></div>
                  </div>
               </>
            )}
          </div>
      </div>
   );
};