import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { Button } from 'primereact/button';
import glob from '../../glob';
import KeyboardInput from '../../KeyboardInput';
import { useParams } from 'react-router';
import GameEndScreen from '../../GameEndScreen';
import { useAppContext } from '../../appContext';

export default function MelyikSzamotHallod(props, { ...rest }) {
   const { paramDifficultyLevel } = useParams();
   const [userAnswer, setUserAnswer] = useState("");
   const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
   const [questionIndex, setQuestionIndex] = useState(0);
   const [answerClass, setAnswerClass] = useState("");
   const [taskConfig, setTaskConfig] = useState(false);
   const [gameStarted, setGameStarted] = useState(false);
  
   const { gameInstructionIsActive } = useAppContext();

   var questions_dict = {
      "1":["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "25", "33", "40", "46", "48", "49", "55", "59", "65", "66", "73", "76", "77", "78", "79", "81", "83", "87", "92", "93", "98", "99"],
      "2":["100", "105", "111", "120", "155", "176", "182", "199", "200", "201", "205", "211", "220", "221", "228", "260", "265", "278", "287", "293", "300", "303", "311", "323", "326", "342", "355", "363", "369", "375", "378", "395", "400", "403", "405", "418", "423", "426", "432", "437", "442", "448", "454", "465", "467", "479", "496", "500", "506", "515", "523", "537", "546", "556", "566", "574", "582", "590", "600", "605", "607", "617", "647", "650", "667", "674", "682", "696", "700", "701", "707", "734", "749", "756", "783", "799", "800", "815", "820", "833", "842", "859", "864", "872", "886", "895", "900", "909", "910", "923", "939", "945", "952", "970", "988", "1000"],
      "3":["1923", "1948", "1953", "1954", "1956", "1959", "1961", "1962", "1965", "1967", "1968", "1973", "1975", "1977", "1980", "1981", "1986", "1989", "1992", "1993", "1995", "2001", "2003", "2008", "2011", "2014", "2019", "2020", "2021", "2028", "2076", "2090", "2744", "2856", "3002", "3016", "3100", "3406", "3499", "3561", "3578", "3603", "4022", "4050", "4107", "4289", "4372", "5056", "5219", "5478", "6560", "6778", "7013", "7616", "7908", "8008", "8015", "8422", "9015", "9289", "9373", "10000", "10005", "10786", "10893", "12350", "12865", "73401", "76334", "78452", "82023", "91491", "95226", "100827", "108223", "351004", "758925", "905006", "1205763", "1400000", "1728040"]
   }

   var questions_ = questions_dict[paramDifficultyLevel];

   useEffect(() => {
      var questions = [];
      $.each(questions_, (index, item) => {
         var new_item = {
            "name": item,
            "sound": require('../../../sounds/voice/melyik_szamot_hallod/' + item + '.mp3').default
         }
         questions.push(new_item);
      });
      
      glob.shuffleArray(questions);
   
      var task_config = {};
      task_config["items"] = questions; //.slice(1, 3)

      setTaskConfig(task_config);

   }, []);
/* 
   useEffect(() => {
      if (userAnswer === "" && answerClass != "") {
         setAnswerClass("");
      }
   }, [userAnswer, answerClass]); */
   
   var taskItem = taskConfig && taskConfig["items"][questionIndex];
   var nextTaskItem = false;
   nextTaskItem = taskConfig && taskConfig["items"][questionIndex + 1];

   const checkAnswer = () => {
      var is_correct = userAnswer == taskItem["name"];
      
      setIsAnswerCorrect(is_correct);
      
      setAnswerClass(is_correct ? "correct" : "incorrect");
      glob.playGameSound(is_correct ? "correct" : "incorrect");
      
      if (!is_correct) {
         setTimeout(function () {
            setAnswerClass("");
         }, 2000);   
      }
   }
   
   const goNextSlide = () => {
      setQuestionIndex(questionIndex + 1);
      setIsAnswerCorrect(false);
      setUserAnswer("");
      setAnswerClass("");
   }

   useEffect(() => {
      if (gameInstructionIsActive && gameStarted) {
        setTimeout(function () {
          glob.playSound(taskItem["sound"]);
       }, 3000);
      }
   }, [gameInstructionIsActive]);
   
   useEffect(() => {
      if (taskItem) {
         setTimeout(function () {
            glob.playSound(taskItem["sound"]);
         }, questionIndex == 0 ? 3000 : 0);
         setTimeout(function () {
            setGameStarted(true);
          }, 1000);
      }
   }, [questionIndex, taskItem]);

   const resetGame = () => {
      var task_config = taskConfig;
      glob.shuffleArray(task_config["items"], true);
      setTaskConfig(task_config);

      setIsAnswerCorrect(false);
      setQuestionIndex(0);
      setUserAnswer("");
      setAnswerClass("");
   }

   if (!nextTaskItem && isAnswerCorrect) {
      return <GameEndScreen className="nomargintop" resetGame={ resetGame } />
   }
   return (
      <div className="task-wrapper task-wrapper-MelyikSzamotHallod">
         <div className="p-grid p-align-center">
            <div className="p-col-3 p-text-right">
               <Button label={<img src={ glob.icons["speaker"] } width="50" />} className="btn-image p-button-rounded p-button-text p-icon-fs-2rem" onClick={() => glob.playSound(taskItem["sound"])} />
            </div>
            <div className="p-col-6">
               <KeyboardInput
                  className={answerClass}
                  onComponentChange={setUserAnswer}
                  value={userAnswer}
                  isAnswerCorrect={isAnswerCorrect}
                  type="numbers"
               />      
            </div>
            <div className="p-col-3">
               {!isAnswerCorrect && <Button label={<img src={ glob.icons["check"] } width="50" />} className="p-button-rounded p-button-plain p-button-text p-icon-fs-2rem" onClick={() => checkAnswer()} />}      
            </div>
            {(isAnswerCorrect && nextTaskItem) && (
               <>
                  <div className="wrapper-btn-next-task">
                     <Button label={<img src={ glob.icons["nextExerciseBig"] } width="50" />} className="btn-next-task p-button-rounded p-button-text" onClick={() => goNextSlide()} />
                     <div className="bg-of-btn-next-task"></div>
                  </div>
               </>
            )}
         </div>
   </div>
   );
};