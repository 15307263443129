import React from 'react';
import { Button } from 'primereact/button';
import { useEffect } from 'react';
import glob from '../glob';

export default function GameEndScreen(props, { ...rest }) {
  useEffect(() => {
    glob.playInstructionSound("Gratulálok!");
  }, []);

  return (
    <div className={`p-grid p-align-center wrapper-GameEndScreen ${props.className}`} >
      <div className="p-col-12 p-text-center">
        <h1>&#128578;</h1>
        <h2>Gratulálok! Az összes feladatot megoldottad.</h2>
        <Button label={(<><img src={ glob.icons["again"] } width="30" /> <span style={{fontSize:"1.5rem", position:"relative", bottom:"4px"}}>Újra</span></>)} className="button-replay p-button-plain p-button-outlined" onClick={() => props.resetGame()} />
      </div>
    </div>
  );
};
