import React, { createRef, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { useHistory, useLocation, useParams } from 'react-router';
import { Redirect } from "react-router-dom";

import Slider from "react-slick";
import $ from 'jquery';

import glob from '../../glob';
import { useAppContext } from '../../appContext';
import GameEndScreen from '../../GameEndScreen';

export default function FigyeldMegJolAKepet(props, { ...rest }) {
  var { paramSubtaskId } = useParams();
  paramSubtaskId = parseInt(paramSubtaskId);
  
    const [currentSlide, setCurrentSlide] = useState(0);
    const [questionIndex, setQuestionIndex] = useState(0);
    const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
    const [gameEnded, setGameEnded] = useState(false);
    const [taskItem, setTaskItem] = useState(null);
    const [isInstructionSoundPlayed, setIsInstructionSoundPlayed] = useState(false);
    const history = useHistory();
    const location = useLocation();
  
    const { setAppTitle } = useAppContext();
    const { setHideSelector } = useAppContext();
  
    const myslider = createRef();

    var task_config = {
      items: [
        {
          main_image:
            require("../../../images/stockphotos/figyeld_meg_jol_a_kepet/1.jpg")
              .default,
          questions: [
            {
              question: {
                name: "Hány gyertya volt a képen?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/1/hany_gyertya.mp3")
                    .default,
              },
              options: [
                {
                  name: "egy",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/1/egy.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "három",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/1/harom.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "hét",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/1/het.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
            {
              question: {
                name: "Hol ült a kislány?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/1/hol_ult_a_kislany.mp3")
                    .default,
              },
              options: [
                {
                  name: "az etetőszékben",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/1/az_etetoszekben.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "az apja ölében",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/1/az_apja_oleben.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "az anyja ölében",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/1/az_anyja_oleben.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
            {
              question: {
                name: "A lakás melyik helységében vacsoráztak?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/1/a_lakas_melyik_helysegenben.mp3")
                    .default,
              },
              options: [
                {
                  name: "a nappaliban",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/1/a_nappaliban.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "a konyhában",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/1/a_konyhaban.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "a gyerekszobában",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/1/a_gyerekszobaban.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
          ],
        },
        {
          main_image:
            require("../../../images/stockphotos/figyeld_meg_jol_a_kepet/2.jpg")
              .default,
          questions: [
            {
              question: {
                name: "Milyen évszak volt?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/2/milyen_evszak_volt.mp3")
                    .default,
              },
              options: [
                {
                  name: "tavasz",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/2/tavasz.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "ősz",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/2/osz.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "tél",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/2/tel.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
            {
              question: {
                name: "Mit sütött a nő?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/2/mit_sutott_a_no.mp3")
                    .default,
              },
              options: [
                {
                  name: "süteményt",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/2/sutemenyt.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "húst",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/2/hust.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "tortát",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/2/tortat.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
            {
              question: {
                name: "Milyen színű volt a nő haja?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/2/milyen_szinu_volt.mp3")
                    .default,
              },
              options: [
                {
                  name: "szőke",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/2/szoke.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "vörös",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/2/voros.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "ősz",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/2/osz.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
          ],
        },
        {
          main_image:
            require("../../../images/stockphotos/figyeld_meg_jol_a_kepet/3.jpg")
              .default,
          questions: [
            {
              question: {
                name: "Milyen eszközön gépelt a nő?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/3/milyen_eszkozon_gepelt.mp3")
                    .default,
              },
              options: [
                {
                  name: "varrógépen",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/3/varrogepen.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "írógépen",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/3/irogepen.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "laptopon",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/3/laptopon.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
            {
              question: {
                name: "Miben hozta a gépet?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/3/miben_hozta_a_gepet.mp3")
                    .default,
              },
              options: [
                {
                  name: "kosárban",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/3/kosarban.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "aktatáskában",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/3/aktataska.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "hátizsákban",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/3/hatizsakban.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
            {
              question: {
                name: "Milyen színű volt a hátizsák?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/3/milyen_szinu_volt.mp3")
                    .default,
              },
              options: [
                {
                  name: "fekete",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/3/fekete.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "rózsaszín",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/3/rozsaszin.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "szürke",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/3/szurke.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
          ],
        },
        {
          main_image:
            require("../../../images/stockphotos/figyeld_meg_jol_a_kepet/4.jpg")
              .default,
          questions: [
            {
              question: {
                name: "Melyik kezén voltak a gyűrűk?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/4/melyik_kezen.mp3")
                    .default,
              },
              options: [
                {
                  name: "mindkettőn",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/4/mindketton.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "jobb kezén",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/4/jobb_kezen.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "bal kezén",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/4/bal_kezen.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
            {
              question: {
                name: "Mit csinált a férfi?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/4/mit_csinalt_ferfi.mp3")
                    .default,
              },
              options: [
                {
                  name: "borotválkozott",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/4/borotvalkozott.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "begombolta az ingét",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/4/begombolta_az_inget.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "nyakkendőt kötött",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/4/nyakkendot_kotott.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
            {
              question: {
                name: "Melyik ujja nem látszódott?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/4/melyik_ujja_nem.mp3")
                    .default,
              },
              options: [
                {
                  name: "kisujj",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/4/kisujj.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "mutatóujj",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/4/mutatoujj.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "hüvelykujj",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/4/huvelykujj.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
          ],
        },
        {
          main_image:
            require("../../../images/stockphotos/figyeld_meg_jol_a_kepet/5.jpg")
              .default,
          questions: [
            {
              question: {
                name: "Mi volt a lány kezében?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/5/mi_volt_a_lany_kezeben.mp3")
                    .default,
              },
              options: [
                {
                  name: "videokamera",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/5/videokamera.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "fényképezőgép",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/5/fenykepezogep.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "telefon",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/5/telefon.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
            {
              question: {
                name: "Mi volt a nyakában?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/5/mi_volt_nakaban.mp3")
                    .default,
              },
              options: [
                {
                  name: "sál",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/5/sal.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "stóla",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/5/stola.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "kiskendő",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/5/kiskendo.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
            {
              question: {
                name: "Ki voltak lakkozva a körmei?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/5/ki_voltak_lakkozva.mp3")
                    .default,
              },
              options: [
                {
                  name: "nem",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/5/nem.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "igen, pirosra",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/5/igen_pirosra.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "igen, zöldre",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/5/igen_zoldre.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
          ],
        },
        {
          main_image:
            require("../../../images/stockphotos/figyeld_meg_jol_a_kepet/6.jpg")
              .default,
          questions: [
            {
              question: {
                name: "Hány személy volt a képen?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/6/hany_szemely.mp3")
                    .default,
              },
              options: [
                {
                  name: "2",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/6/ketto.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "3",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/6/harom.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "4",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/6/negy.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
            {
              question: {
                name: "Milyen jármű volt a teremben?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/6/milyen_jarmu_volt.mp3")
                    .default,
              },
              options: [
                {
                  name: "bicikli",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/6/bicikli.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "roller",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/6/roller.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "motor",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/6/motor.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
            {
              question: {
                name: "Mit csinált a hátul ülő fiú?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/6/mit_csinalt_a_hatul_ulo.mp3")
                    .default,
              },
              options: [
                {
                  name: "tízóraizott",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/6/tizoraizott.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "a telefont nyomkodta",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/6/a_telefont_nyomkodta.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "olvasott",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/6/olvasott.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
          ],
        },
        {
          main_image:
            require("../../../images/stockphotos/figyeld_meg_jol_a_kepet/7.jpg")
              .default,
          questions: [
            {
              question: {
                name: "Mit csináltak?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/7/mit_csinaltak.mp3")
                    .default,
              },
              options: [
                {
                  name: "koccintottak",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/7/koccintotak.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "levest ettek",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/7/levest_ettek.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "beszélgettek",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/7/beszelgettek.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
            {
              question: {
                name: "Milyen pékárut ettek?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/7/milyen_pekarut_ettek.mp3")
                    .default,
              },
              options: [
                {
                  name: "kiflit",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/7/kiflit.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "kalácsot",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/7/kalacsot.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "bagettet",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/7/bagettet.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
            {
              question: {
                name: "Mi az ovális zöldség neve?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/7/mi_az_ovalis_zoldseg.mp3")
                    .default,
              },
              options: [
                {
                  name: "tök",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/7/tok.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "uborka",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/7/uborka.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "avokádó",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/7/avokado.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
          ],
        },
        {
          main_image:
            require("../../../images/stockphotos/figyeld_meg_jol_a_kepet/8.jpg")
              .default,
          questions: [
            {
              question: {
                name: "Hányan ittak barna sört?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/8/hanyan_ittak_barnasort.mp3")
                    .default,
              },
              options: [
                {
                  name: "mind a hárman",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/8/3.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "ketten",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/8/ketten.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "csak egyedül",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/8/csak_egyedul.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
            {
              question: {
                name: "Mi van az asztalon bal oldalon elől?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/8/mi_van_az_asztalon_bal_oldalon_elol.mp3")
                    .default,
              },
              options: [
                {
                  name: "virágláda növénnyel",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/8/viraglada_novennyel.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "étlap",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/8/etlap.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "telefon",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/8/telefon.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
            {
              question: {
                name: "Viselt valaki szoknyát?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/8/viselt_valaki_szoknyat.mp3")
                    .default,
              },
              options: [
                {
                  name: "senki",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/8/senki.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "ketten",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/8/ketten.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "egyvalaki",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/8/egy_valaki.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
          ],
        },
        {
          main_image:
            require("../../../images/stockphotos/figyeld_meg_jol_a_kepet/9.jpg")
              .default,
          questions: [
            {
              question: {
                name: "Hány köldököt láttál?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/9/hany_koldokot_lattal.mp3")
                    .default,
              },
              options: [
                {
                  name: "hatot",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/9/6.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "kettőt",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/9/2.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "ötöt",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/9/5.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
            {
              question: {
                name: "Mi volt a kezükben?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/9/mi_volt_a_kezukben.mp3")
                    .default,
              },
              options: [
                {
                  name: "ugrálókötél",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/9/ugralokotel.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "súlyzó",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/9/sulyzo.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "ladba",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/9/labda.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
            {
              question: {
                name: "Kiknek volt edzése?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/9/kiknek_volt_edzese.mp3")
                    .default,
              },
              options: [
                {
                  name: "a fiúknak",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/9/fiuknak.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "a lányoknak",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/9/lanyoknak.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "a gyerekeknek",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/9/gyerekeknek.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
          ],
        },
        {
          main_image:
            require("../../../images/stockphotos/figyeld_meg_jol_a_kepet/10.jpg")
              .default,
          questions: [
            {
              question: {
                name: "Mi volt a lábukon?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/10/mi_volt_a_labukon.mp3")
                    .default,
              },
              options: [
                {
                  name: "szandál",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/10/szandal.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "gumicsizma",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/10/gumicsizma.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "félcipő",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/10/felcipo.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
            {
              question: {
                name: "Milyen színe volt a lábbeliknek?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/10/milyen_szine.mp3")
                    .default,
              },
              options: [
                {
                  name: "szürke",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/10/szurke.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "zöld",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/10/zold.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "kék",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/10/kek.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
            {
              question: {
                name: "Merre lejtett a farönk?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/10/merre_lejtett_a_farunk.mp3")
                    .default,
              },
              options: [
                {
                  name: "jobbra",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/10/jobbra.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "balra",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/10/balra.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "egyenesen állt",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/10/egyenesen_allt.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
          ],
        },
        {
          main_image:
            require("../../../images/stockphotos/figyeld_meg_jol_a_kepet/11.jpg")
              .default,
          questions: [
            {
              question: {
                name: "Mennyit mutatott a kislány?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/11/mennyit_mutatott.mp3")
                    .default,
              },
              options: [
                {
                  name: "5",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/11/5.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "2",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/11/2.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "3",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/11/3.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
            {
              question: {
                name: "Melyik gyerek nyújtotta ki a nyelvét?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/11/melyik_gyerek_nyujtotta_ki.mp3")
                    .default,
              },
              options: [
                {
                  name: "a kislány",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/11/kislany.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "a bal hátsó fiú",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/11/bal_hatso_fiu.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "a jobb hátsó fiú",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/11/jobb_hatso_fiu.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
            {
              question: {
                name: "Milyen volt a bal első kisfiú ruhaujja?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/11/milyen_volt_a_ruhaujja.mp3")
                    .default,
              },
              options: [
                {
                  name: "pettyes",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/11/pettyes.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "csíkos",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/11/csikos.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "virágmintás",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/11/viragmintas.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
          ],
        },
        {
          main_image:
            require("../../../images/stockphotos/figyeld_meg_jol_a_kepet/12.jpg")
              .default,
          questions: [
            {
              question: {
                name: "Hány embert volt a képen?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/12/hany_ember_volt_a_kepen.mp3")
                    .default,
              },
              options: [
                {
                  name: "4",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/12/negy.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "7",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/12/het.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "2",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/12/ketto.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
            {
              question: {
                name: "Hányas szám volt a kék trikón?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/12/hanyas_szam_volt_a_kek_trikon.mp3")
                    .default,
              },
              options: [
                {
                  name: "3",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/12/harom.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "6",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/12/hat.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "8",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/12/nyolc.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
            {
              question: {
                name: "Mi volt a kék trikós férfi fején?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/12/mi_volt_a_kek_fejen.mp3")
                    .default,
              },
              options: [
                {
                  name: "fejhallgató",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/12/fejhallgato.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "napszemüveg",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/12/napszemuveg.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "kalap",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/12/kalap.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
          ],
        },
        {
          main_image:
            require("../../../images/stockphotos/figyeld_meg_jol_a_kepet/13.jpg")
              .default,
          questions: [
            {
              question: {
                name: "Hol álltak az emberek?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/13/hol_altak_az_emberek.mp3")
                    .default,
              },
              options: [
                {
                  name: "egy fekete tábla előtt",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/13/egy_fekete_tabla.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "egy téren",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/13/egy_teren.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "az ajtóban",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/13/az_ajtoban.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
            {
              question: {
                name: "Hány lányt láttál?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/13/hany_lanyt_lattal.mp3")
                    .default,
              },
              options: [
                {
                  name: "4",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/13/4.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "8",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/13/8.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "3",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/13/3.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
            {
              question: {
                name: "Mennyi volt a felemelt jobb-bal kezek száma?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/13/mennyi_volt_a_felemel.mp3")
                    .default,
              },
              options: [
                {
                  name: "4:4",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/13/4-4.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "6:2",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/13/6-2.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "2:6",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/13/2-6.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
          ],
        },
        {
          main_image:
            require("../../../images/stockphotos/figyeld_meg_jol_a_kepet/14.jpg")
              .default,
          questions: [
            {
              question: {
                name: "Hány madár volt a levegőben?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/14/hany_madar_volt_a_levegoben.mp3")
                    .default,
              },
              options: [
                {
                  name: "1",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/14/1.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "2",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/14/2.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "0",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/14/0.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
            {
              question: {
                name: "Mi volt a szálló madár csőrében?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/14/mi_volt_a_csoreben.mp3")
                    .default,
              },
              options: [
                {
                  name: "cseresznye",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/14/cseresznye.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "vékony ágak",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/14/vekony_agak.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "kukac",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/14/kukac.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
            {
              question: {
                name: "Hol ült a másik madár?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/14/hol_ult_a_masik_madar.mp3")
                    .default,
              },
              options: [
                {
                  name: "a fészkén",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/14/a_feszken.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "a madáretetőn",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/14/a_madareteton.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "oduban",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/14/oduban.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
          ],
        },
        {
          main_image:
            require("../../../images/stockphotos/figyeld_meg_jol_a_kepet/15.jpg")
              .default,
          questions: [
            {
              question: {
                name: "Milyen évszak volt?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/15/milyen_evszak_volt.mp3")
                    .default,
              },
              options: [
                {
                  name: "tavasz",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/15/tavasz.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "nyár",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/15/nyar.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "ősz",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/15/osz.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
            {
              question: {
                name: "Mi volt a férfi hátán?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/15/mi_volt_a_ferfi_hatan.mp3")
                    .default,
              },
              options: [
                {
                  name: "koffer",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/15/koffer.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "aktatáska",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/15/aktataska.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "hátizsák",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/15/hatizsak.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
            {
              question: {
                name: "Mi borította a talajt?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/15/mi_boritotta_a_talajt.mp3")
                    .default,
              },
              options: [
                {
                  name: "kavicsok",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/15/kavicsok.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "hó",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/15/ho.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "avar",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/15/avar.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
          ],
        },
        {
          main_image:
            require("../../../images/stockphotos/figyeld_meg_jol_a_kepet/16.jpg")
              .default,
          questions: [
            {
              question: {
                name: "Milyen nemű gyerekek voltak a képen?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/16/milyen_nemu_gyerekek_voltak_a_kepen.mp3")
                    .default,
              },
              options: [
                {
                  name: "két lány",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/16/2_lany.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "két fiú",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/16/2_fiu.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "egy lány egy fiú",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/16/1_lany_1_fiu.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
            {
              question: {
                name: "Hol ültek a gyerekek?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/16/hol_ultek_a_gyerekek.mp3")
                    .default,
              },
              options: [
                {
                  name: "padon",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/16/padon.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "széken",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/16/szeken.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "kőpárkányon",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/16/koparkanyon.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
            {
              question: {
                name: "Milyen gyerekek voltak a képen?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/16/milyen_gyerekek_voltak_a_kepen.mp3")
                    .default,
              },
              options: [
                {
                  name: "európaiak",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/16/europaiak.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "afrikaiak",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/16/afrikaiak.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "ázsiaiak",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/16/azsiaiak.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
          ],
        },
        {
          main_image:
            require("../../../images/stockphotos/figyeld_meg_jol_a_kepet/17.jpg")
              .default,
          questions: [
            {
              question: {
                name: "Hol volt a mackón ragtapasz?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/17/hol_volt_ragtapasz.mp3")
                    .default,
              },
              options: [
                {
                  name: "a bal mellén",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/17/bal_mellen.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "a jobb mellén",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/17/jobb_mellen.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "középen",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/17/kozepen.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
            {
              question: {
                name: "Hány végtagja volt bekötve?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/17/hany_vegtagja_volt_bekotve.mp3")
                    .default,
              },
              options: [
                {
                  name: "1",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/17/1.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "2",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/17/2.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "4",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/17/4.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
            {
              question: {
                name: "Hol volt a másik kötés?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/17/hol_volt_a_masik_kotes.mp3")
                    .default,
              },
              options: [
                {
                  name: "a nyakán",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/17/a_nyakan.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "a karján",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/17/a_karjan.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "a fején",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/17/a_fejen.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
          ],
        },
        {
          main_image:
            require("../../../images/stockphotos/figyeld_meg_jol_a_kepet/18.jpeg")
              .default,
          questions: [
            {
              question: {
                name: "Hol volt a család?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/18/hol_volt_a_csalad.mp3")
                    .default,
              },
              options: [
                {
                  name: "hajókikötőben",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/18/hajokikotoben.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "pályaudvaron",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/18/palyaudvaron.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "repülőtéren",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/18/repuloteren.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
            {
              question: {
                name: "Hány személy volt a képen?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/18/hany_szemely_volt_a_kepen.mp3")
                    .default,
              },
              options: [
                {
                  name: "3",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/18/3.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "5",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/18/5.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "4",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/18/4.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
            {
              question: {
                name: "Milyenek voltak a kofferek?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/18/milyenek_voltak_a_kofferek.mp3")
                    .default,
              },
              options: [
                {
                  name: "lila és fekete",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/18/lila_es_fekete.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "rózsaszín és fekete",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/18/rozsaszin_es_fekete.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "kék és rózsaszín",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/18/kek_es_rozsaszin.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
          ],
        },
        {
          main_image:
            require("../../../images/stockphotos/figyeld_meg_jol_a_kepet/19.jpeg")
              .default,
          questions: [
            {
              question: {
                name: "Hány ember volt a képen?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/19/hany_ember_volt_a_kepen.mp3")
                    .default,
              },
              options: [
                {
                  name: "4 lány és 4 fiú",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/19/4_lany_4_fiu.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "6 lány és 2 fiú",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/19/6_lany_2_fiu.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "3 lány és 5 fiú",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/19/3_lany_5_fiu.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
            {
              question: {
                name: "Miért jöttek össze?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/19/miert_jottek_ossze.mp3")
                    .default,
              },
              options: [
                {
                  name: "temetésre",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/19/temetesre.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "ebédre",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/19/ebedre.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "születésnapra",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/19/szuletesnapra.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
            {
              question: {
                name: "Milyen szalag volt a csomagokon?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/19/milyen_szalag_volt_a_csomagokon.mp3")
                    .default,
              },
              options: [
                {
                  name: "narancssárga",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/19/narancssarga.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "piros",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/19/piros.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "lila",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/19/lila.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
          ],
        },
        {
          main_image:
            require("../../../images/stockphotos/figyeld_meg_jol_a_kepet/20.jpeg")
              .default,
          questions: [
            {
              question: {
                name: "Hol voltak az emberek?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/20/hol_voltak_az_emberek.mp3")
                    .default,
              },
              options: [
                {
                  name: "fogorvosnál",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/20/fogorvosnal.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "étteremben",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/20/etteremben.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "fodrásznál",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/20/fodrasznal.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
            {
              question: {
                name: "Hány személy volt a képen?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/20/hany_szemely.mp3")
                    .default,
              },
              options: [
                {
                  name: "2",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/20/2.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "4",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/20/4.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "7",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/20/7.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
            {
              question: {
                name: "A kép jobb oldalán látható nőnek milyen volta haja?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/20/a_kep_jobb_oldalan.mp3")
                    .default,
              },
              options: [
                {
                  name: "rövid szőke",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/20/rovid_szoke.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "hosszú szőke",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/20/hosszu_szoke.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "rövid ősz",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/20/rovid_osz.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
          ],
        },
        {
          main_image:
            require("../../../images/stockphotos/figyeld_meg_jol_a_kepet/21.jpeg")
              .default,
          questions: [
            {
              question: {
                name: "Mit csináltak a fiatalok?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/21/mit_csinaltak_a_fiatalok.mp3")
                    .default,
              },
              options: [
                {
                  name: "videofelvételt",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/21/video_felvetlt.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "hangfelvételt",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/21/hang_felvetlt.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "szelfit",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/21/szelfit.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
            {
              question: {
                name: "Milyen hangulatban voltak?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/21/milyen_hangulatban_voltak.mp3")
                    .default,
              },
              options: [
                {
                  name: "szomorú",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/21/szomoru.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "vidám",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/21/vidam.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "bánatos",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/21/banatos.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
            {
              question: {
                name: "Milyen betűt mutatott a baloldali lány?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/21/milyen_betut_mutatott.mp3")
                    .default,
              },
              options: [
                {
                  name: "Z",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/21/z.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "V",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/21/v.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "Y",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/21/y.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
          ],
        },
        {
          main_image:
            require("../../../images/stockphotos/figyeld_meg_jol_a_kepet/22.jpeg")
              .default,
          questions: [
            {
              question: {
                name: "Hány szögletes óra volt a képen?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/22/hany_szogletes_ora_volt_a_kepen.mp3")
                    .default,
              },
              options: [
                {
                  name: "6",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/22/6.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "3",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/22/3.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "0",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/22/0.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
            {
              question: {
                name: "Alul a középső kis kék óra mit mutatott?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/22/alul_a_kozepso_kis_kek.mp3")
                    .default,
              },
              options: [
                {
                  name: "fél hatot",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/22/fel_6.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "nyolc órát",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/22/8_orat.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "éjfelet",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/22/ejfelet.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
            {
              question: {
                name: (<>Mi a neve ennek az órának <img className="p-d-inline-block" src={ require("../../../images/stockphotos/figyeld_meg_jol_a_kepet/22_vekker.jpg").default} width='100' height='100' /> ?</>),
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/22/mi_a_neve.mp3")
                    .default,
              },
              options: [
                {
                  name: "toronyóra",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/22/toronyora.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "homokóra",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/22/homokora.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "vekker",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/22/vekker.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
          ],
        },
        {
          main_image:
            require("../../../images/stockphotos/figyeld_meg_jol_a_kepet/23.jpg")
              .default,
          questions: [
            {
              question: {
                name: "Mi volt a nő kezében?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/23/mi_volt_a_no_kezeben.mp3")
                    .default,
              },
              options: [
                {
                  name: "könyv",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/23/konyv.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "kifli",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/23/kifli.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "tablet",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/23/tablet.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
            {
              question: {
                name: "Hol ült?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/23/hol_ult.mp3")
                    .default,
              },
              options: [
                {
                  name: "padon",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/23/padon.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "sámlin",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/23/samlin.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "fotelben",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/23/fotelben.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
            {
              question: {
                name: "Milyen ruhában volt?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/23/milyen_ruhaban_volt.mp3")
                    .default,
              },
              options: [
                {
                  name: "télikabátban",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/23/telikabatban.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "kockás blúzban",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/23/kockas_bluzban.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "estélyiben",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/23/estelyiben.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
          ],
        },
        {
          main_image:
            require("../../../images/stockphotos/figyeld_meg_jol_a_kepet/24.jpg")
              .default,
          questions: [
            {
              question: {
                name: "Mi volt a lány fején?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/24/mi_volt_a_lany.mp3")
                    .default,
              },
              options: [
                {
                  name: "bohócsapka",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/24/bohoc_sapka.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "kendő",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/24/kendo.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "kalap",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/24/kalap.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
            {
              question: {
                name: "Milyen virág volt a kezében?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/24/milyen_virag_volt.mp3")
                    .default,
              },
              options: [
                {
                  name: "sárga rózsa",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/24/sarga_rozsa.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "pipacs",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/24/pipacs.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "napraforgó",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/24/napraforgo.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
            {
              question: {
                name: "Hol üldögélt?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/24/hol_uldogelt.mp3")
                    .default,
              },
              options: [
                {
                  name: "moziban",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/24/moziban.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "étteremben",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/24/etteremben.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "a természetben",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/24/a_termeszetben.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
          ],
        },
        {
          main_image:
            require("../../../images/stockphotos/figyeld_meg_jol_a_kepet/25.jpg")
              .default,
          questions: [
            {
              question: {
                name: "Milyen állatuk volt?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/25/milyen_allatuk.mp3")
                    .default,
              },
              options: [
                {
                  name: "macska",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/25/macska.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "nyúl",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/25/nyul.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "kutya",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/25/kutya.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
            {
              question: {
                name: "Hol voltak?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/25/hol_voltak.mp3")
                    .default,
              },
              options: [
                {
                  name: "erdőben",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/25/erdoben.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "moziban",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/25/moziban.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "réten",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/25/reten.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
            {
              question: {
                name: "Mit csináltak?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/25/mit_csinaltak.mp3")
                    .default,
              },
              options: [
                {
                  name: "veszekedtek",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/25/veszekedtek.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "csókolóztak",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/25/csokoloztak.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "olvastak",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/25/olvastak.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
          ],
        },
        {
          main_image:
            require("../../../images/stockphotos/figyeld_meg_jol_a_kepet/26.jpeg")
              .default,
          questions: [
            {
              question: {
                name: "Kivel sütött a kislány?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/26/kivel_sutott_a_kislany.mp3")
                    .default,
              },
              options: [
                {
                  name: "az anyukájával",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/26/az_anyukajaval.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "a nagymamájával",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/26/a_nagymamajaval.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "a nagypapájával",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/26/a_nagypapajaval.mp3")
                      .default,
                  isCorrect: true,
                },
              ],
            },
            {
              question: {
                name: "Mi volt a nagypapa kezében?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/26/mi_volt_a_nagypapa_kezeben.mp3")
                    .default,
              },
              options: [
                {
                  name: "pogácsaszaggató",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/26/pogacsa_szaggato.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "sodrófa",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/26/sodrofa.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "fakanál",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/26/fakanal.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
            {
              question: {
                name: "Milyen volt a kötény mintája?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/26/milyen_volt_a_koteny_mintaja.mp3")
                    .default,
              },
              options: [
                {
                  name: "kockás",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/26/kockas.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "pettyes",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/26/pettyes.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "csíkos",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/26/csikos.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
          ],
        },
        {
          main_image:
            require("../../../images/stockphotos/figyeld_meg_jol_a_kepet/27.jpg")
              .default,
          questions: [
            {
              question: {
                name: "Hány lány volt a képen?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/27/hany_lany_volt_a_kepen.mp3")
                    .default,
              },
              options: [
                {
                  name: "0",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/27/0.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "3",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/27/3.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "4",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/27/4.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
            {
              question: {
                name: "Milyen színű pulóvert viselt a fiú?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/27/milyen_szinu_pulovert.mp3")
                    .default,
              },
              options: [
                {
                  name: "piros",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/27/piros.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "kék",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/27/kek.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "zöld",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/27/zold.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
            {
              question: {
                name: "Mi volt a fiú kezében?",
                sound:
                  require("../../../sounds/voice/figyeld_meg_jol_a_kepet/27/mi_volt_a_kezeben.mp3")
                    .default,
              },
              options: [
                {
                  name: "fagyi",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/27/fagyi.mp3")
                      .default,
                  isCorrect: false,
                },
                {
                  name: "telefon",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/27/telefon.mp3")
                      .default,
                  isCorrect: true,
                },
                {
                  name: "fényképezőgép",
                  sound:
                    require("../../../sounds/voice/figyeld_meg_jol_a_kepet/27/fenykepezogep.mp3")
                      .default,
                  isCorrect: false,
                },
              ],
            },
          ],
        },
      ],
   };
   
  
    useEffect(() => {
      $.each(task_config["items"], (index, item) => {
        $.each(task_config["items"][index]["questions"], (index2, item) => {
          glob.shuffleArray(task_config["items"][index]["questions"][index2]["options"]);
        });
      });
      
      setTaskItem(task_config["items"][paramSubtaskId ? paramSubtaskId : 0]);
    }, []);

    useEffect(() => {
      if (currentSlide === 0) {
        setAppTitle("");
        //setIsAnswerCorrect(false);
        setHideSelector(false);
      } else {
        var apptitle_ = "Válaszolj a kérdésekre!";
        setAppTitle(apptitle_);
        setHideSelector(true);

        if (!isInstructionSoundPlayed) {
            setIsInstructionSoundPlayed(true);
            glob.playInstructionSound(apptitle_);
        }
      }

      return () => {
        setAppTitle("");
      };
   }, [currentSlide]);

   useEffect(() => {
      setIsAnswerCorrect(false);
      setTaskItem(task_config["items"][paramSubtaskId ? paramSubtaskId : 0]);
   }, [questionIndex]);

   useEffect(() => {
      setIsAnswerCorrect(false);
      setQuestionIndex(0);
      myslider.current.slickGoTo(0);
     setAppTitle("");
     setTaskItem(task_config["items"][paramSubtaskId ? paramSubtaskId : 0]);
   }, [paramSubtaskId]);
  
/* 
   var task_config = {
      "items": [
         {
            "main_image": require('../../../images/tasks/figyeld_meg_jol_a_kepet/1.jpg').default,
            "questions": [
               {
                  "question": {
                     "name": "Hány gyertya volt a képen?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/1/hany_gyertya.mp3').default,
                  },
                  "options": [
                     {
                        "name": "egy",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/1/egy.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "három",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/1/harom.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "hét",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/1/het.mp3').default,
                        "isCorrect": true
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Hol ült a kislány?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/1/hol_ult_a_kislany.mp3').default,
                  },
                  "options": [
                     {
                        "name": "az etetőszékben",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/1/az_etetoszekben.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "az anyja ölében",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/1/az_anyja_oleben.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "az apja ölében",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/1/az_apja_oleben.mp3').default,
                        "isCorrect": false
                     }
                  ]
               },
               {
                  "question": {
                     "name": "A lakás melyik helységében vacsoráztak?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/1/a_lakas_melyik_helysegenben.mp3').default,
                  },
                  "options": [
                     {
                        "name": "a nappaliban",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/1/a_nappaliban.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "a konyhában",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/1/a_konyhaban.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "a gyerekszobában",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/1/a_gyerekszobaban.mp3').default,
                        "isCorrect": false
                     }
                  ]
               }
            ]
         },{
            "main_image": require('../../../images/tasks/figyeld_meg_jol_a_kepet/2.jpg').default,
            "questions": [
               {
                  "question": {
                     "name": "Milyen évszak volt?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/2/milyen_evszak_volt.mp3').default,
                  },
                  "options": [
                     {
                        "name": "tavasz",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/2/tavasz.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "ősz",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/2/osz.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "tél",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/2/tel.mp3').default,
                        "isCorrect": true
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Mit sütött a nő?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/2/mit_sutott_a_no.mp3').default,
                  },
                  "options": [
                     {
                        "name": "tortát",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/2/tortat.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "süteményt",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/2/sutemenyt.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "húst",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/2/hust.mp3').default,
                        "isCorrect": false
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Milyen színű volt a haja?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/2/milyen_szinu_volt.mp3').default,
                  },
                  "options": [
                     {
                        "name": "szőke",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/2/szoke.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "vörös",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/2/voros.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "ősz",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/2/osz.mp3').default,
                        "isCorrect": false
                     }
                  ]
               }
            ]
         },{
            "main_image": require('../../../images/tasks/figyeld_meg_jol_a_kepet/3.jpg').default,
            "questions": [
               {
                  "question": {
                     "name": "Milyen eszközön gépelt a nő?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/3/milyen_eszkozon_gepelt.mp3').default,
                  },
                  "options": [
                     {
                        "name": "varrógépen",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/3/varrogepen.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "írógépen",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/3/irogepen.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "laptopon",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/3/laptopon.mp3').default,
                        "isCorrect": true
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Miben hozta a gépet?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/3/miben_hozta_a_gepet.mp3').default,
                  },
                  "options": [
                     {
                        "name": "hátizsákban",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/3/hatizsakban.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "aktatáskában",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/3/aktataska.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "kosárban",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/3/kosarban.mp3').default,
                        "isCorrect": false
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Milyen színű volt a hátizsák?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/3/milyen_szinu_volt.mp3').default,
                  },
                  "options": [
                     {
                        "name": "szürke",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/3/szurke.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "rózsaszín",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/3/rozsaszin.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "fekete",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/3/fekete.mp3').default,
                        "isCorrect": false
                     }
                  ]
               }
            ]
         },{
            "main_image": require('../../../images/tasks/figyeld_meg_jol_a_kepet/4.jpg').default,
            "questions": [
               {
                  "question": {
                     "name": "Melyik kezén voltak a gyűrűk?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/4/melyik_kezen.mp3').default,
                  },
                  "options": [
                     {
                        "name": "mindkettőn",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/4/mindketton.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "jobb kezén",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/4/jobb_kezen.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "bal kezén",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/4/bal_kezen.mp3').default,
                        "isCorrect": true
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Mit csinált a férfi?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/4/mit_csinalt_ferfi.mp3').default,
                  },
                  "options": [
                     {
                        "name": "nyakkendőt kötött",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/4/nyakkendot_kotott.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "begombolta az ingét",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/4/begombolta_az_inget.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "borotválkozott",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/4/borotvalkozott.mp3').default,
                        "isCorrect": false
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Melyik ujja nem látszódott?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/4/melyik_ujja_nem.mp3').default,
                  },
                  "options": [
                     {
                        "name": "hüvelykujj",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/4/huvelykujj.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "mutatóujj",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/4/mutatoujj.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "kisujj",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/4/kisujj.mp3').default,
                        "isCorrect": false
                     }
                  ]
               }
            ]
         },{
            "main_image": require('../../../images/tasks/figyeld_meg_jol_a_kepet/5.jpg').default,
            "questions": [
               {
                  "question": {
                     "name": "Mi volt a lány kezében?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/5/mi_volt_a_lany_kezeben.mp3').default,
                  },
                  "options": [
                     {
                        "name": "videokamera",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/5/videokamera.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "telefon",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/5/telefon.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "fényképezőgép",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/5/fenykepezogep.mp3').default,
                        "isCorrect": true
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Mi volt a nyakában?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/5/mi_volt_nakaban.mp3').default,
                  },
                  "options": [
                     {
                        "name": "kiskendő",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/5/kiskendo.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "sál",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/5/sal.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "stóla",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/5/stola.mp3').default,
                        "isCorrect": false
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Ki voltak lakkozva a körmei?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/5/ki_voltak_lakkozva.mp3').default,
                  },
                  "options": [
                     {
                        "name": "nem",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/5/nem.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "igen, pirosra",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/5/igen_pirosra.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "igen, zöldre",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/5/igen_zoldre.mp3').default,
                        "isCorrect": false
                     }
                  ]
               }
            ]
         },{
            "main_image": require('../../../images/tasks/figyeld_meg_jol_a_kepet/6.jpg').default,
            "questions": [
               {
                  "question": {
                     "name": "Hány személy volt a képen?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/6/hany_szemely.mp3').default,
                  },
                  "options": [
                     {
                        "name": "3",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/6/harom.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "4",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/6/negy.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "2",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/6/ketto.mp3').default,
                        "isCorrect": true
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Milyen jármű volt a teremben?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/6/milyen_jarmu_volt.mp3').default,
                  },
                  "options": [
                     {
                        "name": "bicikli",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/6/bicikli.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "motor",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/6/motor.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "roller",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/6/roller.mp3').default,
                        "isCorrect": false
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Mit csinált a hátul ülő fiú?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/6/mit_csinalt_a_hatul_ulo.mp3').default,
                  },
                  "options": [
                     {
                        "name": "olvasott",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/6/olvasott.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "tízóraizott",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/6/tizoraizott.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "a telefont nyomkodta",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/6/a_telefont_nyomkodta.mp3').default,
                        "isCorrect": false
                     }
                  ]
               }
            ]
         },{
            "main_image": require('../../../images/tasks/figyeld_meg_jol_a_kepet/7.jpg').default,
            "questions": [
               {
                  "question": {
                     "name": "Mit csináltak?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/7/mit_csinaltak.mp3').default,
                  },
                  "options": [
                     {
                        "name": "koccintottak",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/7/koccintotak.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "levest ettek",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/7/levest_ettek.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "beszélgettek",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/7/beszelgettek.mp3').default,
                        "isCorrect": true
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Milyen pékárut ettek?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/7/milyen_pekarut_ettek.mp3').default,
                  },
                  "options": [
                     {
                        "name": "bagettet",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/7/bagettet.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "kalácsot",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/7/kalacsot.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "kiflit",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/7/kiflit.mp3').default,
                        "isCorrect": false
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Mi az ovális zöldség neve?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/7/mi_az_ovalis_zoldseg.mp3').default,
                  },
                  "options": [
                     {
                        "name": "avokádó",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/7/avokado.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "uborka",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/7/uborka.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "tök",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/7/tok.mp3').default,
                        "isCorrect": false
                     }
                  ]
               }
            ]
         },{
            "main_image": require('../../../images/tasks/figyeld_meg_jol_a_kepet/8.jpg').default,
            "questions": [
               {
                  "question": {
                     "name": "Hányan ittak barna sört?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/8/hanyan_ittak_barnasort.mp3').default,
                  },
                  "options": [
                     {
                        "name": "csak egyedül",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/8/csak_egyedul.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "ketten",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/8/ketten.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "mind a hárman",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/8/mind_a_harman.mp3').default,
                        "isCorrect": true
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Mi van az asztalon bal oldalon elől?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/8/mi_van_az_asztalon_bal_oldalon_elol.mp3').default,
                  },
                  "options": [
                     {
                        "name": "virágláda növénnyel",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/8/viraglada_novennyel.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "étlap",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/8/etlap.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "telefon",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/8/telefon.mp3').default,
                        "isCorrect": false
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Viselt valaki szoknyát?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/8/viselt_valaki_szoknyat.mp3').default,
                  },
                  "options": [
                     {
                        "name": "egyvalaki",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/8/egy_valaki.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "senki",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/8/senki.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "ketten",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/8/ketten.mp3').default,
                        "isCorrect": false
                     }
                  ]
               }
            ]
         },{
            "main_image": require('../../../images/tasks/figyeld_meg_jol_a_kepet/9.jpg').default,
            "questions": [
               {
                  "question": {
                     "name": "Hány köldököt láttál?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/9/hany_koldokot_lattal.mp3').default,
                  },
                  "options": [
                     {
                        "name": "hatot",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/9/6.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "kettőt",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/9/2.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "ötöt",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/9/5.mp3').default,
                        "isCorrect": true
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Mi volt a kezükben?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/9/mi_volt_a_kezukben.mp3').default,
                  },
                  "options": [
                     {
                        "name": "súlyzó",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/9/sulyzo.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "ugrálókötél",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/9/ugralokotel.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "labda",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/9/labda.mp3').default,
                        "isCorrect": false
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Kinek volt edzése?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/9/kiknek_volt_edzese.mp3').default,
                  },
                  "options": [
                     {
                        "name": "a fiúknak",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/9/fiuknak.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "a gyerekeknek",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/9/gyerekeknek.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "a lányoknak",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/9/lanyoknak.mp3').default,
                        "isCorrect": false
                     }
                  ]
               }
            ]
         },{
            "main_image": require('../../../images/tasks/figyeld_meg_jol_a_kepet/10.jpg').default,
            "questions": [
               {
                  "question": {
                     "name": "Mi volt a lábukon?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/10/mi_volt_a_labukon.mp3').default,
                  },
                  "options": [
                     {
                        "name": "félcipő",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/10/felcipo.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "szandál",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/10/szandal.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "gumicsizma",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/10/gumicsizma.mp3').default,
                        "isCorrect": true
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Milyen színe volt a lábbeliknek?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/10/milyen_szine.mp3').default,
                  },
                  "options": [
                     {
                        "name": "szürke",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/10/szurke.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "kék",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/10/kek.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "zöld",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/10/zold.mp3').default,
                        "isCorrect": false
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Merre lejtett a farönk?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/10/merre_lejtett_a_farunk.mp3').default,
                  },
                  "options": [
                     {
                        "name": "egyenesen állt",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/10/egyenesen_allt.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "jobbra",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/10/jobbra.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "balra",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/10/balra.mp3').default,
                        "isCorrect": false
                     }
                  ]
               }
            ]
         },{
            "main_image": require('../../../images/tasks/figyeld_meg_jol_a_kepet/11.jpg').default,
            "questions": [
               {
                  "question": {
                     "name": "Mennyit mutatott a kislány?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/11/mennyit_mutatott.mp3').default,
                  },
                  "options": [
                     {
                        "name": "3",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/11/3.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "2",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/11/2.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "5",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/11/5.mp3').default,
                        "isCorrect": true
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Melyik gyerek nyújtotta ki a nyelvét?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/11/melyik_gyerek_nyujtotta_ki.mp3').default,
                  },
                  "options": [
                     {
                        "name": "a bal hátsó fiú",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/11/bal_hatso_fiu.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "a jobb hátsó fiú",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/11/jobb_hatso_fiu.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "a kislány",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/11/kislany.mp3').default,
                        "isCorrect": false
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Milyen volt a bal első kisfiú ruhaujja?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/11/milyen_volt_a_ruhaujja.mp3').default,
                  },
                  "options": [
                     {
                        "name": "virágmintás",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/11/viragmintas.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "csíkos",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/11/csikos.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "pettyes",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/11/pettyes.mp3').default,
                        "isCorrect": false
                     }
                  ]
               }
            ]
         },{
            "main_image": require('../../../images/tasks/figyeld_meg_jol_a_kepet/12.jpg').default,
            "questions": [
               {
                  "question": {
                     "name": "Hány ember volt a képen?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/12/hany_ember_volt_a_kepen.mp3').default,
                  },
                  "options": [
                     {
                        "name": "7",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/12/het.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "2",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/12/ketto.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "4",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/12/negy.mp3').default,
                        "isCorrect": true
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Hányas szám volt a kék trikón?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/12/hanyas_szam_volt_a_kek_trikon.mp3').default,
                  },
                  "options": [
                     {
                        "name": "8",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/12/nyolc.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "3",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/12/harom.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "6",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/12/hat.mp3').default,
                        "isCorrect": false
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Mi volt a kék trikós férfi fején?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/12/mi_volt_a_kek_fejen.mp3').default,
                  },
                  "options": [
                     {
                        "name": "napszemüveg",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/12/napszemuveg.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "fejhallgató",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/12/fejhallgato.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "kalap",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/12/kalap.mp3').default,
                        "isCorrect": false
                     }
                  ]
               }
            ]
         },{
            "main_image": require('../../../images/tasks/figyeld_meg_jol_a_kepet/13.jpg').default,
            "questions": [
               {
                  "question": {
                     "name": "Hol álltak az emberek?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/13/hol_altak_az_emberek.mp3').default,
                  },
                  "options": [
                     {
                        "name": "egy téren",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/13/egy_teren.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "egy fekete tábla előtt",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/13/egy_fekete_tabla.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "az ajtóban",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/13/az_ajtoban.mp3').default,
                        "isCorrect": true
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Hány lányt láttál?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/13/hany_lanyt_lattal.mp3').default,
                  },
                  "options": [
                     {
                        "name": "4",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/13/4.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "3",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/13/3.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "8",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/13/8.mp3').default,
                        "isCorrect": false
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Mennyi volt a felemelt jobb-bal kezek száma?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/13/mennyi_volt_a_felemel.mp3').default,
                  },
                  "options": [
                     {
                        "name": "2:6",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/13/2-6.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "4:4",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/13/4-4.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "6:2",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/13/6-2.mp3').default,
                        "isCorrect": false
                     }
                  ]
               }
            ]
         },{
            "main_image": require('../../../images/tasks/figyeld_meg_jol_a_kepet/14.jpg').default,
            "questions": [
               {
                  "question": {
                     "name": "Hány madár volt a levegőben?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/14/hany_madar_volt_a_levegoben.mp3').default,
                  },
                  "options": [
                     {
                        "name": "0",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/14/0.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "1",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/14/1.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "2",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/14/2.mp3').default,
                        "isCorrect": true
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Mi volt a szálló madár csőrében?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/14/mi_volt_a_csoreben.mp3').default,
                  },
                  "options": [
                     {
                        "name": "cseresznye",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/14/cseresznye.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "kukac",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/14/kukac.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "vékony ágak",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/14/vekony_agak.mp3').default,
                        "isCorrect": false
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Hol ült a másik madár?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/14/hol_ult_a_masik_madar.mp3').default,
                  },
                  "options": [
                     {
                        "name": "a fészkén",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/14/a_feszken.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "odúban",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/14/oduban.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "a madáretetőn",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/14/a_madareteton.mp3').default,
                        "isCorrect": false
                     }
                  ]
               }
            ]
         },{
            "main_image": require('../../../images/tasks/figyeld_meg_jol_a_kepet/15.jpg').default,
            "questions": [
               {
                  "question": {
                     "name": "Milyen évszak volt",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/15/milyen_evszak_volt.mp3').default,
                  },
                  "options": [
                     {
                        "name": "tavasz",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/15/tavasz.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "ősz",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/15/osz.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "nyár",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/15/nyar.mp3').default,
                        "isCorrect": true
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Mi volt a férfi hátán?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/15/mi_volt_a_ferfi_hatan.mp3').default,
                  },
                  "options": [
                     {
                        "name": "hátizsák",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/15/hatizsak.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "aktatáska",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/15/aktataska.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "koffer",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/15/koffer.mp3').default,
                        "isCorrect": false
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Mi borítja a talajt?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/15/mi_boritotta_a_talajt.mp3').default,
                  },
                  "options": [
                     {
                        "name": "avar",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/15/avar.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "hó",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/15/ho.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "kavicsok",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/15/kavicsok.mp3').default,
                        "isCorrect": false
                     }
                  ]
               }
            ]
         },{
            "main_image": require('../../../images/tasks/figyeld_meg_jol_a_kepet/16.jpg').default,
            "questions": [
               {
                  "question": {
                     "name": "Milyen nemű gyerekek voltak a képen?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/16/milyen_nemu_gyerekek_voltak_a_kepen.mp3').default,
                  },
                  "options": [
                     {
                        "name": "két lány",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/16/2_lany.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "két fiú",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/16/2_fiu.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "egy lány egy fiú",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/16/1_lany_1_fiu.mp3').default,
                        "isCorrect": true
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Hol ültek a gyerekek?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/16/hol_ultek_a_gyerekek.mp3').default,
                  },
                  "options": [
                     {
                        "name": "széken",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/16/szeken.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "padon",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/16/padon.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "kőpárkányon",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/16/koparkanyon.mp3').default,
                        "isCorrect": false
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Milyen gyerekek voltak a képen?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/16/milyen_gyerekek_voltak_a_kepen.mp3').default,
                  },
                  "options": [
                     {
                        "name": "európaiak",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/16/europaiak.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "ázsiaiak",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/16/azsiaiak.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "afrikaiak",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/16/afrikaiak.mp3').default,
                        "isCorrect": false
                     }
                  ]
               }
            ]
         },{
            "main_image": require('../../../images/tasks/figyeld_meg_jol_a_kepet/17.jpg').default,
            "questions": [
               {
                  "question": {
                     "name": "Hol volt a mackón ragtapasz?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/17/hol_volt_ragtapasz.mp3').default,
                  },
                  "options": [
                     {
                        "name": "középen",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/17/kozepen.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "a bal mellén",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/17/bal_mellen.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "a jobb mellén",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/17/jobb_mellen.mp3').default,
                        "isCorrect": true
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Hol volt a másik kötés?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/17/hol_volt_a_masik_kotes.mp3').default,
                  },
                  "options": [
                     {
                        "name": "a karján",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/17/a_karjan.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "a fején",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/17/a_fejen.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "a nyakán",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/17/a_nyakan.mp3').default,
                        "isCorrect": false
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Hány végtagja volt bekötve?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/17/hany_vegtagja_volt_bekotve.mp3').default,
                  },
                  "options": [
                     {
                        "name": "2",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/17/2.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "1",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/17/1.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "4",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/17/4.mp3').default,
                        "isCorrect": false
                     }
                  ]
               }
            ]
         },{
            "main_image": require('../../../images/tasks/figyeld_meg_jol_a_kepet/18.jpeg').default,
            "questions": [
               {
                  "question": {
                     "name": "Hol volt a család?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/18/hol_volt_a_csalad.mp3').default,
                  },
                  "options": [
                     {
                        "name": "repülőtéren",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/18/repuloteren.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "pályaudvaron",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/18/palyaudvaron.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "hajókikötőben",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/18/hajokikotoben.mp3').default,
                        "isCorrect": true
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Hány személy volt a képen?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/18/hany_szemely_volt_a_kepen.mp3').default,
                  },
                  "options": [
                     {
                        "name": "5",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/18/5.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "4",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/18/4.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "3",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/18/3.mp3').default,
                        "isCorrect": false
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Milyenek voltak a kofferek?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/18/milyenek_voltak_a_kofferek.mp3').default,
                  },
                  "options": [
                     {
                        "name": "rózsaszín és fekete",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/18/rozsaszin_es_fekete.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "lila és fekete",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/18/lila_es_fekete.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "kék és rózsaszín",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/18/kek_es_rozsaszin.mp3').default,
                        "isCorrect": false
                     }
                  ]
               }
            ]
         },{
            "main_image": require('../../../images/tasks/figyeld_meg_jol_a_kepet/19.jpeg').default,
            "questions": [
               {
                  "question": {
                     "name": "Hány ember volt a képen?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/19/hany_ember_volt_a_kepen.mp3').default,
                  },
                  "options": [
                     {
                        "name": "6 lány és 2 fiú",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/19/6_lany_2_fiu.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "3 lány és 5 fiú",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/19/3_lany_5_fiu.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "4 lány és 4 fiú",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/19/4_lany_4_fiu.mp3').default,
                        "isCorrect": true
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Miért jöttek össze?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/19/miert_jottek_ossze.mp3').default,
                  },
                  "options": [
                     {
                        "name": "ebédre",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/19/ebedre.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "születésnapra",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/19/szuletesnapra.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "temetésre",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/19/temetesre.mp3').default,
                        "isCorrect": false
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Milyen szalag volt a csomagokon?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/19/milyen_szalag_volt_a_csomagokon.mp3').default,
                  },
                  "options": [
                     {
                        "name": "lila",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/19/lila.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "narancssárga",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/19/narancssarga.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "piros",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/19/piros.mp3').default,
                        "isCorrect": false
                     }
                  ]
               }
            ]
         },{
            "main_image": require('../../../images/tasks/figyeld_meg_jol_a_kepet/20.jpeg').default,
            "questions": [
               {
                  "question": {
                     "name": "Hol voltak az emberek?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/20/hol_voltak_az_emberek.mp3').default,
                  },
                  "options": [
                     {
                        "name": "fogorvosnál",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/20/fogorvosnal.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "étteremben",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/20/etteremben.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "fodrásznál",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/20/fodrasznal.mp3').default,
                        "isCorrect": true
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Hány személy volt a képen?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/20/hany_szemely.mp3').default,
                  },
                  "options": [
                     {
                        "name": "2",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/20/2.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "4",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/20/4.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "7",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/20/7.mp3').default,
                        "isCorrect": false
                     }
                  ]
               },
               {
                  "question": {
                     "name": "A kép jobb oldalán látható nőnek milyen volt a haja?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/20/a_kep_jobb_oldalan.mp3').default,
                  },
                  "options": [
                     {
                        "name": "rövid ősz",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/20/rovid_osz.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "rövid szőke",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/20/rovid_szoke.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "hosszú szőke",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/20/hosszu_szoke.mp3').default,
                        "isCorrect": false
                     }
                  ]
               }
            ]
         },{
            "main_image": require('../../../images/tasks/figyeld_meg_jol_a_kepet/21.jpeg').default,
            "questions": [
               {
                  "question": {
                     "name": "Mit csináltak a fiatalok?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/21/mit_csinaltak_a_fiatalok.mp3').default,
                  },
                  "options": [
                     {
                        "name": "hangfelvételt",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/21/hang_felvetlt.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "videofelvételt",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/21/video_felvetlt.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "szelfit",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/21/szelfit.mp3').default,
                        "isCorrect": true
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Milyen hangulatban voltak?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/21/milyen_hangulatban_voltak.mp3').default,
                  },
                  "options": [
                     {
                        "name": "szomorú",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/21/szomoru.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "bánatos",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/21/banatos.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "vidám",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/21/vidam.mp3').default,
                        "isCorrect": false
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Milyen betűt mutatott a baloldali lány?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/21/milyen_betut_mutatott.mp3').default,
                  },
                  "options": [
                     {
                        "name": "Z",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/21/z.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "Y",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/21/y.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "V",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/21/v.mp3').default,
                        "isCorrect": false
                     }
                  ]
               }
            ]
         },{
            "main_image": require('../../../images/tasks/figyeld_meg_jol_a_kepet/22.jpeg').default,
            "questions": [
               {
                  "question": {
                     "name": "Hány szögletes óra volt a képen?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/22/hany_szogletes_ora_volt_a_kepen.mp3').default,
                  },
                  "options": [
                     {
                        "name": "0",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/22/0.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "3",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/22/3.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "6",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/22/6.mp3').default,
                        "isCorrect": true
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Alul a középső kis kék óra mit mutatott?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/22/alul_a_kozepso_kis_kek.mp3').default,
                  },
                  "options": [
                     {
                        "name": "éjfelet",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/22/ejfelet.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "fél hatot",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/22/fel_6.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "nyolc órát",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/22/8_orat.mp3').default,
                        "isCorrect": false
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Mi a neve ennek az órának [kép] ?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/22/mi_a_neve.mp3').default,
                  },
                  "options": [
                     {
                        "name": "toronyóra",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/22/toronyora.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "vekker",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/22/vekker.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "homokóra",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/22/homokora.mp3').default,
                        "isCorrect": false
                     }
                  ]
               }
            ]
         },{
            "main_image": require('../../../images/tasks/figyeld_meg_jol_a_kepet/23.jpg').default,
            "questions": [
               {
                  "question": {
                     "name": "Mi volt a nő kezében?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/23/mi_volt_a_no_kezeben.mp3').default,
                  },
                  "options": [
                     {
                        "name": "kifli",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/23/kifli.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "tablet",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/23/tablet.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "könyv",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/23/konyv.mp3').default,
                        "isCorrect": true
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Hol ült?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/23/hol_ult.mp3').default,
                  },
                  "options": [
                     {
                        "name": "padon",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/23/padon.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "sámlin",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/23/samlin.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "fotelben",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/23/fotelben.mp3').default,
                        "isCorrect": false
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Milyen ruhában volt?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/23/milyen_ruhaban_volt.mp3').default,
                  },
                  "options": [
                     {
                        "name": "kockás blúzban",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/23/kockas_bluzban.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "télikabátban",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/23/telikabatban.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "estélyiben",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/23/estelyiben.mp3').default,
                        "isCorrect": false
                     }
                  ]
               }
            ]
         },{
            "main_image": require('../../../images/tasks/figyeld_meg_jol_a_kepet/24.jpg').default,
            "questions": [
               {
                  "question": {
                     "name": "Mi volt a lány fején?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/24/mi_volt_a_lany.mp3').default,
                  },
                  "options": [
                     {
                        "name": "bohócsapka",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/24/bohoc_sapka.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "kalap",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/24/kalap.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "kendő",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/24/kendo.mp3').default,
                        "isCorrect": true
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Milyen virág volt a kezében?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/24/milyen_virag_volt.mp3').default,
                  },
                  "options": [
                     {
                        "name": "pipacs",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/24/pipacs.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "sárga rózsa",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/24/sarga_rozsa.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "napraforgó",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/24/napraforgo.mp3').default,
                        "isCorrect": false
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Hol üldögélt?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/24/hol_uldogelt.mp3').default,
                  },
                  "options": [
                     {
                        "name": "moziban",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/24/moziban.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "étteremben",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/24/etteremben.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "a természetben",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/24/a_termeszetben.mp3').default,
                        "isCorrect": false
                     }
                  ]
               }
            ]
         },{
            "main_image": require('../../../images/tasks/figyeld_meg_jol_a_kepet/25.jpg').default,
            "questions": [
               {
                  "question": {
                     "name": "Milyen állatuk volt?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/25/milyen_allatuk.mp3').default,
                  },
                  "options": [
                     {
                        "name": "nyúl",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/25/nyul.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "macska",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/25/macska.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "kutya",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/25/kutya.mp3').default,
                        "isCorrect": true
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Hol voltak?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/25/hol_voltak.mp3').default,
                  },
                  "options": [
                     {
                        "name": "réten",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/25/reten.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "erdőben",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/25/erdoben.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "moziban",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/25/moziban.mp3').default,
                        "isCorrect": false
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Mit csináltak?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/25/mit_csinaltak.mp3').default,
                  },
                  "options": [
                     {
                        "name": "veszekedtek",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/25/veszekedtek.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "csókolóztak",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/25/csokoloztak.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "olvastak",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/25/olvastak.mp3').default,
                        "isCorrect": false
                     }
                  ]
               }
            ]
         },{
            "main_image": require('../../../images/tasks/figyeld_meg_jol_a_kepet/26.jpeg').default,
            "questions": [
               {
                  "question": {
                     "name": "Kivel sütött a kislány?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/26/kivel_sutott_a_kislany.mp3').default,
                  },
                  "options": [
                     {
                        "name": "a nagypapájával",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/26/a_nagypapajaval.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "az anyukájával",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/26/az_anyukajaval.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "a nagymamájával",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/26/a_nagymamajaval.mp3').default,
                        "isCorrect": true
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Mi volt a nagypapa kezében?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/26/mi_volt_a_nagypapa_kezeben.mp3').default,
                  },
                  "options": [
                     {
                        "name": "pogácsaszaggató",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/26/pogacsa_szaggato.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "fakanál",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/26/fakanal.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "sodrófa",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/26/sodrofa.mp3').default,
                        "isCorrect": false
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Milyen volt a kötény mintája?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/26/milyen_volt_a_koteny_mintaja.mp3').default,
                  },
                  "options": [
                     {
                        "name": "csíkos",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/26/csikos.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "kockás",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/26/kockas.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "pettyes",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/26/pettyes.mp3').default,
                        "isCorrect": false
                     }
                  ]
               }
            ]
         },{
            "main_image": require('../../../images/tasks/figyeld_meg_jol_a_kepet/27.jpg').default,
            "questions": [
               {
                  "question": {
                     "name": "Hány lány volt a képen?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/27/hany_lany_volt_a_kepen.mp3').default,
                  },
                  "options": [
                     {
                        "name": "0",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/27/0.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "3",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/27/3.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "4",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/27/4.mp3').default,
                        "isCorrect": true
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Milyen színű pulóvert viselt a fiú?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/27/milyen_szinu_pulovert.mp3').default,
                  },
                  "options": [
                     {
                        "name": "kék",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/27/kek.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "zöld",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/27/zold.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "piros",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/27/piros.mp3').default,
                        "isCorrect": false
                     }
                  ]
               },
               {
                  "question": {
                     "name": "Mi volt a fiú kezében?",
                     "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/27/mi_volt_a_kezeben.mp3').default,
                  },
                  "options": [
                     {
                        "name": "fagyi",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/27/fagyi.mp3').default,
                        "isCorrect": true
                     },
                     {
                        "name": "telefon",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/27/telefon.mp3').default,
                        "isCorrect": false
                     },
                     {
                        "name": "fényképezőgép",
                        "sound": require('../../../sounds/voice/figyeld_meg_jol_a_kepet/27/fenykepezogep.mp3').default,
                        "isCorrect": false
                     }
                  ]
               }
            ]
         }
      ]
   };
    */


  
   var sliderSettings = {
      dots: false,
      arrows: false,
      infinite: false,
      speed: 500,
      centerMode: true,
      slidesToShow: 1,
      draggable: false,
      slidesToScroll: 1,
      afterChange: current => setCurrentSlide(current),
      responsive: [
      {
         breakpoint: 680,
         settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
         }
      }
      ]
  };
  
   
   
  

  
  
  const goNextSlide = () => {
      if (taskItem["questions"][questionIndex + 1]) {
         setQuestionIndex(questionIndex+1)
      } else {
        var nextTaskItem = false;
        nextTaskItem = task_config["items"][paramSubtaskId + 1];
        if ((!nextTaskItem && isAnswerCorrect)) {
          setGameEnded(true);
          return false;
        } else {
          // reset question
          setQuestionIndex(0);

          // go next subtask
          var url = new URL(window.location.href);
          var new_val = parseInt(paramSubtaskId + 1);
          console.log("new_val: ", new_val);
          var new_url = glob.removeLastDirectoryPartOf(location.pathname) + "/" + new_val;
          history.push(new_url);
        }
      }
      $(".btn-question-option").removeClass("active");
   }
   const openQuestions = () => {
     setAppTitle("Válaszolj a kérdésekre!");
      myslider.current.slickGoTo(1);
   }

  const answerQuestion = (elemId, isCorrect) => {
    if (isAnswerCorrect) { return false }
    glob.playGameSound(isCorrect ? "correct":"incorrect");  
      if (isCorrect) {
         setIsAnswerCorrect(true);
      }
      $("#" + elemId).addClass("active");
      if (!isCorrect) {
         setTimeout(function () {
            $("#" + elemId).removeClass("active");
         }, 2000);   
      }
  }

  const resetGame = () => {
    setIsAnswerCorrect(false);
    setGameEnded(false);
    setQuestionIndex(0);
    setAppTitle("");
    var new_url = glob.removeLastDirectoryPartOf(location.pathname) + "/" + 0;
    history.push(new_url);
  }
  var nextTaskItem = false;
  nextTaskItem = task_config["items"][paramSubtaskId + 1];

  if ((!nextTaskItem && isAnswerCorrect && gameEnded)) {
    return <GameEndScreen className="nomargintop" resetGame={ resetGame } />
  }
   return (
     <div className="task-wrapper task-wrapper-FigyeldMegJolAKepet">
         <Slider ref={myslider} {...sliderSettings} className={`SliderFigyeldMegJolAKepet`}>
            <div className="wrapper-main_image p-text-center">
               <div className="p-grid p-align-center">
                  <div className="p-col-1 p-xl-3"></div>
                  <div className="p-col-8 p-xl-6">
                     {taskItem && <img src={taskItem["main_image"]} height="100%" />}
                  </div>
                  <div className="p-col-3">
                     <Button label={<img src={ glob.icons["question"] } width="50" />}  className="btn-question-mark p-button-rounded p-button-text" onClick={() => openQuestions()} />
                  </div>
               </div>
            </div>
            
            <div className="question-item sy-position-relative">
               <div className="p-grid p-align-center">
                  <div className="p-col-2 p-md-3 sy-position-relative">
                     <Button label={<img src={ glob.icons["image_again5"] } width="50" />} className="btn-image p-button-rounded p-button-text p-button-plain" onClick={() => myslider.current.slickGoTo(0)} />
                  </div>
                  <div className="p-col-8 p-md-6">
                     <h2 className="p-m-0">{taskItem && taskItem["questions"][questionIndex]["question"]["name"]} <Button label={(<><img src={ glob.icons["speaker"] } width="30" /></>)} className="p-button-rounded p-button-text p-button-plain" onClick={() =>{glob.playSound(taskItem["questions"][questionIndex]["question"]["sound"])}} /></h2>
                     {taskItem && taskItem["questions"][questionIndex]["options"].map(function (option, index2) {
                        return (
                           <div className="question-option">
                              <Button id={"btn-question-option-"+index2} label={option.name}  className={`button-answer btn-question-option p-button-outlined p-button-plain ${option.isCorrect ? ("btn-answer-correct") : ("btn-answer-incorrect")} `} onClick={() => answerQuestion("btn-question-option-"+index2, option.isCorrect)} /> <Button label={(<><img src={ glob.icons["speaker"] } width="30" /></>)} className="p-button-rounded p-button-text p-button-plain" onClick={() => { glob.playSound(option["sound"]) }} />
                           </div>
                        )
                     })}
                  </div>
                  <div className="p-col-2 p-md-3">
                      
                  </div>
               </div>
            </div>
       </Slider>
          {(isAnswerCorrect && currentSlide === 1) && (
            <>
                <div className="wrapper-btn-next-task">
                    <Button label={<img src={ glob.icons["nextExerciseBig"] } width="50" />} className="btn-next-task p-button-rounded p-button-text " onClick={() => goNextSlide()} />
                    <div className="bg-of-btn-next-task"></div>
                </div>
            </>
          )}
      </div>
   );
};