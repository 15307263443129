import React from 'react'
import { Route, Redirect } from "react-router-dom";
import Cookies from 'universal-cookie';
import { license_accepted_key, login_date_key } from './Login';

export default function PrivateRoute({ component: Component, ...rest }) {
  const cookies = new Cookies();

  function getComponentAfterLoginStateCheck(props) {
    var login_date = cookies.get(login_date_key())
    var _la = cookies.get(license_accepted_key())
    if (login_date!== undefined && login_date != "null" && login_date.length > 0 &&
        _la!== undefined && _la != "null" && _la){
      return <Component {...props} />;
    } else {
      return <Redirect to="/login" />;
    }
  }

  return (
    <Route
      {...rest}
      render={(props) => {
          return getComponentAfterLoginStateCheck(props);
        }
      }
    ></Route>
  );
}
