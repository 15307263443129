import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import QuestionItem from '../../QuestionItem';
import glob from '../../glob';

export default function MelyikKepTartozikASzohoz(props, { ...rest }) {   
   const [taskConfig, setTaskConfig] = useState(false);

   useEffect(() => {
      var questions_ = [];
      $.each(glob.words, (index, item) => {
         if (glob.word_categories.includes(item["question"]["name"])) {
            $.each(item["options"], (index, item2) => {
               item2["category"] = item["question"]["name"];
            });
            questions_ = questions_.concat(item["options"]);
         }
      });
      glob.shuffleArray(questions_);
      console.log("questions_: ", questions_);
      var questions = [];
      $.each(questions_, (index, item) => {
         
         var question_item = item;
         console.log("question_item: ", question_item);

         
         var opts_ = [{
            "image": question_item["image"],
            "isCorrect": true
         }];
         
         var selected_options_ = [];
         while (opts_.length != 4) {
            var rand_item = glob.getRandomElementFromArray(questions_);
            if (question_item["name"] != rand_item["name"] && !selected_options_.includes(rand_item["name"])) {
               selected_options_.push(rand_item["name"]);
               
               opts_.push({
                  "image": rand_item["image"],
                  "isCorrect": false
               });   
            }
         }
         glob.shuffleArray(opts_);
         var new_item = {
            "question": {
               "name": question_item["name"],
            },
            "options": opts_
         };
         questions.push(new_item);
      });
      glob.shuffleArray(questions);

      
      var task_config = {};
      
      task_config["items"] = questions; //.slice(1, 3)

      setTaskConfig(task_config);
   }, []);
   
   return (
      <div className="task-wrapper task-wrapper-MelyikKepTartozikASzohoz">
         {taskConfig["items"] && <QuestionItem type="valaszok_kozepen" taskConfig={taskConfig} />}
      </div>
   );
};