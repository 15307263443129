import React from 'react';
import MennyiPenzedVan from '../../MennyiPenzedVan';

export default function MennyiPapirpenzVanNalad(props, { ...rest }) {

   var penz_ = [
      "500",
      "1000",
      "2000",
      "5000",
      "10000",
      "20000"
   ]

   return (
      <div className="task-wrapper task-wrapper-MennyiPapirpenzVanNalad">
         <MennyiPenzedVan options={penz_} />
      </div>
   );
};