import React from 'react';
import $ from 'jquery';

import FrameImageGallery from '../FrameImageGallery';


export default function NevezdMegAKepeket(props, { ...rest }) {
   
   var task_config = {
      "items": []
   };
   
   $.each(["b", "d", "g", "l", "m", "p", "r", "s", "t", "z"], (index, item) => {
      var new_item = (
         <div className="p-grid">
            <div className="p-col-4">
               <img src={require('../../../images/tasks/nevezd_meg_a_kepeket/'+item+'/1.jpeg').default} alt="gallery image" />
            </div>
            <div className="p-col-4">
               <img src={require('../../../images/tasks/nevezd_meg_a_kepeket/'+item+'/'+item.toUpperCase()+'.png').default} alt="gallery image" />
            </div>
            <div className="p-col-4">
               <img src={require('../../../images/tasks/nevezd_meg_a_kepeket/'+item+'/2.jpeg').default} alt="gallery image" />
            </div>
            <div className="p-col-4">
               <img src={require('../../../images/tasks/nevezd_meg_a_kepeket/'+item+'/3.jpeg').default} alt="gallery image" />
            </div>
            <div className="p-col-4">
               <img src={require('../../../images/tasks/nevezd_meg_a_kepeket/'+item+'/4.jpeg').default} alt="gallery image" />
            </div>
            <div className="p-col-4">
               <img src={require('../../../images/tasks/nevezd_meg_a_kepeket/'+item+'/5.jpeg').default} alt="gallery image" />
            </div>
         </div>
      );
      task_config["items"].push(new_item);
   });

   return (
      <div className="task-wrapper task-wrapper-NevezdMegAKepeket">
         <FrameImageGallery items={task_config["items"]} customArrows={true} />
      </div>
   );
};